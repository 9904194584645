import { removeErrorMessage } from "../features/errorSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getMessageIcon } from "../utils/Commons";
import { XIcon } from "./Icons";
import '../styles/error.css';

export default function Error ({error}) {

    const dispatch = useDispatch();

    const message = error.type === "error" && error.message == null ? "Une erreur s'est produite, veuillez réessayer" : error.message;

    const errorTimeout = 7;
    useEffect(() => {
        let timer = null;
        if (error.timeout) {
            timer = setTimeout(() => {
                dispatch(removeErrorMessage({id:error.id})) //on supprime le message d'erreur apres 7 secondes
            }, 1000*errorTimeout+300);
        }
        return () => clearTimeout(timer);
      }, []);


    return (
        <div className={"errorMsg "+error.type+ (error.timeout ? " timeout" : "")} style={{ "--error-timeout": errorTimeout+"s" }} onClick={() => dispatch(removeErrorMessage({id:error.id}))}>
            <div>{getMessageIcon(error.type)}</div>
            <span>{message}</span>
            <button onClick={() => dispatch(removeErrorMessage({id:error.id}))}>
                <XIcon></XIcon>
            </button>
            {error.timeout ? 
                <div className="progressBar"></div>
                : null
            }
        </div>
     )

    
}

