import { useDispatch } from "react-redux";
import { ClientIcon } from "../Icons";
import { useEffect, useState } from "react";
import Loader from "../Loader";
import { getListeActiveSessions } from "../../api/sessionsApi";
import { addErrorMessage } from "../../features/errorSlice";
import dayjs from "dayjs";

export default function ActiveSessions () {

    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    
    useEffect(() => {
        if (data == null) {
            getListeActiveSessions()
            .then((res) => {
                console.log(res);
                setData(res);
            })
            .catch(error =>{
                setData([]);
                dispatch(addErrorMessage({type:"error"}));
            });
        }
    }, [data])

    return (
        <div className="Block ActiveSessions">
            <h3>
                <ClientIcon/>
                <span>Sessions actives</span>
            </h3>
            <div>
                { data == null ? <Loader/> :
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Utilisateur</th>
                                <th>Expiration</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(s => {
                                return <SessionRow session={s} setData={setData} key={s.id}/>
                            })}
                        </tbody>
                    </table> 
                }
            </div>
            
        </div>
    )

}


function SessionRow ({session}) {
    return (
        <tr>
            <td>{session.id}</td>
            <td>{session.username}</td>
            <td>{session.expires ? dayjs(session.expires).format('DD/MM/YY HH:mm:ss') : ""}</td>
        </tr>
    )
}