import { Outlet, Link } from "react-router-dom";
import '../../styles/admin/admin.css';
import { logout } from '../../api/authApi.js'
import { useDispatch } from 'react-redux'
import { logoutSuccess } from '../../features/authSlice.js';
import { useNavigate } from "react-router-dom";
import { ClientIcon, GroupIcon, HomeIcon, LogoutIcon, ShopIcon } from "../Icons.js";


function Admin(){
    const navigate = useNavigate();
    let dispatch=useDispatch();

    //TODO : fonction commune
    const handleLogout = async e =>{
        e.preventDefault();
        logout().then(() => {
            dispatch(logoutSuccess());
        })
        .then(()=>{
            navigate('/login');
        })
        .catch(err=>{
            console.log(err); //TODO
        });
    }

    return (
        <div className="Admin">
            <div className="menu">
                <div>
                    <Link to={''}><HomeIcon/></Link>
                    <br></br>
                    <Link to={'users'}><ClientIcon/></Link>
                    <br></br>
                    <Link to={'lots'}><GroupIcon/></Link>
                    <br></br>
                    <Link to={'shops'}><ShopIcon/></Link>
                    <br></br>
                    <button className="logout" onClick={handleLogout}><LogoutIcon/></button>
                </div>
            </div>
            <Outlet/>
        </div>
    )
}

export default Admin;