import { useState, useEffect } from 'react';

import { StandardButton } from './Buttons';
import Loader from './Loader';
import { XIcon } from './Icons';

import '../styles/dialog.css'; 

export function Dialog ({ children, confirmText, confirmCallback, cancelText, cancelCallback }){

    const [loading, setLoading] = useState(false);

    return (
        <DialogWithLoading
            confirmText={confirmText}
            confirmCallback={confirmCallback}
            cancelText={cancelText}
            cancelCallback={cancelCallback}
            loading={loading}
            setLoading={setLoading}
            >
            {children}
        </DialogWithLoading>
    )

   
}



export function DialogWithLoading ({ children, confirmText, confirmCallback=null, cancelText, cancelCallback, loading, setLoading }){

    const [open, setOpen] = useState(false);

    const onClickConfirm = async (e) =>{
        setLoading(true);
        if (confirmCallback && typeof confirmCallback === "function") {
            confirmCallback(e);
        }
    }

    const onClickCancel = async (e) =>{
        setLoading(true);
        if (cancelCallback && typeof cancelCallback === "function") {
            cancelCallback(e);
        }
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 27) {
            onClickCancel();
        }
        else if (e.keyCode === 13 ){
            onClickConfirm();
        }
    }
    
    useEffect(() => {
        setOpen(true);
        window.addEventListener("keydown", onKeyDown);

        return () => {
        setOpen(false);
            window.removeEventListener("keydown", onKeyDown);
        };
    }, []);
    
    return (
        <div className='modal-container'>
            <div className={"modal "+ (open ? "expand": "")}>
                <div className="to">
                    <div className="to-contents">
                        {loading ? 
                            <div className="modal-body">
                                <Loader/>
                            </div> :
                            <div className="modal-body">
                                {children}
                            </div>
                        }
                        <div className="modal-footer">
                            <StandardButton isLocked={loading} label={confirmText} onClick={onClickConfirm}></StandardButton>
                            <StandardButton isLocked={loading} label={cancelText} onClick={onClickCancel}></StandardButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export function Modal({ children , width='500px', height='500px', closeCallback=null, className='' }){

    const [open, setOpen] = useState(false);

    const onCloseModal = async (e) =>{
        setOpen(false);
        if (closeCallback && typeof closeCallback === "function") {
            closeCallback(e);
        }
    }
    
    useEffect(() => {
        setOpen(true);

        return () => {
        setOpen(false);
        };
    }, []);


    let bodyHeight = 'calc( '+height+' - 30px)';
    
    return (
        <div className='modal-container'>
            <div style={{width:width, height:height}} className={"modal "+ className + (open ? " expand": "")}>
                <div className="to">
                    <div className="to-contents">
                        <div className='modal-header' style={{width:width}}>
                            <button onClick={onCloseModal}>
                                <XIcon/>
                            </button>
                        </div>
                        <div className="modal-body" style={{width:width, height:bodyHeight}}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}