import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChevronIcon, EditIcon, InfoIcon, LocIcon } from "../Icons";
import { getDisponibiliteArticles,sortArticles } from "../../api/articlesApi";
import { BackButton, LocationButton, LocationFamilleButton } from "../fiche/FicheButtons";
import { DateTimeField, PlusMinusNumberField } from "./EditableField";
import { getArticles } from "../../api/articlesApi";
import { addErrorMessage } from "../../features/errorSlice";
import { LigneArticle } from "./LigneArticle";
import { CheckIcon, XIcon, HideIcon, ShowIcon } from "../Icons";
import dayjs from "dayjs";
import SortableItemList from "../SortableItemList";

import Loader from "../Loader";
import { addPendingArticle } from "../../features/ficheSlice";
import { LigneArticleReservation } from "./LigneArticleReservation";
import { BlockTitle } from "../Title";
import { InfoTooltip } from "../Tooltips";
import { sortItemsByIndice } from "../../utils/Commons";

export function Location({socket}) {

    const dispatch = useDispatch();
    const articles = useSelector((state) => state.fiche.articles);
    const pendingArticles = useSelector((state) => state.fiche.pendingArticles);
    const client = useSelector((state) => state.fiche.client);
    const reservation = useSelector((state) => state.fiche.reservation);
    const [detailReservation, setDetailReservation] = useState([]);

    const totalLocation = articles.reduce((total, product) => (total = total + product.prix),0);

    const [datesLocation, setDatesLocation] = useState({
        dateDebut: dayjs(),
        duree:6,
    })
    
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        if (reservation?.IdResa != null) {
            socket.current.emit("detailReservation", {
                idResa: reservation.IdResa,
            });
        }
        else setDetailReservation([]);
    }, [reservation]);

    useEffect(() => {
        socket.current.on("detailReservation", onDetailReservation);
        return () => {
            socket.current.off("detailReservation", onDetailReservation);
        }
    }, []);

    const onDetailReservation = (response) => {
        console.log(response);
        if (!response.success) {
            setDetailReservation([]);
            dispatch(addErrorMessage({type:"error", message:response.message}));
        }
        else setDetailReservation(response.detail);
    }
   
    return (
        <div className='Block'>
            <BlockTitle 
                title={"Location"} 
                onClick={() =>  setIsOpen(!isOpen)}
                icon={<LocIcon/>}>
                    {totalLocation > 0 ?
                        <span>{totalLocation.toFixed(2)}&euro;</span>
                        :null
                    }
                    {client != null || reservation != null ?
                        <button className={isOpen ? 'open':'close'}><ChevronIcon></ChevronIcon></button>
                        : null
                    }
            </BlockTitle>            
            
            <div className="contentLocation" style={{display : isOpen && (client != null || reservation != null) ? "block":"none"}}>
                { reservation == null ? 
                <div>
                    <DateInputs datesLocation={datesLocation} setDatesLocation={setDatesLocation}/>
                    <LocationButtons socket={socket} datesLocation={datesLocation}/>
                </div>
                    :null
                }

                <table className='Panier Location'>
                    <thead>
                        <tr>
                            <th className="code"><span>Code</span></th>
                            <th className="categorie"><span>Catégorie</span></th>
                            <th className="debut"><span>Début</span></th>
                            <th className="fin"><span>Fin</span></th>
                            <th><span>Durée</span></th>
                            {reservation == null ?<th className="mnt"><span>Montant</span></th> : null}
                            {reservation == null ? <th className="aj"><span>Ajustement</span></th> : null}
                            {reservation == null ? <th><span>Total</span></th> : null}
                            {reservation == null ? <th className="suppr"></th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {pendingArticles>0 ? 
                            Array(pendingArticles).fill(true).map((_, i) => {
                                return <tr key={i} className="pendingLignePanier"><td colSpan={9}><Loader height={20} width={20}/></td></tr>
                            })
                            :null
                        }
                        
                        {articles.slice().reverse().map((article) => {
                            return <LigneArticle key={article.indice} article={article} socket={socket}/>
                        })} 

                        {detailReservation.map((article, i) => {
                            return <LigneArticleReservation key={i} article={article}/>
                        })} 
                    </tbody>                        
                </table> 
            </div> 

        </div>
    )  

}


function DateInputs ({datesLocation, setDatesLocation}) {
    const [status, setStatus] = useState(null);

    const onChangeDuree = (duree) => {
        duree = parseFloat(duree);
        if (isNaN(duree) || duree <= 0) {
            setStatus(null);
            return;
        }
        setDatesLocation({
            ...datesLocation,
            duree: duree
        });
        setStatus(null);
    }

    const onChangeDate = (date) => {
        //temp
        if (date > dayjs()) {
            date = dayjs();
        }
        //temp
        //TODO : check values
        setDatesLocation({
            ...datesLocation,
            dateDebut: date,
        });
        setStatus(null);
    }

    const onChangeStatus = (field, value) => {
        if (value) setStatus(field);
        else setStatus(null);
    }

    return (
        <div className="inputs">
            <div className="debut">
                <span>début le </span>
                <DateTimeField
                    initValue={datesLocation.dateDebut}
                    maxDate={dayjs()} //temp
                    onFocusOut={onChangeDate}
                    format={'datetime-local'}
                    edit={status === "debut"}
                    setEdit={(v)=> onChangeStatus("debut", v)}/>
            </div>
            <div className="duree">
                <span>pour</span>
                <PlusMinusNumberField
                    initialValue={datesLocation.duree}
                    onFocusOut={onChangeDuree}
                    style={{width:90}}
                    edit={status === "duree"}
                    setEdit={(v)=> onChangeStatus("duree", v)}
                    step={0.5}
                />
                <span> jours</span>
            </div>
        </div>
    )
}



function LocationButtons ({socket, datesLocation}){

    const dispatch = useDispatch();
    const [articles, setArticles] = useState([]);
    const [showDisabled, setShowDisabled] = useState(false);
    const [sort, setSort] = useState(false);
    const [codeFamille, setCodeFamille] = useState(null);
    const [dispos, setDispos] = useState({
        enable : true,
        values:[]
    });

    const getListeArticles = (codeFamille) => {
        let famille = articles.find((fam) => fam.code === codeFamille);
        if (famille === null) return [];

        return famille.articles;
    }

    const addArticle = (ean13) => {
        dispatch(addPendingArticle());
        setCodeFamille(null);
        socket.current.emit("addArticle", {ean13:ean13, dateDebut: datesLocation.dateDebut.format('YYYY-MM-DD HH:mm'), duree:datesLocation.duree});
    }

    const onSelectFamille = (codeFamille) => {
        getDispoArticlesFamille(getListeArticles(codeFamille));
        setCodeFamille(codeFamille);
    }


    useEffect(() => {
        getArticles()
        .then(res=> {
            console.log(res);
            setArticles(res);
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error", message:"Impossible de charger la liste des articles"}));
        });
        return () => {
            setArticles([]);
        };
    }, [sort]);

    useEffect(() => {
        if (codeFamille != null) getDispoArticlesFamille(getListeArticles(codeFamille));

    },[datesLocation]);

    const getDispoArticlesFamille = async (listeArticles) =>{
        if (!dispos.enable) return;
        let packs = [];
        listeArticles.forEach(article => {
            if (article.idPack != null && article.idPack.length>0) packs.push(article.idPack[0].Code);
        });
        getDisponibiliteArticles ({
            dateDebut: datesLocation.dateDebut.format('YYYY-MM-DD'),
            duree : datesLocation.duree,
            packs : packs
        })
        .then(res=> {
            console.log(res);
            setDispos({...dispos, values:res.disponibilites});
        })
        .catch(error =>{
            console.log(error);
            setDispos({...dispos, enable:false});
            dispatch(addErrorMessage({type:"warning", message:"Impossible d'afficher les disponibilités des articles"}));
        });
    }


    return articles.length == 0 ? 
            <Loader width={50} height={50} containerStyle={{color:'var(--main-color)'}}/>
            :
            <div>

                {codeFamille === null ?
                <div className='ButtonList'>
                    {articles.map(famille=>{
                        return <LocationFamilleButton key={famille.code} code={famille.code} onClick={onSelectFamille}/>
                    })}
                </div>
                : 
                    (!sort ?
                        <div className='ButtonList'>
                            {getListeArticles(codeFamille)
                                .filter(el => (showDisabled || el.indice >= 0 || el.indice == null))
                                .sort(sortItemsByIndice)
                                .sort((a,b) => a.indice < 0 ? 1 : 0)
                                .map(article=>{
                                    let idPack = (article.idPack == null ? null : article.idPack[0]?.Code);
                                    return <LocationButton key={article.code} article={article} onClick={addArticle} dispo={dispos.values.find((el) => el.idPack == idPack)}/>
                            })}
                            <BackButton className={"location"} onClick={setCodeFamille}/>
                        </div>
                        :
                        <SortButtons 
                        listeArticles={getListeArticles(codeFamille).sort(sortItemsByIndice).sort((a,b) => a.indice < 0 ? 1 : 0)} 
                        setSort={setSort}/>
                    )
                }
                {codeFamille != null && !sort ?
                    <div className="sortButtons">
                        <InfoTooltip placement="left" title="Afficher les articles masqués">
                            <button onClick={() => setShowDisabled(!showDisabled)}>
                                {showDisabled ? <ShowIcon width={20} height={20}/> : <HideIcon width={20} height={20}/>}
                            </button>
                        </InfoTooltip>
                        <button onClick={() => setSort(true)}><EditIcon width={20} height={20}/></button>
                    </div> 
                    : null
                }
            </div>
    
}


function SortButtons({listeArticles, setSort}) {

    const [articles, setArticles] = useState(listeArticles.map(p => {return {libelle:p.code, code:p.code, id:p.code, indice:p.indice}}));
    const dispatch = useDispatch();

    const getArticleButton = (item) => {
        return (
            <button className={"ficheButton produit"+(item.indice < 0 ? " transparent" : "")} onClick={() => onClickArticle(item)}>
                <span>{item.libelle}</span>
            </button>
        )
    }

    const onClickArticle = (article) => {
        let index = articles.map(function(e) { return e.code; }).indexOf(article.code);
        let newIndice = null;
        if (!(article.indice < 0)) newIndice = -1;
        let newArticle = [...articles];
        newArticle[index].indice = newIndice;
        setArticles(newArticle);
    }

    const onClickSaveButton = () => {
        let sortedList = articles.map((el, indice) => {
            return {
                code:el.code,
                indice: el.indice < 0 ? el.indice : indice
            }
        })

        sortArticles({articles:sortedList})
        .then(res=> {
            setSort(false);
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"})); 
        });
    }

    return (
        <div>
            <SortableItemList items={articles} setItems={setArticles} getElementFromItem={getArticleButton}/>
            <div className="sortButtons">
                <InfoTooltip placement="left" title="Glissez/déposez les boutons dans l'ordre de votre choix. Cliquez sur les boutons pour les masquer">
                    <InfoIcon width={15} height={15} style={{marginRight: '1em'}}/>
                </InfoTooltip>
                <button onClick={onClickSaveButton}><CheckIcon width={15} height={15}/></button>
                <button onClick={() => setSort(false)}><XIcon width={15} height={15}/></button>
            </div>   
        </div>
    );
}


