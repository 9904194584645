import Loader from "./Loader";

import '../styles/filler.css';

export default function Filler ({text, children, loading}){
    return (
        
        <div className="Filler">
            {loading ? 
                <Loader/> :
                <div>
                    {children}
                    <span>{text}</span>
                </div>  
            }
            
        </div> 
    )
}