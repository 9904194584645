import React, { useEffect} from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import Container from './components/Container.js';
import ProtectedRoute from './components/ProtectedRoute.js';
import { ErrorBoundary } from 'react-error-boundary';
import store from './app/store.js';

import Login from './routes/Login.js';

import Home from './routes/Home.js';
import Error from './routes/Error.js';
import Fiche from './routes/Fiche.js';
import Caisse from './routes/Caisse.js';
import Statistiques from './routes/Statistiques.js';
import EtatStocks from './routes/EtatStocks.js';
import Planning from './routes/Planning.js';
import EntreeStock from './routes/EntreeStock.js';
import GestionProduitsLocaux from './routes/GestionProduitsLocaux.js';
import CorrectionCaisse from './routes/CorrectionCaisse.js';
import RemisesEnBanque from './routes/RemisesEnBanque.js';
import PrevisionsSortiesRetours from './routes/PrevisionsSortiesRetours.js';
import ReductionAllotement from './routes/ReductionAllotement.js';
import Allotements from './routes/Allotements.js';
import RetoursAnticipes from './routes/RetoursAnticipes.js';

import SupervisorHome from './routes/supervisor/SupervisorHome.js';
import GestionProduits from './routes/supervisor/GestionProduits.js';
import SupervisorShops from './routes/supervisor/SupervisorShops.js';
import GestionPrixLocation from './routes/supervisor/GestionPrixLocation.js';

import AdminHome from './routes/admin/AdminHome.js';
import User from './routes/admin/User.js';
import UserList from './routes/admin/UserList.js';
import ShopList from './routes/admin/ShopList.js';
import Shop from './routes/admin/Shop.js';
import LotList from './routes/admin/LotList.js';
import Lot from './routes/admin/Lot.js';



import './index.css';
import './styles/app.css';
import Settings from './routes/Settings.js';
import ShopsStatistiques from './routes/admin/ShopsStatistiques.js';
import Aide from './routes/Aide.js';
import AideConsultationDetail from './routes/AideConsultationDetail.js';
import ChiffreAffairesCumulDetail from './routes/admin/ChiffreAffairesCumulDetail.js';

export default function App() {


  const router = createBrowserRouter([
    {
      path:'/',
      errorElement: <Error/>,
      element: <Container/>,
      children: [
        {
          path: "/",
          element: <ErrorBoundary fallback={<Error/>}><ProtectedRoute allowedRoles={['user']}/></ErrorBoundary>,
          children:[
            {
              path:"/",
              element: <Home/>
            },
            {
              path: "location",
              element: <Fiche typeFiche='Location'/>,
            },
            {
              path: "vente",
              element: <Fiche typeFiche='Vente'/>,
            },
            {
              path: "caisse",
              element: <Home section='Caisse'/>,
            },
            {
              path: "caisse/edition",
              element: <Caisse/>,
            },
            {
              path: "caisse/correction",
              element: <CorrectionCaisse/>,
            },
            {
              path:"caisse/remises",
              element: <RemisesEnBanque/>
            },
            {
              path: "statistiques",
              element: <Statistiques/>,
            },
            {
              path: "stocks/etat",
              element: <EtatStocks/>,
            },
            {
              path: "stocks/entree",
              element: <EntreeStock/>,
            },
            {
              path: "retours/manuels",
              element: <RetoursAnticipes/>,
            },
            {
              path: "planning",
              element: <Home section='Planning'/>,
            },
            {
              path: "/planning/:type",
              element: <Planning/>,
            },
            {
              path: "/previsions",
              element: <PrevisionsSortiesRetours/>,
            },
            {
              path: "produits",
              element: <GestionProduitsLocaux/>,
            },
            {
              path: "articles/reduction",
              element: <ReductionAllotement/>,
            },
            {
              path: "articles/quantites",
              element: <Allotements/>,
            },
            {
              path: "gestion",
              element: <Home section='GestionLocation'/>,
            },
            {
              path: "settings",
              element: <Settings role="user"/>,
            },
          ],
        },
        {
          path: "/supervisor",
          element: <ErrorBoundary fallback={<Error/>}><ProtectedRoute allowedRoles={['supervisor']}/></ErrorBoundary>,
          children:[
            {
              path:"/supervisor",
              element: <SupervisorHome/>
            },
            {
              path:"/supervisor/shops/stocks/:id",
              element: <EtatStocks/>
            },
            {
              path:"/supervisor/shops/planning/:id",
              element: <Planning/>
            },
            {
              path:"/supervisor/shops",
              element: <SupervisorShops/>
            },
            {
              path:"/supervisor/produits",
              element: <GestionProduits/>
            },
            {
              path:"/supervisor/prix",
              element: <GestionPrixLocation/>
            },
            {
              path: "/supervisor/settings",
              element: <Settings role="supervisor"/>,
            },
            {
              path: "/supervisor/aide",
              element: <AideConsultationDetail/>,
            },
          ],
        },
        {
          path: "/admin",
          element: <ErrorBoundary fallback={<Error/>}><ProtectedRoute allowedRoles={['admin']}/></ErrorBoundary>,
          children:[
            {
              path:"/admin",
              element: <AdminHome/>
            },
            {
              path:"/admin/users",
              element: <UserList/>
            },
            {
              path:"/admin/users/:id",
              element: <User/>
            },
            {
              path:"/admin/shops",
              element: <ShopList/>
            },
            {
              path:"/admin/shops/:id",
              element: <Shop/>
            },
            {
              path:"/admin/lots",
              element: <LotList/>
            },
            {
              path:"/admin/lots/:id",
              element: <Lot/>
            },
            {
              path: "/admin/settings",
              element: <Settings role="admin"/>,
            },
            {
              path: "/admin/statistiques",
              element: <ShopsStatistiques/>,
            },
            {
              path: "/admin/aide",
              element: <AideConsultationDetail/>,
            },
            {
              path: "/admin/ca",
              element: <ChiffreAffairesCumulDetail/>,
            },
          ],
        },
        {
          path: "/aide",
          element: <ErrorBoundary fallback={<Error/>}><ProtectedRoute allowedRoles={['user', 'supervisor', 'admin']}/></ErrorBoundary>,
          children:[
            {
              path:"/aide",
              element: <Aide/>
            }
          ],
        },
        {
          path: "/login",
          element: <ErrorBoundary fallback={<Error/>}><Login/></ErrorBoundary>,
        }
      ]
    }
  ]);

  return <RouterProvider router={router} />
    
}
