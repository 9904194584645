import { logoutSuccess } from "../features/authSlice";
import { addErrorMessage } from "../features/errorSlice";
import store from '../app/store';
import { redirect } from "react-router-dom";


export default function errorHandler (resultat){
    if (!resultat.success) {
        if (resultat.status === 401){
            store.dispatch(logoutSuccess());
            store.dispatch(addErrorMessage({type:"info", message:"Vous avez été déconnecté"}));
            redirect('/login');
        }
        else {
            throw new Error (resultat.message);
        }

        return false;
    }

    return resultat;
                
}