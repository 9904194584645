import { StandardButton } from "../components/Buttons";
import { ChevronIcon, RetourIcon, TimeBackIcon } from "../components/Icons";
import Filler from "../components/Filler";
import { Title } from "../components/Title";
import { useDispatch, useSelector } from "react-redux";
import { TextInput } from "../components/Inputs";
import { getListeFichesEnCours, retourArticle } from '../api/retoursApi';
import { addErrorMessage } from "../features/errorSlice";
import Loader from "../components/Loader";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

import '../styles/retoursAnticipes.css';
import { VideoLink } from "../components/VideoLink";

export default function RetoursAnticipes () {

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        nom:"",
        prenom:""
    })

    const [listeFichesEnCours, setListeFichesEnCours] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [updateKey, setUpdateKey] = useState(0);

    const handleChange = (e) => {
        let {name, value} = e.target;
        setFormData({...formData, [name]:value})
    }

    const onSubmitForm = () => {
        //TODO : check params ???
        setIsLoading(true);
        setListeFichesEnCours(null);
        getListeFichesEnCours(formData)
        .then(res=> {
            console.log(res);
            setListeFichesEnCours(res);
            setIsLoading(false);
        })
        .catch(error =>{
            console.log(error);
            setListeFichesEnCours(null);
            setIsLoading(false);
            dispatch(addErrorMessage({type:"error", message:"Impossible de charger la liste des articles"}));
        });
    }

    useEffect (()=>{
        if (updateKey > 0) onSubmitForm();
    }, [updateKey])


    return (
        <div className='RetoursAnticipes'>
            <Title title="Retours Anticipés" backTo="/gestion"><TimeBackIcon/></Title>
            <div className="Block filtres">
                <div className="filtresColumn">
                    <h3>Critères de recherche :</h3>
                    <div>
                        <TextInput 
                            label="Nom" 
                            name="nom"
                            value={formData.nom}
                            onChange={handleChange}/>
                    </div>
                    <div>
                        <TextInput 
                            label="Prénom" 
                            name="prenom"
                            value={formData.prenom}
                            onChange={handleChange}/>
                    </div>
                </div>
                <div className="filtresColumn">
                    <StandardButton label="Rechercher" onClick={onSubmitForm}/>
                </div>
                
            </div>
            <div className="Block">
                <div className="frame">
                    {
                        listeFichesEnCours == null ? 
                        <Filler 
                            loading={isLoading}
                            text="">
                        </Filler> :
                        <ListeArticlesDehors listeFichesEnCours={listeFichesEnCours} updateKey={updateKey} setUpdateKey={setUpdateKey}/>
                    }
                </div>
            </div>
            <VideoLink link={"https://www.youtube.com/embed/kt20tJjaLhQ?si=z4RgXYT2eRPZyxYD"} libelle='retoursAnticipes'/>
        </div>
    )
}

function ListeArticlesDehors ({listeFichesEnCours, updateKey, setUpdateKey}) {

    return (
        listeFichesEnCours.length>0 ?
        <table className="table">
            <thead>
                <tr>
                    <th className="code"><span>Code</span></th>
                    <th>Catégorie</th>
                    <th className="sort"><span>Sortie</span></th>
                    <th>Retour prévu</th>
                    <th><RetourIcon width={25} height={25}/></th>
                </tr>
            </thead>
            {listeFichesEnCours.map(f => {
                return <LigneFiche key={f.codeFiche} fiche={f} updateKey={updateKey} setUpdateKey={setUpdateKey}/>
            })}


        </table> :
        <Filler 
            loading={false}
            text="Aucune fiche en cours n'a été trouvée à ce nom">
        </Filler>
    )

}

function LigneFiche ({fiche, updateKey, setUpdateKey}) {

    const [showDetail, setShowDetail] = useState(false);

    return (
        <tbody>
            <tr key="fiche" className="ligneFiche" onClick={() => setShowDetail(!showDetail)}>
                <td className="code"><span>{fiche.codeFiche}</span></td>
                <td><span>{fiche.nom}</span></td>
                <td><span>{fiche.prenom}</span></td>
                <td className="sort"><span>{fiche.articles.length + " article" + (fiche.articles.length>1 ? "s" : "")}</span></td>
                <td><button className={showDetail ? 'open':'close'}><ChevronIcon/></button></td>
            </tr>
            
            {
                showDetail ? fiche.articles.map(a=>{
                    return <LigneArticle key={a.numeroLigne+"_"+a.indice} article={a} codeFiche={fiche.codeFiche} updateKey={updateKey} setUpdateKey={setUpdateKey}/>
                })
                : null
            }
        </tbody>
        
    )

}

function LigneArticle ({article, codeFiche, updateKey, setUpdateKey}) {

    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const handleRetourArticle = () => {
        setIsLoading(true);
        retourArticle({codeFiche: codeFiche, code:article.code, ean13:article.ean13, numeroLigne:article.numeroLigne, indice:article.indice})
        .then(res=> {
            if (res) {
                setIsLoading(false);
                dispatch(addErrorMessage({type:"success", message:"L'article a été retourné"}));
                setUpdateKey(updateKey+1)
            }
        })
        .catch(error =>{
            console.log(error);
            setIsLoading(false);
            dispatch(addErrorMessage({type:"error", message:"Impossible de retourner l'article"}));
        });

    }

    return (
        <tr className="ligneFiche">
            <td className="code"><span>{article.codeProduit}</span></td>
            <td><span>{article.categorie}</span></td>
            <td className="sort"><span>{dayjs(article.heureSortie).format('DD/MM hh:mm')}</span></td>
            <td><span>{dayjs(article.heureRetour).format('DD/MM hh:mm')}</span></td>
            <td>
                {isLoading ? 
                    <Loader width={15} height={15}/> :
                    <button onClick={handleRetourArticle}><RetourIcon/></button>
                }
            </td>
        </tr>
    )

}


