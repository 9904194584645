import { useState, useEffect } from 'react';
import Loader from '../../components/Loader.js';
import { getUsers } from '../../api/usersApi.js';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { addErrorMessage } from '../../features/errorSlice.js';
import { StandardButton } from '../../components/Buttons.js';
import { Title } from '../../components/Title.js';
import { ClientIcon } from '../../components/Icons.js';


export default function UserList (){

    const [users, setUsers] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => { 
        getUsers().then(res=>{
            if (res) setUsers(res);
        }).catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
        });
        setIsLoading(false);

    }, []);

    
    return (
        <div className='ItemList'>
            <Title title="Liste des utilisateurs" backTo='/admin'><ClientIcon/></Title>
            <div className='Block'>
                <div className='header'>
                    <StandardButton label="Créer un utilisateur" onClick={() => navigate('new')}/>
                </div>
                {isLoading ?
                    <Loader></Loader> :
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Login</th>
                                <th>Email</th>
                                <th>Role</th>
                            </tr>
                        </thead>
                        <tbody>
                            { users == null ? null :
                                users.map((user) => {
                                    return <UserRow key={user.id} user={user}></UserRow>
                                })
                            } 
                        </tbody>
                    </table>
                }
            </div>

        </div> 
    )
}


function UserRow ({user}) {

    return (
       <tr className='ItemRow'>
            <td>{user.id}</td>
            <td>
                <Link to={'/admin/users/'+user.id}>{user.username} </Link>
            </td>
            <td>{user.email}</td>
            <td>{user.role}</td>
       </tr>
     )
 }
 