import { Outlet } from "react-router-dom";
import Error from './Error.js';
import { useDispatch, useSelector } from 'react-redux';
import { getColors } from "../utils/Theme.js";
import { useEffect } from "react";
import { setTheme, setVoucher } from "../features/authSlice.js";

export default function Container() {

    const errors = useSelector((state) => state.errors);
    const theme = useSelector((state) => state.user.parametres.theme);
    const dispatch = useDispatch();

    const getTheme = () => {
        let theme = localStorage.getItem('theme');
        let voucher = localStorage.getItem('voucher');
        dispatch(setTheme({theme :theme}));
        if (voucher != null) dispatch(setVoucher({voucher :voucher ==='true'}));

    }

    useEffect(() => {     
        getTheme();
        window.addEventListener('storage', getTheme)
        return () => {
            window.removeEventListener('storage', getTheme)
        }
    }, [])

    

    return(
        <div className="container" style={getColors(theme)}>
            <Outlet/>
            <div className="errorContainer">
                { errors == null ? null :
                    errors.map((error) => {
                        if (error.active){
                            return <Error error={error} key={error.id}/>
                        }
                    })
                } 
            </div>
        </div>
    )
}