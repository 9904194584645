import '../../styles/dataBlocks.css';
import { TrendDownIcon, TrendUpIcon, WarningIcon } from '../Icons';
import { ErrorTooltip } from '../Tooltips';

export function StatistiquesTitle ({title, shopsKO}){

    const errorMsg = shopsKO == null ? "" : shopsKO.reduce(function(a, b){
        return a +" "+ b +",";
    }, "");

    return (
        <h2 className='StatistiquesTitle'>
            <span>{title}</span>
            {shopsKO != null && shopsKO.length > 0 ?
                <ErrorTooltip 
                    title = {"Les magasins " + errorMsg.substring(0, errorMsg.length-1) + " n'ont pas repondu. Les statistiques affichées ne tiennent donc pas compte de ces résultats."} 
                    openOnClick={true} 
                    placement="left">
                    <WarningIcon/>
                </ErrorTooltip> :
                null 
            }
        </h2>
    )
}


export function SmallBlock ({title, valueN, valueN_1, unit=""}){

    let pct =getPct(valueN, valueN_1).toFixed(1);

    return (
        <div className='smallBlock'>
            <span>{title}</span>
            <div>
                <div>
                    <div className='dataN'>{(valueN == null ? 0 : valueN)  + unit}</div>
                    <div className='dataN_1'>{valueN_1 == null ? '-' : (valueN_1 + unit) }</div>                  
                </div>
                <div>
                    <div className={'pct ' + (pct > 0 ? 'up' : 'down')}>
                        {pct > 0 ? <TrendUpIcon/> : <TrendDownIcon/>}
                        <span>{(pct > 0 ? '+'+pct : pct) +'%'}</span>
                    </div>
                </div>
            </div>            
        </div>
    )
}

export function LargeBlock ({title, valueN, valueN_1, unit=""}){
    let pct =getPct(valueN, valueN_1);

    return (
        <div className='largeBlock'>
            <span>{title}</span>
            <div>
                <div>
                    <div className={'pct ' + (pct > 0 ? 'up' : 'down')}>
                        {(pct > 0 ? '+'+pct : pct) +'%'}
                    </div>
                </div>
                <div>
                    <span className='dataN'>{(valueN == null ? 0 : valueN)  + unit}</span>
                    <span className='dataN_1'>{valueN_1 == null ? '-' : (valueN_1 + unit) }</span>                  
                </div>
            </div>            
        </div>
    )
}


function getPct (valueN, valueN_1){
    if (valueN_1 == null || valueN_1 === 0) {
        if (valueN === 0) return 0;
        return 100;
    }

    return 100*(valueN-valueN_1)/valueN_1;
   

}