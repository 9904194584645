import { useEffect, useState } from "react"
import { StandardButton } from "../Buttons"
import { ChevronIcon } from "../Icons"
import Loader from "../Loader"
import { getProduits } from "../../api/produitsApi";
import { addErrorMessage } from "../../features/errorSlice";
import { useDispatch, useSelector } from "react-redux";
import { BlockTitle } from "../Title";
import { SelectInput } from "../Inputs";

export default function ListeProduits({setCurrentProduit, produitsLocaux, currentProduit}){

    const [produits, setProduits] = useState(null);
    const dispatch = useDispatch();


    useEffect(() => {
        if (currentProduit == null) {
            getProduits(0, produitsLocaux)
            .then(res=> {
                setProduits(res);
            })
            .catch(error =>{
                console.log(error);
                dispatch(addErrorMessage({type:"error"})); 
            });
        }
    }, [currentProduit]);

    return (
        <div className="Block listeProduits">
            <BlockTitle title={produitsLocaux == 1 ? "Mes produits locaux" : "Mes produits"}/>
            {produits == null ? 
                <Loader width={50} height={50}/> : 
                <div>
                    <SelectFamilleProduit familles={produits} currentProduit={currentProduit} setCurrentProduit={setCurrentProduit}/>
                    <table>
                        {produits.map(famille=>{
                            return <BlocFamille key={famille.code} famille={famille} setCurrentProduit={setCurrentProduit} currentProduit={currentProduit}/>
                        })}
                    </table>
                </div>
            } 
            <div className="footer">
                <StandardButton onClick={() => setCurrentProduit('create')} label="Créer"/>
            </div>
        </div>
    )
}

function SelectFamilleProduit ({familles, currentProduit, setCurrentProduit}) {

    const [currentFamille, setCurrentFamille] = useState(null);

    const onChangeFamille = (e) => {
        let famille = familles.find(el => el.code === e.target.value);
        setCurrentFamille(famille);
    }

    const onChangeProduit = (e) => {
        let produit = currentFamille.produits.find(el => el.code === e.target.value);
        setCurrentProduit(produit);
    }

    useEffect(() => {
        if (currentProduit === "create") setCurrentFamille(null);
    }, [currentProduit]);

    return (
        <div>
            <SelectInput  
            value={currentFamille == null ? "" : currentFamille.code}
            onChange={onChangeFamille}
            options={familles.map(f => {return {name:f.libelle, value:f.code}})}/>
            {currentFamille == null ? null :
                <SelectInput
                value={currentProduit?.code}
                onChange={onChangeProduit}
                options={currentFamille.produits.map(p => {return {name:p.libelle, value:p.code}})}/>

            }
        </div>
    )
}


function BlocFamille ({famille, setCurrentProduit, currentProduit}) {

    const [showDetail, setShowDetail] = useState(false);

    return (
        <tbody>
            <tr className="ligneFamille">
                <td colSpan={2}>{famille.libelle}</td>
                <td>
                    <button className={showDetail ? 'open':'close'} onClick={()=>setShowDetail(!showDetail)}>
                        <ChevronIcon/>
                    </button>
                </td>
            </tr>
            {showDetail? famille.produits.map(produit=>{
                return <LigneProduit key={produit.code} produit={produit} setCurrentProduit={setCurrentProduit} currentProduit={currentProduit}/>
            }):null}
        </tbody>
    )
}


function LigneProduit ({produit, setCurrentProduit, currentProduit}) {

    const isSelected = produit.code === currentProduit?.code;

    return (
        <tr className={"ligneProduit" + (isSelected ? " selected" : "")} onClick={() => setCurrentProduit(produit)}>
            <td>
                {produit.libelle}
            </td>
            <td>
                {produit.code}
            </td>
            <td></td>
        </tr>
    )
}