import { useEffect, useState } from "react";
import { getPrevisionsSorties } from "../../api/statistiquesApi";
import { useDispatch } from "react-redux";
import { addErrorMessage } from "../../features/errorSlice";
import Loader from "../Loader";
import { LargeBlock } from "./DataBlocks";
import dayjs from "dayjs";
import { BarChart } from "../charts/BarChart";

export default function PrevisionsSorties ({date}) {

    let dispatch = useDispatch();
    let [data, setData] = useState({});

    useEffect(() => {
        getPrevisionsSorties(date)
        .then(res=>{
            console.log(res);
            if (res) {
                setData(res);
            }
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
        });
    }, []);

    //TODO : v�rifier que le mag est connect� au serveur socket myskilou


    return (
        <div className="Block" style={{width:'100%'}}>
            <h2>
                <span>Prévisions de sorties</span>
            </h2>
                {
                    data.date == null ?
                    <div className="slideLoader">
                        <Loader/>
                    </div> : 
                    <div>
                        <span className="date">{dayjs(date.date).format('dddd D MMMM YYYY')}</span>
                        <LargeBlock title="articles réservés" valueN={data['nbArticleN']} valueN_1={data['nbArticleN-1']}/>
                        <LargeBlock title={"articles loués en direct"} valueN={data['nbArticleDirectN']} valueN_1={data['nbArticleDirectN-1']}/>
                        <div>
                            <span className="date"></span>
                            <BarChart data={data.topCategories == null ? [] : data.topCategories.map(el => {return {name: el.nom, valueN: el['nbArticleN'], valueN_1: el['nbArticleN-1']}})} title={"Catégories les plus sorties"} colorTheme={'blue'} style={{height:400}}/>
                        </div>
                    </div>  
                }
        </div>
    )
}