import { useEffect, useState } from "react";
import { getCaisse } from "../../api/statistiquesApi";
import { useDispatch } from "react-redux";
import { addErrorMessage } from "../../features/errorSlice";
import Loader from "../Loader";
import { SmallBlock, StatistiquesTitle } from "./DataBlocks";
import dayjs from "dayjs";

export default function CaisseLocation ({date, id}) {

    let dispatch = useDispatch();
    let [data, setData] = useState({});

    useEffect(() => {
        getCaisse (id, 'loc', '@EXERCICEDEBUT@', date)
        .then(res=>{
            if (res) {
                setData(res);
            }
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
        });
    }, []);



    return (
        <div className="Block">
            <StatistiquesTitle title="Caisse de location" shopsKO={data.shopsKO}/>
            {
               data.shopsKO == null ?
               <Loader/>
               : 
               data.dateDebut == null ? 
                   null :
                    <div>
                        <span className="date">{'du ' + dayjs(data.dateDebut).format('dddd D MMMM YYYY') + ' au ' + dayjs(data.dateFin).format('dddd D MMMM YYYY')}</span>
                        <div className="flex">
                            <SmallBlock title="Montant location" valueN={data['montantLocN']} valueN_1={data['montantLocN_1']} unit={String.fromCodePoint(8364)}/>
                            <SmallBlock title="Montant panier moyen" valueN={data['panierMoyenN']} valueN_1={data['panierMoyenN_1']} unit={String.fromCodePoint(8364)}/>
                        </div>
                    </div>  
            }
        </div>
    )
}