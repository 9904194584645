import { useEffect, useState } from "react";
import { getChiffreAffairesCumul} from "../../api/statistiquesApi";
import { useDispatch } from "react-redux";
import { addErrorMessage } from "../../features/errorSlice";
import Loader from "../Loader";
import { LineChart } from "../charts/LineChart";
import dayjs from "dayjs";

export default function ChiffreAffairesCumul ({date, id, chartHeight = '500px'}) {

    const dispatch = useDispatch();
    const [dataN, setDataN] = useState(null);
    const [dataN_1, setDataN_1] = useState(null);
    useEffect(() => {
        getChiffreAffairesCumul(dayjs(date).format('YYYY'), id)
        .then(res=>{
            console.log(res);
            if (res) {
                setDataN(res);
            }
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
        });
        getChiffreAffairesCumul(parseInt(dayjs(date).format('YYYY')) -1, id)
        .then(res=>{
            console.log(res);
            if (res) {
                setDataN_1(res);
            }
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
        });
    }, [id]);



    return (
        ! dataN ?
        <Loader/>
        : 
        dataN.length == 0 ? 
        null :
        <div>
            <LineChart
                xAxisValues={dataN.map(el => el.date.substring(5))}
                series={
                    getSeries(dataN, dataN_1)
                }
                style={{height : chartHeight}}/>
        </div>  

    )
}



function getSeries (dataN, dataN_1) {
    let series = [];

    let colors = {
        venteN:"#5698e8",
        venteN_1:"#90c7e8",
        locationN: "#88b844",
        locationN_1: "#d5e070",
        totalN: "#7c86ec",
        totalN_1:"#cc85f2"
    }

    if (dataN) {
        series = [
            ...series,
            {
                name:'vente N',
                color: colors.venteN,
                type:'solid',
                unit: String.fromCodePoint(8364),
                data: dataN.map(el => el.chiffreAffairesCumulVente)
            },
            {
                name:'location N',
                color: colors.locationN,
                type:'solid',
                unit: String.fromCodePoint(8364),
                data: dataN.map(el => el.chiffreAffairesCumulLoc)
            },
            {
                name:'total N',
                color: colors.totalN,
                type:'solid',
                unit: String.fromCodePoint(8364),
                data: dataN.map(el => el.chiffreAffairesCumulLoc + el.chiffreAffairesCumulVente)
            },

        ]
    }
    if (dataN_1) {
        series = [
            ...series,
            {
                name:'vente N-1',
                color: colors.venteN_1,
                type:'dotted',
                unit: String.fromCodePoint(8364),
                data: dataN_1.map(el => el.chiffreAffairesCumulVente)
            },
            {
                name:'location N-1',
                color: colors.locationN_1,
                type:'dotted',
                unit: String.fromCodePoint(8364),
                data: dataN_1.map(el => el.chiffreAffairesCumulLoc)
            },
            {
                name:'total N-1',
                color: colors.totalN_1,
                type:'dotted',
                unit: String.fromCodePoint(8364),
                data: dataN_1.map(el => el.chiffreAffairesCumulLoc + el.chiffreAffairesCumulVente)
            }

        ]
    }

    return series;
}