import { useEffect, useState } from "react";
import { getSorties } from "../../api/statistiquesApi";
import { useDispatch } from "react-redux";
import { addErrorMessage } from "../../features/errorSlice";
import Loader from "../Loader";
import { LargeBlock, StatistiquesTitle } from "./DataBlocks";
import dayjs from "dayjs";
import { BarChart } from "../charts/BarChart";

export default function PerformancesCategorie ({date, id}) {

    let dispatch = useDispatch();
    let [data, setData] = useState({});

    useEffect(() => {
        getSorties (id, date, 10)
        .then(res=>{
            if (res) {
                setData(res);
            }
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
        });
    }, []);



    return (
        <div className="Block">
            <StatistiquesTitle title="Performances par catégories" shopsKO={data.shopsKO}/>
            {
                data.shopsKO == null ?
                <Loader/>
                : 
                data.date == null ? 
                    null :
                <div>
                    <span className="date">{dayjs(data.date).format('dddd D MMMM YYYY')}</span>
                    <LargeBlock title="Total des articles dehors" valueN={data['nbArticleN']} valueN_1={data['nbArticleN-1']}/>
                    <BarChart data={data.topCategories.map(el => {return {name: el.nom, valueN: el.nbArticleN, valueN_1: el['nbArticleN-1']}})} title='Top 10 des catégories' mainColor={'blue'} style={{height:'600px'}}/>
                </div>  
            }
        </div>
    )
}