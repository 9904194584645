import { useNavigate } from "react-router-dom";
import { BackIcon } from "./Icons";

import '../styles/title.css';

export function Title ({title, children, backTo='/'}) {

    const navigate = useNavigate();

    const onClickBack = () => {
        navigate(backTo);
    }

    return (
        <h1 className='Title' onClick={onClickBack}>
                {children}
                <span>{title}</span>
                <button tabIndex={-1}><BackIcon/></button>
        </h1>
    )
}

export function BlockTitle ({title, icon, onClick=null, children}) {

     const onClickTitle = () => {
        if (onClick && typeof onClick === "function")
            onClick();
    }


    return (
        <h2 className="BlockTitle" onClick={onClickTitle}>
            {icon}
            <span>{title}</span>
            {children}
        </h2>
    )
}