import { useState } from 'react';
import { login } from '../api/authApi.js'
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { loginSuccess } from '../features/authSlice.js'
import { TextInput, PasswordInput } from '../components/Inputs.js';
import { StandardButton } from '../components/Buttons.js';

import Loader from '../components/Loader.js';
import { getUrlLogo } from '../utils/Theme.js';
import { HomeFooter } from '../components/HomeHeaderFooter.js';
import { updateStorageTheme } from '../utils/Theme.js';

import '../styles/login.css';

export default function Login() {
  const [formData, setFormData] = useState({
    username:"",
    password:"",
  });
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isLogged = useSelector((state) => state.user.active);
  const role = useSelector((state) => state.user.role);


  const handleSubmit = async e => {
    e.preventDefault();
    if (formData.username === "" || formData.password === ""){
      setError ("Veuillez renseigner le(s) champ(s) manquant(s)");
      return;
    }
    setLoading(true);
    login({
      username:formData.username,
      password:formData.password
    })
    .then(res =>{
      setLoading(false);
      if (!res.success){
        setError(res.message);
        return;
      }  
      setError(false);
      dispatch(loginSuccess(res));
      updateStorageTheme(res.settings.theme);   
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
      setError("Une erreur est survenue");
    })
  }
  
  const handleChange = e =>{
    const { name, value } = e.target;
    setError(false);
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  }



  if (isLogged){
    if (role === 'admin') return <Navigate to="/admin" />;
    else if (role === 'supervisor') return <Navigate to="/supervisor" />;
    else if (role === 'user') return <Navigate to="/" />;
  }
  
  return(
    <div>
      <img className='fond' src="fond.jpg"></img>
      <form className='loginForm' onSubmit={handleSubmit}>
        <img className='logo' src={getUrlLogo(localStorage.getItem('theme'))}/>
        <h1>Bienvenue !</h1>
        <div className='error'>{error !== false ? error : ' '}</div>
        <TextInput label={'nom d\'utilisateur'} name={'username'} value={formData.username} onChange={handleChange}></TextInput>
        <PasswordInput label={'mot de passe'} name={'password'} value={formData.password} onChange={handleChange}></PasswordInput>
        <div className="buttonContainer">
          {loading ? <Loader width={35} height={35}/> : <StandardButton label="Connexion"></StandardButton>}
        </div>
        <HomeFooter/>
      </form>
    </div>
   )
}