
export async function previsionsSorties(date) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/sorties/previsions/'+date, {
        method: 'GET',
        credentials: 'include',
    });

    if (response.status>=400) throw new Error("previsionsSorties api error");
    let result = await response.blob();
    return result;

}
