import { getChartColorTheme } from "../../utils/Charts";
import { EChart } from "./EChart";

export function BarChart ({data, mainColor, style, title}){

    let colorTheme = getChartColorTheme(mainColor);
  
    let options = {
        exporting: {
            enabled: false
        },
        grid: {
            left: 10,
            top: 0,
            right:10,
            bottom: 0,
            containLabel :true
        },
        xAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value}',
            }
        },
        yAxis: {
            type: 'category',
            inverse: true,
            data: data.map(el => el.name.substring(0,15)+(el.name.length > 15 ? "..." : "")),
            axisLabel: {
                overflow : 'break',
                interval : 0,
                height: 50,
                fontSize : 12,
    
            }
        },
        series: [
            {
                type: 'bar',
                data: data.map(el => el.valueN),
                label: {show :true},
                color: colorTheme.colorN,
                itemStyle : { 
                    normal: {
                        label : {
                            fontSize : 12,
                            show: true, 
                            position: 'inside',
                            color :colorTheme.labelColorN
                        }
                    }
                },
            },
            {
                selected: false,
                color: colorTheme.colorN_1,
                type: 'bar',
                label:{show: true},
                data: data.map(el => el.valueN_1),
                itemStyle : { 
                    normal: {
                        label : {
                            fontSize : 12,
                            show: true, 
                            position: 'inside',
                            color :colorTheme.labelColorN_1
                        }
                    }
                }
            }
        ]
        }
  
    return  (
      <div className="BarChart">
        <div style={{color:colorTheme.colorN}}>{title}</div>
        <div>
            <span style={{color:colorTheme.colorN}}>N</span><span style={{color:colorTheme.colorN_1}}>N-1</span>
        </div>
        <EChart option={options} style={style}/> 
      </div>
    )
  
  }