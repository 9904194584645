import { createSlice } from '@reduxjs/toolkit'

export const shopSlice = createSlice({
  name: 'shops',
  initialState: [],
  reducers: {
    setSupervisorShops: (state, action) => {
        console.log(action.payload.shops);
        return [...action.payload.shops];
       
    },

    setSupervisorShops: (state, action) => {
      return [...action.payload.shops];
     
    },

    setShopData: (state, action) => {
      let index = state.map(function(e) { return e.id; }).indexOf(action.payload.id);
      state[index].data = action.payload.data;
     
    },

    clearShopData: (state, action) => {
      let index = state.map(function(e) { return e.id; }).indexOf(action.payload.id);
      state[index].data = null;
     
    },

    clearAllShopsData: (state, action) => {
      return state.map((el) => {return {
        ...el,
        data:null
      }})
     
    },
   
  }
})

export const { setSupervisorShops, setShopData, clearShopData, clearAllShopsData } = shopSlice.actions;
export default shopSlice.reducer;
