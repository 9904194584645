import { useState } from 'react';
import '../styles/videoLink.css'
import { VideoIcon } from './Icons'
import { Modal } from './Modals'
import { consultationTuto } from '../api/aideApi';

export function VideoLink({link, libelle, small = false}){

    const [isOpen, setIsOpen] = useState(false);

    const onClickButton = () => {
        consultationTuto(libelle);
        setIsOpen(true);
    }

    return (
        <div>
            <div className={"VideoLink" + (small ? " small" : "")}>
                <button className='videoButton' onClick={onClickButton}>
                    <VideoIcon/>
                </button>
            </div>
            {isOpen ? 
                <Modal width='90vw' height='90vh' closeCallback={() => setIsOpen(false)}>
                    <iframe width="100%" height="100%" src={link+"?&rel=0"} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin"></iframe>
                </Modal>
                :null
            }
        </div>
       
    )
}


