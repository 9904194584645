import errorHandler from "../utils/ErrorHandler";


export async function getListeAide () {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/aide', {
        method: 'GET',
        credentials: 'include',
    })
    .catch(err=>{
        console.log(err);
    });

    if (response.status>=500) throw new Error("getLogsConsultation api error");
    let result = await response.json();
    result = errorHandler(result);
    return result.aide
}

export async function consultationAide (libelle) {
    await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/aide/'+libelle, {
        method: 'GET',
        credentials: 'include',
    })
    .catch(err=>{
        console.log(err);
    })
}

export async function consultationTuto (libelle) {
    await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/aide/tuto/'+libelle, {
        method: 'GET',
        credentials: 'include',
    })
    .catch(err=>{
        console.log(err);
    })
}

export async function getLogsConsultationUser (userId, detail) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/aide/logs/user/'+userId+'/'+detail, {
        method: 'GET',
        credentials: 'include',
    })
    
    if (response.status>=500) throw new Error("getLogsConsultationUser api error");
    let result = await response.json();
    result = errorHandler(result);
    return formatUserAideData(result.data, detail)
}

export async function getLogsConsultationLibelle (type, libelle, detail) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/aide/logs/libelle/'+type+'/'+libelle+'/'+detail, {
        method: 'GET',
        credentials: 'include',
    })
    
    if (response.status>=500) throw new Error("getLogsConsultationLibelle api error");
    let result = await response.json();
    result = errorHandler(result);
    return formatLibelleAideData(result.data, detail)
}

function formatUserAideData (data, detail) {

    if (detail) {
        return data.map(el => {
            return {
                name: el.date,
                valueN: el.nombreN,
                valueN_1: el.nombreN_1
            }
        }).sort((a,b) => a.valueN > b.valueN ? -1 : 1);
    }

    return data.map(el => {
        return {
            name: el.type + ' ' + el.libelle,
            valueN: el.nombreN,
            valueN_1: el.nombreN_1
        }
    }).sort((a,b) => a.valueN > b.valueN ? -1 : 1);
}

function formatLibelleAideData (data, detail) {

    if (detail) {
        return data.map(el => {
            return {
                name: el.date,
                valueN: el.nombreN,
                valueN_1: el.nombreN_1
            }
        }).sort((a,b) => a.valueN > b.valueN ? -1 : 1);
    }

 
    return data.map(el => {
        return {
            name: el.username,
            valueN: el.nombreN,
            valueN_1: el.nombreN_1
        }
    }).sort((a,b) => a.valueN > b.valueN ? -1 : 1);
    
}