import { useState, useEffect } from 'react';
import Loader from '../../components/Loader.js';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { addErrorMessage } from '../../features/errorSlice.js';
import { StandardButton } from '../../components/Buttons.js';
import { getShops } from '../../api/shopsApi.js';
import { ShopIcon } from '../../components/Icons.js';
import { Title } from '../../components/Title.js';


export default function ShopList (){

    const [shops, setShops] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => { 
        getShops()
        .then(res=>{
            setShops(res);
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
        });
        setIsLoading(false);

    }, []);

    
    return ( 
        <div className='ItemList'>
            <Title title="Liste des magasins" backTo="/admin"><ShopIcon/></Title>
            <div className='Block'>
                <div className='header'>
                    <StandardButton label="Créer un magasin" onClick={() => navigate('new')}/>
                </div>
                {isLoading ?
                    <Loader></Loader> :
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Nom</th>
                                <th>Id Base</th>
                                <th>Code Magasin</th>
                                <th>Code Caisse</th>
                                <th>Lot</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                shops.map((shop) => {
                                    return <ShopRow key={shop.id} shop={shop}></ShopRow>
                                })
                            } 
                        </tbody>
                    </table>
                }
            </div>
        </div> 
    )
}


function ShopRow ({shop}) {

    return (
       <tr className='ItemRow'>
            <td>{shop.id}</td>
            <td>
                <Link to={'/admin/shops/'+shop.id}>{shop.name}</Link>
            </td>
            <td>{shop.idBase}</td>
            <td>{shop.numeroMagasin}</td>
            <td>{shop.codeCaisse}</td>
            <td>
                <Link to={'/admin/lot/'+shop.idLot}>{shop.lotName}</Link>
            </td>
       </tr>
     )
 }
 