import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'user',
  initialState: {
    username: null,
    active: false,
    role: null,
    connections: null,
    parametres:{
      theme: null,
      voucher:true
    },
    shopName: null
  },
  reducers: {
    loginSuccess: (state, action) => {
      console.log(action.payload);
      return{
        ...state,
        active: true,
        role: action.payload.role,
        username: action.payload.username,
        shopName: action.payload.shopName,
        parametres: {...action.payload.settings, voucher: state.parametres.voucher}
      }
    },
    logoutSuccess: (state) => {
      return{
        ...state,
        active: false,
        role: null,
        connections:null
      }
    },
    setConnections: (state, action) => {
      return{
        ...state,
        connections: {
            skilouApp: action.payload.skilouApp,
            mySkilou: action.payload.mySkilou
        }
      }
    },
    setParametres: (state, action) => {
      return{
        ...state,
        parametres: {...action.payload.settings, voucher: state.parametres.voucher}
      }
    },
    setTheme: (state, action) => {
      return{
        ...state,
        parametres: {
          ...state.parametres,
          theme: action.payload.theme
        }
      }
    },
    setPopupNouveautes: (state, action) => {
      return{
        ...state,
        parametres: {
          ...state.parametres,
          popupNouveautes: action.payload.popupNouveautes
        }
      }
    },
    setVoucher: (state, action) => {
      return{
        ...state,
        parametres: {
          ...state.parametres,
          voucher: action.payload.voucher
        }
      }
    },
  }
})

export const { 
  loginSuccess, 
  logoutSuccess, 
  setConnections,
  setParametres, 
  setTheme,
  setVoucher, 
  setPopupNouveautes } = authSlice.actions;
  
export default authSlice.reducer;
