import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";

import { PlusIcon, MinusIcon } from "../Icons";
import Loader from "../Loader";
import { DateTimeInput } from "../Inputs";

import '../../styles/editableField.css'

export function PlusMinusNumberField ({initialValue, onFocusOut, edit, setEdit, step, style, loading}){
    const [value, setValue] = useState(initialValue);
    const wrapperRef = useRef(null);
    
    const handleValueChange = (val) => {
        setValue(val)
    }

    const handleKeyDown = (e) =>{
        if(e.key === 'Enter') onConfirmValue(value);
        else handleValueChange(e.target.value);
    }

    const onConfirmValue = (value) => {
        if (value%step > 0){
            setValue(initialValue);
            onFocusOut(initialValue);
        }
        else onFocusOut(value);
    }

    useEffect(() => {
        function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            onConfirmValue(value);
        }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    
    return (
        <div className='editableField plusMinus' style={style}>
            
            {loading ?
                <Loader height={20} width={20}/> :
                (edit ? 
                <div ref={wrapperRef}>
                    <button onClick={()=>handleValueChange(parseFloat(value)-step)}><MinusIcon/></button>
                    <input type='number' step={step} autoFocus value={value} onKeyDown={(e) => handleKeyDown(e)} onChange={(e) => handleValueChange(e.target.value)}/>
                    <button onClick={()=>handleValueChange(parseFloat(value)+step)}><PlusIcon/></button>
                </div> :
                <span onClick={() => setEdit(true)}>{initialValue}</span>)
            }
        </div>         
    )
}

export function NumberField ({initValue, onFocusOut, step, inputStyle, unit, edit, setEdit, loading}){
    const [value, setValue] = useState(0);

    const handleKeyDown = (e) =>{
        if(e.key === 'Enter') onFocusOut(value);
        else handleValueChange(e.target.value);
    }

    const handleValueChange = (val) => {
        setValue(val);
    }

        
    useEffect(() => {
        setValue(initValue);
    }, [initValue]);


    return (
        <div className="editableField">
            {loading ?
                <Loader height={20} width={20}/> :
                (edit ?
                    <input
                    type='number' 
                    step={step} 
                    autoFocus 
                    value={value} 
                    style={inputStyle}
                    onKeyDown={(e) => handleKeyDown(e)} 
                    onChange={(e)=>handleValueChange(e.target.value)} 
                    onBlur={(e)=>onFocusOut(e.target.value)}
                    />:
                    <span onClick={() => setEdit(true)}>{initValue}&nbsp;{unit}</span>)
            }

        </div>


    )
}


export function DateTimeField ({initValue, onFocusOut, edit, setEdit, maxDate=null}){

    const [value, setValue] = useState(initValue);
    const [isOpen, setIsOpen] = useState(false);
    const wrapperRef = useRef(null);

    const handleKeyDown = (e) =>{
        if(e.key === 'Enter') onFocusOut (value);
    }
    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                if(!isOpen) onFocusOut(value);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });



    return (
        <div className="editableField dateTime">
            {edit ?
                <div onKeyDown={handleKeyDown} ref={wrapperRef}>
                    <DateTimeInput
                        autoFocus
                        value={value} 
                        maxDate={maxDate}
                        onOpen={()=>setIsOpen(true)}
                        onClose={()=>setIsOpen(false)}
                        onAccept={(val) =>onFocusOut(val)}
                        onChange={(val) => setValue(val)} 
                    />
                </div> :
                <span onClick={() => setEdit(true)}>{initValue.format('DD/MM/YYYY HH:mm')}</span>
            }
        </div>


    )
}