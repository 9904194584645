import Palmares from "../components/statistiques/Palmares";
import { StatsIcon } from "../components/Icons";
import { Title } from "../components/Title";
import dayjs from "dayjs";
import Sorties from "../components/statistiques/PrevisionsSorties";

import '../styles/statistiques.css';

export default function Statistiques () {

    let date = dayjs().format('YYYY-MM-DD');

    return (
        <div className='Statistiques'>
            <Title title="Statistiques"><StatsIcon/></Title>
            <Sorties date={date}/>
            <Palmares of={"familles"} date={date} top={10}/>
            <Palmares of={"produits"} date={date} top={10}/>
        </div>
    )
}

