import { useDispatch, useSelector } from "react-redux";
import { SettingsIcon } from "../components/Icons";
import { Title } from "../components/Title";
import { updateSettings } from "../api/usersApi";
import { addErrorMessage } from "../features/errorSlice";
import { updateStorageTheme } from "../utils/Theme";
import { ToggleSwitch } from "../components/Inputs";
import { useState } from "react";
import { StandardButton } from "../components/Buttons";
import { setParametres } from "../features/authSlice";

import "../styles/settings.css";
import { useNavigate } from "react-router-dom";

export default function Settings ({role}) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({...useSelector((state) => state.user.parametres)});

    const onClickSave = () => {
       
        updateSettings({...formData})
        .then((res) =>{
            console.log(res);
            dispatch(setParametres({settings:res.settings}));
            updateStorageTheme(res.settings.theme);
            navigate('/');
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
        });

    }

    return (
        <div className='Settings'>
                <Title title="Paramètres"><SettingsIcon/></Title>
                <div className="Block">
                   <ThemeSettings formData={formData} setFormData={setFormData}/>
                   {role === "user" ? <FicheSettings formData={formData} setFormData={setFormData}/> : null}
                    <div className="footer">
                        <StandardButton onClick={onClickSave} label="Enregistrer"/>
                    </div>
                </div>
        </div>
    )
}


function ThemeSettings ({formData, setFormData}) {

    const possibleThemes = [
        {value:'blue', color:"#71a0c2"}, 
        {value:'purple', color:"#ad89e2"}, 
        {value:'green', color:"#379e7b"}, 
        {value:'yellow', color:"#f89c2e"}
    ];

    const onClickTheme = (newTheme) => {
        setFormData({...formData, theme: newTheme})
    }

    return (
        <div className="theme">
            <h3>Thème :</h3>
            <div>
                {possibleThemes.map (t => {
                    return (
                        <div 
                            key={t.value}
                            className={"themePicker" + (t.value === formData.theme ? " selected": "")}
                            style={{background:t.color}}
                            onClick={() => onClickTheme(t.value)}
                            >

                        </div>
                    )
                })}
            </div>
        </div>
    )

}

function FicheSettings ({formData, setFormData}) {
    return (
        <div className="theme">
            <h3>Vente et Location :</h3>
            <div>
                <ToggleSwitch 
                    isChecked={formData.retourAccueilFiche}
                    onChange={() => setFormData({...formData, retourAccueilFiche:!formData.retourAccueilFiche})}
                    label="Retourner à la page d'accueil après enregistrement de la fiche"/>
            </div>
        </div>
    )

}
