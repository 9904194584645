export default function Aide() {

    const style = {
        position:'absolute',
        left: 0,
        right:0,
        padding:0
    }

    return (
        <iframe style={style} src={process.env.PUBLIC_URL + '/aide.html'}/>
    );
  }