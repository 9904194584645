import '../../styles/ficheButtons.css'
import { BackIcon } from '../Icons.js';

export function LocationButton({article, onClick, dispo}) {

    const getDispoClassName = (total, dispo) => {
        if (dispo == 999 || dispo == null) return "";
        if (dispo<0) return 'darkRed';
        else if (dispo == 0 || total == 0) return 'red';

        let ratio = 100*(total-dispo)/total;

        if (ratio>75) return 'orange';
        if (ratio>50) return 'yellow';
        return 'green'; 

    }

    return (
    <button className="ficheButton location" onClick={() => onClick(article.ean13)}>
        {article.code}
        {dispo == null ? 
            null : 
           <div className={"bulle "+getDispoClassName(dispo.total, dispo.dispo)}>{dispo.dispo}</div>
        } 
    </button>
    )
}

export function LocationFamilleButton({code, onClick}) {
    return (
    <button className="ficheButton famille" onClick={() => onClick(code)}>{code}</button>
    )
}

export function BackButton({className, onClick=null}) {

    const onClickButton = () => {
        if (onClick && typeof onClick === "function") {
            onClick(null);
        }
    }
    return (
        <button className={"ficheButton " + className} onClick={onClickButton}>
            <BackIcon/>
        </button>
    )
}

export function PaymentMethodButton({paiement, onClick}) {
    const onClickButton = () => {
        if (onClick && typeof onClick === "function") {
            onClick(paiement.Code);
        }
    }
    return (
        <button onClick={onClickButton} className="ficheButton paiement">{paiement.Code}</button>
    )
}

export function CustomableButton({onClick, label, className, isLocked=false}) {
    const onClickButton = () => {
        if (onClick && typeof onClick === "function") {
            onClick();
        }
    }
    return (
        <button className={"ficheButton "+className} onClick={onClickButton} disabled={isLocked}>{label}</button>
    )
  }