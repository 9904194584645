import errorHandler from "../utils/ErrorHandler";

export async function envoiTicket(data) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/tickets/', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    });

    if (response.status>=500) throw new Error("envoiTicket api error");
    let result = await response.json();

    return errorHandler(result);
}

export async function getVoucher(numeroFiche, params) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/tickets/voucher/'+numeroFiche, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
    });

    
    if (response.status>=500) throw new Error("editeCaisse api error");
    let result = await response.blob();
    return result;

}
