import errorHandler from "../utils/ErrorHandler";

export async function getReservations(data) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/reservations', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    });

    if (response.status>=500) throw new Error("getReservations api error");
    let result = await response.json();
    result = errorHandler(result);
    if (!result) return [];
    return result.reservations;

}

export async function getDetailReservation(idResa) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/reservations/'+idResa, {
        method: 'GET',
        credentials: 'include',
    });

    if (response.status>=500) throw new Error("getReservations api error");
    let result = await response.json();
    result = errorHandler(result);
    if (!result) return [];
    return formatDetailReservation(result.detail);

}


function formatDetailReservation (detailResa) {
    return detailResa.map((el) => {
        return {
            code:el.Code_cat,
            categorie: el.Libelle_pack,
            dateDebut: el.Date_debut,
            dateFin: el.Date_fin,
            duree: el.Nb_Jours
        }
    })
}
