import errorHandler from "../utils/ErrorHandler";

export async function getGrillesPrix() {

    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/prix/', {
        method: 'GET',
        credentials: 'include'
    });

    if (response.status>=500) throw new Error("getGrillesPrix api error");
    let result = await response.json();
    result = errorHandler(result);
    if (!result) return [];
    return result.grilles;

}


export async function updateGrillePrix(codeGrille, prix) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/prix/update/', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({codeGrille: codeGrille, prix:prix}),
    });

    if (response.status>=500) throw new Error("updateGrillePrix api error");
    let result = await response.json();
    return errorHandler(result);

}