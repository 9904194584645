import { forwardRef } from "react";
import '../styles/buttons.css'


export const StandardButton = forwardRef(({label, onClick=null, isLocked, tabIndex}, ref) => {

    const onClickButton = (e) => {
        if (onClick && typeof onClick === "function") {
            onClick(e);
          }
    }

    return (
        <button className="standardButton" disabled={isLocked} onClick={onClickButton} tabIndex={tabIndex} ref={ref}>{label}</button>
    )
})

export const UnderlindedButton = forwardRef(({label, onClick=null, isLocked, tabIndex}, ref) => {

    const onClickButton = (e) => {
        if (onClick && typeof onClick === "function") {
            onClick(e);
          }
    }

    return (
        <button className="underlinedButton" disabled={isLocked} onClick={onClickButton} tabIndex={tabIndex} ref={ref}>{label}</button>
    )
})