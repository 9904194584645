
export function isCBString (string){
    return string.startsWith('%B') // && string.length === 117; 
} 

export function isErrorCBString (string){
    return string.startsWith('%E');
} 

export function getCBInfo (cbString){
    console.log(cbString);
    if (!cbString.startsWith('%B')) return null;
    cbString = cbString.substring(2);
    let split = null;
    if (cbString.includes('^')) split = cbString.split('^');
    else if (cbString.includes('�')) split = cbString.split('�');
    else if (cbString.includes('^A')) split = cbString.split('^A');
    else if (cbString.includes('�')) split = cbString.split('�');

    if (split == null) return null;

    let nom = "";
    let prenom = "";
    let date = "";

    if (split.length>1) {
        let infosIdentite = split[1].trim().split('/');
        nom = infosIdentite[0];
        if (infosIdentite.length>1) prenom = infosIdentite[1].split('.')[0]
    }


    if (split.length>2) {
        let infosDate = split[2].trim();
        if (infosDate.length>3) date = infosDate.substring(0,4);
    }


    return {
        nom:nom,
        prenom:prenom,
        numeroCB: formatCardNumber(split[0]),
        dateExp: formatDateExp(date)
    }
}


export function formatCardNumber (cardNumber) {
    let inputNumbersOnly = cardNumber.replace(/\D/g, ""); // Get only digits
    if (inputNumbersOnly.length > 16) {
        //If entered value has a length greater than 16 then take only the first 16 digits
        inputNumbersOnly = inputNumbersOnly.substr(0, 16);
    }

   // Get nd array of 4 digits per an element EX: ["4242", "4242", ...]
    const splits = inputNumbersOnly.match(/.{1,4}/g);

    let spacedNumber = "";
    if (splits) {
        spacedNumber = splits.join(" "); // Join all the splits with an empty space
    }

    return spacedNumber; // Set the new CC number
};

export function formatDateExp (date) {
    let inputNumbersOnly = date.replace(/\//g, "");

    if (inputNumbersOnly.length > 4) {
        inputNumbersOnly = inputNumbersOnly.substr(0, 4);
    }

    const splits = inputNumbersOnly.match(/.{1,2}/g);

    let spacedNumber = "";
    if (splits) {
        spacedNumber = splits.join("/"); 
    }

    return spacedNumber;
};

