import * as XLSX from 'xlsx';
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Title } from "../components/Title";
import { getDetailMouvementStock, getHistoriqueMouvementsStock, mouvementStock } from "../api/stockApi";
import { Dialog, Modal } from "../components/Modals";
import { StandardButton } from "../components/Buttons";
import { useParams } from "react-router-dom";
import { ChevronIcon, ExcelIcon, BoxIcon } from "../components/Icons";
import { getProduits } from "../api/produitsApi";
import { addErrorMessage } from "../features/errorSlice";
import { SimpleTextInput, ToggleSwitch } from "../components/Inputs";
import Loader from "../components/Loader";

import '../styles/etatStocks.css';
import { VideoLink } from '../components/VideoLink';

export default function EtatStocks (){

    let dispatch = useDispatch();
    const { id } = useParams();

    const [produits, setProduits] = useState([]);

    const [alerte, setAlerte] = useState(false);
    const [stock, setStock] = useState(false);
    const [file, setFile] = useState(null);
    const [editStocks, setEditStocks] = useState(false);
    const [correctionsStock, setCorrectionsStock] = useState([]);

    const role = useSelector((state) => state.user.role);
        
    useEffect(() => {
        getProduits(0, 0, id)
        .then(res=> setProduits(res))
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error", message:"Impossible de charger la liste des produits"})); //TODO : revoir la gestion d'erreur
        });

        return () => {
            setProduits([]);
        };
        
    }, [editStocks]);


    const onCloseModal = () => {
        setFile(null);
    }


    const updateStock = (ean13, stock) => {
        setCorrectionsStock([
            ...correctionsStock.filter((el) => el.ean13 !== ean13),
            {
                ean13:ean13,
                quantite: stock
            }
        ])
    }

    const enregistreStock = () => {
        if (correctionsStock.length == 0) {
            setEditStocks(false);
            return;
        }
        setFile('loading');
        mouvementStock('correction', correctionsStock)
        .then(res => {
            if (res.type == null || !res.type.includes('pdf')) {
                if (res.success) {
                    dispatch(addErrorMessage({type:"success", message:'La correction de stock a été enregistrée avec succès'}));
                    dispatch(addErrorMessage({type:"warning", message:'le récapitulatif n\'a pas pu être édité, essayez de le générer de nouveau depuis le menu contrôle de stock'}));
                    setCorrectionsStock([]);
                    setEditStocks(false);
                }
                setFile(null);
                return;
            }
            
            setFile(window.URL.createObjectURL(res));
            setCorrectionsStock([]);
            setEditStocks(false);
        })
        .catch(error =>{
            console.log(error);
            setFile(null);
            dispatch(addErrorMessage({type:"error", message:"Erreur lors de l'enregistrement de la correction de stock, veuillez réessayer"}));
        });
    }


    return (
        <div className='EtatStocks'>
            <Title title="Etat des stocks" backTo={role === 'user' ? '/':'/supervisor/shops'}><BoxIcon/></Title>
            <div className="Block">
                <h3>Paramètres :</h3>
                <ToggleSwitch label="Produits en alerte stock uniquement" name="alerte" value="1" isChecked={alerte} onChange={(e) => setAlerte(e.target.checked)}/>
                <ToggleSwitch label="Produits en stock uniquement" name="stock" value="1" isChecked={stock} onChange={(e) => setStock(e.target.checked)}/>
                <h3>Export :</h3>
                <ExportData produits={produits} stock={stock} alerte={alerte}/>
            </div>
            <div className="Block">
                {produits.length == 0 ? 
                    <Loader/> :
                    <table className="tableEtat">
                        <thead>
                            <tr>
                                <th>Code</th>
                                <th>Libellé</th>
                                <th>Stock Actuel</th>
                                <th>Stock Mini</th>
                                <th></th>
                            </tr>
                        </thead>
                        {produits.map(famille=>{
                            return <BlocFamille key={famille.code} famille={famille} stock={stock} alerte={alerte} editStocks={editStocks} updateStock={updateStock}/>
                        })}
                    </table>
                    
                }
            </div>
            {
                role === "user" ?
                <div className="Block">
                    {
                        produits.length > 0 ?
                        <HistoriqueMouvements editStocks={editStocks} setEditStocks={setEditStocks} enregistreStock={enregistreStock} setFile={setFile}/>
                        :null
                    }
                </div>
                : null
            }
            {file == null ? 
                null :
                <Modal width="90vw" height="90vh" closeCallback={onCloseModal}>
                    <div className="frameContainer">
                        { file === "loading" ?
                            <Loader/> :
                            <iframe className="pdfFrame" src={file}/>
                        }
                        
                    </div>
                </Modal>
            }
            <VideoLink link={"https://www.youtube.com/embed/9c4oFCk_f_8?si=VszvtQyrkvEG_dwi"} libelle='etatStock'/>

        </div>
    )
}


function BlocFamille ({famille, stock, alerte, editStocks, updateStock}) {
    return (
        <tbody>
            <tr className="ligneFamille">
                <td>{famille.code}</td>
                <td>{famille.libelle}</td>
                <td>{famille.stock}</td>
                <td>{famille.stockMini}</td>
                <td></td>
            </tr>
            {famille.produits.map(produit=>{
                return <LigneProduit key={produit.code} produit={produit} stock={stock} alerte={alerte} editStocks={editStocks} updateStock={updateStock}/>
            })}
        </tbody>
    )
}

function LigneProduit ({produit, stock, alerte, editStocks, updateStock}) {

    const [showDetail, setShowDetail] = useState(false);

    const noDetail = produit.tailles.length === 1 && produit.tailles[0].couleurs.length === 1       

    //si le produit a au moins une taille en alerte stock
    const warning  = produit.tailles.map((t) => {
            return t.couleurs.find((c) => c.stock <= c.stockMini);
        }).filter((el) => el != null).length > 0;

    return (
       alerte && ! warning || stock && warning  ? null : [
        <tr key={"ligneProduit"} className={"ligneProduit "+ (warning  ? "warning": "")}>
            <td>{produit.code}</td>
            <td>{produit.libelle}</td>
            <td>
                { editStocks && noDetail ?
                    <EditStockInput 
                        stockIni={produit.stock} 
                        updateStock={updateStock} 
                        ean13={produit.tailles[0].couleurs[0].ean13}/> :
                    produit.stock
                }
            </td>
            <td>{produit.stockMini}</td>
            <td>
                {noDetail? null : 
                    <button className={showDetail ? 'open':'close'} onClick={()=>setShowDetail(!showDetail)}>
                        <ChevronIcon/>
                    </button>
                }
            </td>
        </tr>,
        <DetailProduit key={"detailProduit"} tailles={produit.tailles} show={showDetail} editStocks={editStocks} updateStock={updateStock}/>
        ]
    )
}

function DetailProduit ({tailles, show, editStocks, updateStock}) {
    return (
        show ? tailles.map(taille=>{
            return taille.couleurs.map(couleur=>{
                return <LigneTailleCouleur key={taille.code+"_"+couleur.code} taille={taille} couleur={couleur} editStocks={editStocks} updateStock={updateStock}/>
            })
        }) : null
    )
}

function LigneTailleCouleur ({taille, couleur, editStocks, updateStock}){

    const warning = couleur.stock<= couleur.stockMini

    return (
        <tr className={"ligneTailleCouleur "+( warning ? "warning" : "" )}>
            <td>{taille.libelle}</td>
            <td>{couleur.libelle}</td>
            <td>
                { editStocks ?
                    <EditStockInput 
                        stockIni={couleur.stock} 
                        updateStock={updateStock} 
                        ean13={couleur.ean13}/> :
                    couleur.stock
                }
            </td>
            <td>{couleur.stockMini}</td>
            <td></td>
        </tr>
    )
}


function ExportData ({produits, stock, alerte}){
    let content = listProduitsToExcel(produits, stock, alerte);

    const generateExcel = ()=>{
    const worksheet = XLSX.utils.json_to_sheet(content);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");
    XLSX.writeFile(workbook, "etat_stocks_"+dayjs().format('YYYYMMDD')+".xlsx");
    }


    return (
        <div className="export">
            <button onClick={generateExcel}><ExcelIcon/></button>
        </div>
    )

}


function EditStockInput ({ean13, stockIni, updateStock}) {

    const [newStock, setNewStock] = useState(stockIni);

    const onKeyUp = (e) => {
        if (e.keyCode === 13) {
            e.target.blur();
        }
    }

    const onConfirmStock = (e) => {
        let { value } = e.target;
        value = parseInt(value);
        if (isNaN(value) ||value <0) {
            setNewStock(stockIni);
            return;
        }
        updateStock(ean13, value)
    }

    
    return (
        <SimpleTextInput
            style={{width:50, margin:'auto'}}
            value={newStock}
            type='number'
            onChange={(e) => setNewStock(e.target.value)}
            onBlur={onConfirmStock}
            onKeyUp={onKeyUp}
            />
    )
}


function HistoriqueMouvements ({editStocks, setEditStocks, enregistreStock, setFile}){


    let dispatch = useDispatch();
    const [historiqueEntrees, setHistoriqueEntrees] = useState([]);
    const [historiqueCorrections, setHistoriqueCorrections] = useState([]);
    const [showWaningDialog, setShowWarningDialog] = useState(false);


    useEffect(() => {
        getHistoriqueMouvementsStock("entree")
        .then(res=> {
            if (res) {
                setHistoriqueEntrees(res);
            }
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error", message:"Impossible de charger l'historique des mouvements de stock"}));
        });

    }, []);

    useEffect(() => {
        if (!editStocks){
            getHistoriqueMouvementsStock("correction")
            .then(res=> {
                if (res) {
                    setHistoriqueCorrections(res);
                }
            })
            .catch(error =>{
                console.log(error);
                dispatch(addErrorMessage({type:"error", message:"Impossible de charger l'historique des mouvements de stock"}));
            });
        }

    }, [editStocks]);

    const onConfirmEditStock = () => {
        setEditStocks (true);
        setShowWarningDialog (false);
    }

    return (
        <div>
            <h3>Editer :</h3>
            <div className="editStock">
                {editStocks ? 
                    [
                        <StandardButton key="save" label={'Enregistrer'} className='couleur' onClick={enregistreStock}/>,
                        <StandardButton key="cancel" label={'Abandon'} className='couleur' onClick={() => setEditStocks(false)}/> 
                    ] :  
                    <StandardButton label={'Corriger les stocks'} onClick={() => setShowWarningDialog(true)}/>
                }
            </div> 
            
            {editStocks ? null :

                <div>
                    <h3>Historique des entrées en stock :</h3>
                    <table className="table historique">
                        <thead>
                            <tr>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                historiqueEntrees.map(m => {
                                    return <LigneMouvement key={m.CodeTransaction} mouvement={m} setFile={setFile}/>
                                })
                            }
                        </tbody>
                    </table>
                    <h3>Historique des corrections de stock :</h3>
                    <table className="table historique">
                        <thead>
                            <tr>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                historiqueCorrections.map(m => {
                                    return <LigneMouvement key={m.CodeTransaction} mouvement={m} setFile={setFile}/>
                                })
                            }
                        </tbody>
                    </table>

                </div>
            }
            
            {showWaningDialog ?
                <Dialog 
                    confirmText="oui"
                    confirmCallback={onConfirmEditStock}
                    cancelText="non"
                    cancelCallback={()=>setShowWarningDialog(false)}>
                    <span>Attention, afin d'éviter les erreurs, il est recommendé d'effectuer les corrections de stock lorsque le magasin est fermé.<br></br> Continuer ?</span>
                </Dialog>
                :null
            }
            
        </div>
    )
}

function LigneMouvement ({mouvement, setFile}){

    const dispatch = useDispatch ();


    const exportMouvement = () => {
        setFile('loading');
        getDetailMouvementStock(mouvement.CodeTransaction)
        .then((res) =>{
            if (res) {
                if (res.type.includes('application/json')) throw new Error (res);
                setFile(window.URL.createObjectURL(res));
            }
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
            setFile(null);
        });

    }


    return (
        <tr onClick={exportMouvement}>
            <td>{mouvement.Date}</td>
        </tr>
        
    )
}


const listProduitsToExcel = (produits, stock, alerte) => {
    const result= [];
    produits.forEach(famille => {
        famille.produits.forEach(produit=>{
            produit.tailles.forEach(taille=>{
                taille.couleurs.forEach(couleur=>{
                    if (stock && couleur.stock <= couleur.stockMini) return;
                    if (alerte && couleur.stock > couleur.stockMini) return;
                    result.push({
                        codeFamille:famille.code,
                        famille: famille.libelle,
                        codeProduit: produit.code,
                        produit : produit.libelle,
                        codeTaille : taille.code,
                        taille : taille.libelle,
                        codeCouleur : couleur.code,
                        couleur : couleur.libelle,
                        stock:couleur.stock, 
                        stockMini:couleur.stockMini
                    });
                })
            })
        });
    });

    return result;
}