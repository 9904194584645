import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/Loader.js';
import { StandardButton } from '../../components/Buttons.js';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addErrorMessage } from '../../features/errorSlice.js';
import { LockedInput, TextInput } from '../../components/Inputs.js';
import { createLot, getLotById, updateLot } from '../../api/lotsApi.js';
import { BlockTitle, Title } from '../../components/Title.js';
import { GroupIcon } from '../../components/Icons.js';

import '../../styles/lot.css';


export default function Lot () {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [lot, setLot] = useState({
        id: null,
        name: "",
        shops: []
    });



    useEffect(()=>{
        if (id !== "new") {
            getLotById(id)
            .then(res=>{
                setLot(res);
            })
            .catch(error =>{
                console.log(error);
                dispatch(addErrorMessage({type:"error"}));
            });        
        }
        else setLot({...lot, id:'new'});
    },[]);


    const handleChange = (e) => {
        let {name, value} = e.target;
        setLot({...lot, [name]:value})

    }

    const saveChanges = () => {
        if (lot.name === ""){
            return;
        }

        setIsLoading(true);
        if (lot.id === 'new') {
            createLot({
                name: lot.name,
            })
            .then(res=>{
                if (res) {
                    dispatch(addErrorMessage({type:"success", message:"Le lot a été crée avec succès"}));
                    navigate('/admin/lots');
                }
                
            })
            .catch(error =>{
                console.log(error);
                dispatch(addErrorMessage({type:"error"}));
                setIsLoading(false);
            });  
        }
        else {
            updateLot({
                id: lot.id,
                name: lot.name,
            })
            .then(res=>{
                if (res) {
                    dispatch(addErrorMessage({type:"success", message:"Les données du lot ont été mises à jour avec succès"}));
                    navigate('/admin/lots');
                }
                
            }).catch(error =>{
                console.log(error);
                dispatch(addErrorMessage({type:"error"}));
                setIsLoading(false);
            });  
        }
        
    }
   

    return ( 
        lot.id == null ? 
        <Loader></Loader> : 
        <div className='Lot'>
            <Title title="Lot" backTo='/admin/lots'><GroupIcon/></Title>
            <div className='Block'>
                <div className='form'>
                    {lot.id !== 'new' ? <LockedInput label="Id" className="id" value={lot.id}/> : null}
                    <TextInput label='Nom' name='name' className="name" value={lot.name} onChange={handleChange} required={true}/>
                </div>
                <div className='submitForm'>
                    {isLoading ? <Loader width={35} height={35}/> : <StandardButton label="Enregistrer" onClick={saveChanges}/>}
                </div>
            </div>
            <div className='Block ShopList'>
                <BlockTitle title="Magasins Rattachés"/>
                <div>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Nom</th>
                            </tr>
                        </thead>
                            <tbody>
                            {lot.shops.map((s)=>{
                                return <ListRow key={s.id} shop={s}/>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>     
        </div>
                    
    )
}

function ListRow ({shop}) {

    return (
       <tr className='ItemRow'>
            <td>{shop.id}</td>
            <td>
                <Link to={'/admin/shops/'+shop.id}>{shop.name}</Link>
            </td>
       </tr>
     )
 }
 
