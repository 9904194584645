import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getListeOpenedSockets } from "../../api/sessionsApi";
import { addErrorMessage } from "../../features/errorSlice";
import Loader from "../Loader";
import { ConnectionIcon } from "../Icons";

export default function OpenedSockets () {

    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    
    useEffect(() => {
        if (data == null) {
            getListeOpenedSockets()
            .then((res) => {
                console.log(res);
                setData(res);
            })
            .catch(error =>{
                setData([]);
                dispatch(addErrorMessage({type:"error"}));
            });
        }
    }, [data])

    return (
        <div className="Block ActiveSessions">
            <h3>
                <ConnectionIcon/>
                <span>Sockets ouvertes</span>
            </h3>
            <div>
                { data == null ? <Loader/> :
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Id</th>
                                {/* <th>Id session</th> */}
                                <th>Username</th>
                                <th>Magasin</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(s => {
                                return <SocketRow socket={s} setData={setData} key={s.id}/>
                            })}
                        </tbody>
                    </table> 
                }
            </div>
            
        </div>
    )

}


function SocketRow ({socket}) {
    return (
        <tr>
            <td>{socket.id}</td>
            {/* <td>{socket.idSession}</td> */}
            <td>{socket.username}</td>
            <td>{socket.shop}</td>
        </tr>
    )
}