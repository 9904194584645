import { closestCenter, DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, rectSortingStrategy, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import {forwardRef, useState} from 'react';
  
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
  

export default function SortableItemList({items, setItems, getElementFromItem}) {
    const [activeId, setActiveId] = useState(null);
    
    // const sensors = useSensors(
    //   useSensor(PointerSensor),
    //   useSensor(KeyboardSensor, {
    //     coordinateGetter: sortableKeyboardCoordinates,
    //   })
    // );

    const sensors = useSensors(
        useSensor(PointerSensor, {
        activationConstraint: {
          distance: 5
        }
    })
    );
  
    return (

        <DndContext 
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragCancel={handleDragCancel}
        >
        <SortableContext 
            items={items}
            strategy={rectSortingStrategy}
        >
            <div className='ButtonList'>
                {items.map((item) => (
                    <SortableItem key={item.id} item={item}>
                        {getElementFromItem(item)}
                    </SortableItem>
                ))}
            </div>
        </SortableContext>
        <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
            {activeId ? 
                <Item isDragging>
                    {getElementFromItem(items.find(el => el.id === activeId))}
                </Item> 
                : null
            }
        </DragOverlay>
        </DndContext>

    );
    
    function handleDragStart(event) {
        setActiveId(event.active.id);
    }

    function handleDragOver(event) {
        const {active, over} = event;
    
        if (active.id !== over.id) {
          setItems((items) => {
            const oldIndex = items.map(function(e) { return e.id; }).indexOf(active.id);
            const newIndex = items.map(function(e) { return e.id; }).indexOf(over.id);
    
            return arrayMove(items, oldIndex, newIndex);
          });
        }
      }
  
    function handleDragCancel() {
        setActiveId(null);
    }
}

function SortableItem(props) {
    const {
        isDragging,
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: props.item.id });
  
    const style = {
        // transform: CSS.Translate.toString(),

        transform: CSS.Translate.toString(transform),
        // transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
    };

    return (
        <Item
            ref={setNodeRef}
            style={style}
            withOpacity={isDragging}
            {...props}
            {...attributes}
            {...listeners}
        />
    );
}

const Item = forwardRef(({ item, withOpacity, isDragging, style, ...props }, ref) => {
    const inlineStyles = {
        opacity: withOpacity ? '0.5' : '1',
        transformOrigin: '50% 50%',
        cursor: isDragging ? 'grabbing' : 'grab',
        //transform: isDragging ? 'scale(1.05)' : 'scale(1)',
        ...style,
    };
    return (
        <div style={{... inlineStyles, display:"inline-block"}} ref={ref} {...props}>
            {props.children}
        </div>
    )
});