import { useEffect, useState } from "react";
import { getCaisse } from "../../api/statistiquesApi";
import { useDispatch } from "react-redux";
import { addErrorMessage } from "../../features/errorSlice";
import Loader from "../Loader";
import { SmallBlock, StatistiquesTitle } from "./DataBlocks";
import dayjs from "dayjs";

export default function CaisseVente ({date, id}) {

    let dispatch = useDispatch();
    let [data, setData] = useState({});

    useEffect(() => {
        getCaisse (id, 'vente', '@EXERCICEDEBUT@', date)
        .then(res=>{
            if (res) {
                setData(res);
            }
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
        });
    }, []);



    return (
        <div className="Block">
             <StatistiquesTitle title="Caisse de vente" shopsKO={data.shopsKO}/>
            {
                data.shopsKO == null ?
                <Loader/>
                : 
                data.dateDebut == null ? 
                    null :
                    <div>
                        <span className="date">{'du ' + dayjs(data.dateDebut).format('dddd D MMMM YYYY') + ' au ' + dayjs(data.dateFin).format('dddd D MMMM YYYY')}</span>
                        <div className="flex">
                            <SmallBlock title="Nombre de ventes" valueN={data['nbVenteN']} valueN_1={data['nbVenteN-1']}/>
                            <SmallBlock title="Montant du panier moyen" valueN={data['montantPanierMoyN']} valueN_1={data['montantPanierMoyN-1']} unit={String.fromCodePoint(8364)}/>
                        </div>
                        <div className="flex">
                            <SmallBlock title="Nombre de pièces" valueN={data['nbPieceN']} valueN_1={data['nbPieceN-1']}/>
                            <SmallBlock title="Pourcentage de remise moyen" valueN={data['pourcRemiseMoyN']} valueN_1={data['pourcRemiseMoyN-1']} unit={'%'}/>
                        </div>
                        <div className="flex">
                            <SmallBlock title="Montant total" valueN={data['montantTotN']} valueN_1={data['montantTotN-1']} unit={String.fromCodePoint(8364)}/>
                            <SmallBlock title="Marge totale" valueN={data['margeN']} valueN_1={data['margeN-1']} unit={String.fromCodePoint(8364)}/>
                        </div>
                    </div>  
            }
        </div>
    )
}