import errorHandler from "../utils/ErrorHandler";

export async function getShops() {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/shops', {
        method: 'GET',
        credentials: 'include',
    });

    if (response.status>=500) throw new Error("getShops api error");
    let result = await response.json();
    result = errorHandler(result);
    if (!result) return [];
    return result.shops;

}


export async function getShopsStatus(keepAlive) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/shops/status/'+keepAlive, {
        method: 'GET',
        credentials: 'include',
    })

    if (response.status>=500) throw new Error("getShopsStatus api error");
    let result = await response.json();
    result = errorHandler(result);
    if (!result) return [];
    return result.shops;

}

export async function getShopById(id) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/shops/'+id, {
        method: 'GET',
        credentials: 'include',
    });

    if (response.status>=500) throw new Error("getShopById api error");
    let result = await response.json();
    result = errorHandler(result);
    if (!result) return {};
    return result.shop;

}

export async function createShop(data) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/shops/new', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    });

    if (response.status>=500) throw new Error("createShop api error");
    let result = await response.json();
    return errorHandler(result);

}

export async function updateShop(data) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/shops/update', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    });

    if (response.status>=500) throw new Error("updateShop api error");
    let result = await response.json();
    return errorHandler(result);

}

export async function getSupervisorShopStats() {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/shops/supervisor', {
        method: 'GET',
        credentials: 'include',
    });

    if (response.status>=500) throw new Error("getSupervisorShopStats api error");
    let result = await response.json();
    result = errorHandler(result);
    if (!result) return {};
    return result.shops;

}