import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react';
import { checkAuth } from '../api/authApi.js';
import Loader from '../components/Loader.js';
import Admin from './admin/Admin.js';
import { loginSuccess, logoutSuccess } from '../features/authSlice.js';
import { updateStorageTheme } from '../utils/Theme.js';



export default function ProtectedRoute({allowedRoles}) {

    const logged = useSelector((state) => state.user.active);
    const role = useSelector((state) => state.user.role);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const checkLogin = async () => {
        let response = await checkAuth();

        if (response === null || response.status !== 200) {
            console.log("not logged");
            dispatch(logoutSuccess());
            navigate('/login');
        }
        else {
            dispatch(loginSuccess(response));
            updateStorageTheme(response.settings.theme);
        }
    }

    useEffect(() => { 
        if(!logged) {
            console.log('checking auth...');

           checkLogin();
        }

    }, [location.pathname, logged]);

    


    return !logged ? <Loader></Loader> 
        : allowedRoles.indexOf(role) > -1 ?
            (role === 'admin' ? 
                <Admin></Admin>
                : <Outlet/>
            ) 
            : <Navigate to={'/login'}></Navigate>;

}