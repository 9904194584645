import { useEffect, useState } from 'react';
import { BarChartIcon, ClientIcon, HelpIcon, LineChartIcon, VideoIcon } from '../components/Icons.js';
import { SelectInput } from '../components/Inputs.js';
import { BlockTitle, Title } from '../components/Title.js';


import { getUsers } from '../api/usersApi.js';
import { useDispatch } from 'react-redux';
import { addErrorMessage } from '../features/errorSlice.js';
import { getListeAide, getLogsConsultationLibelle, getLogsConsultationUser } from '../api/aideApi.js';
import Loader from '../components/Loader.js';
import { BarChart } from '../components/charts/BarChart.js';
import { LineChart } from '../components/charts/LineChart.js';
import { getChartColorTheme } from '../utils/Charts.js';

import '../styles/aideConsultationDetail.css';


export default function AideConsultationDetail () {

    const [users, setUsers] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userChartType, setUserChartType] = useState('bar');
    const [userData, setUserData] = useState(null);

    const [listeAide, setListeAide] = useState(null);
    const [selectedAide, setSelectedAide] = useState(null);
    const [aideChartType, setAideChartType] = useState('bar');
    const [aideData, setAideData] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        getUsers().then(res=>{
            if (res) setUsers(res);
            setSelectedUser(0);
        }).catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error", message: "Impossible de charger la liste des utilisateurs"}));
        });

        getListeAide().then(res=>{
            if (res) setListeAide(res);
            setSelectedAide("all all");
        }).catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error", message: "Impossible de charger la liste des tutos"}));
        });

    }, [])

    useEffect(() => {
        if (selectedUser != null) {
            setUserData(null);
            getLogsConsultationUser(selectedUser, (userChartType ==='line' ? 1 : 0))
            .then(res=>{
                setUserData(res);
            })
            .catch(error =>{
                console.log(error);
                dispatch(addErrorMessage({type:"error"}));
            });
        }

    }, [selectedUser, userChartType])

    useEffect(() => {
        if (selectedAide != null) {
            let split = selectedAide.split(" ");
            setAideData(null);
            getLogsConsultationLibelle(split[0], split[1] || '', (aideChartType ==='line' ? 1 : 0))
            .then(res=>{
                setAideData(res);
            })
            .catch(error =>{
                console.log(error);
                dispatch(addErrorMessage({type:"error"}));
            });
        }

    }, [selectedAide, aideChartType])

    const onChangeUser = (e) => {
        setSelectedUser(e.target.value);
    }

    const onChangeAide = (e) => {
        setSelectedAide(e.target.value);
    }
      

    return (
        <div className='AideConsultationDetail'>
            <Title title="Consultation de l'aide en ligne"><HelpIcon/></Title>   
            <div className='Block'>
                <BlockTitle title="Détail par utilisateur" icon={<ClientIcon/>}/>
                {users ?
                    <div>
                        <SelectInput 
                            withEmptyOption={false}
                            value={selectedUser}
                            onChange={onChangeUser}
                            options={[{name:"tous", value:0}, ...users.map(el => {return {name: el.username, value: el.id}})]}/>
                        
                       
                        <AideConsultationChartBlock data={userData} chartType={userChartType}  changeChartType={setUserChartType}/>
                       
                    </div>
                    : null
                }
            </div>
            <div className='Block'>
                <BlockTitle title="Détail par vidéo" icon={<VideoIcon/>}/>
                {listeAide ?
                    <div>
                        <SelectInput 
                            withEmptyOption={false}
                            value={selectedAide}
                            onChange={onChangeAide}
                            options={[{name:"tous", value:"all all"}, ...listeAide.map(el => {return {name: el, value: el}})]}/>

                        <AideConsultationChartBlock data={aideData} chartType={aideChartType} changeChartType={setAideChartType}/>
                    </div>
                    : null
                }
            </div>
                    
        </div>
    )
}


function AideConsultationChartBlock ({chartType, changeChartType, data}) {

    let colorTheme = getChartColorTheme('purple');


    return (
        <div className="AideConsultationChartBlock">
            <div>
                <button onClick={() => changeChartType('bar')} className={chartType === 'bar' ? 'selected' : ''}><BarChartIcon/></button>
                <button onClick={() => changeChartType('line')} className={chartType === 'line' ? 'selected' : ''}><LineChartIcon/></button>
            </div>
        { data == null ? 
            <Loader/> : 
            (chartType === 'bar' ? 
                <BarChart 
                    data={data.sort((a,b) => a.valueN > b.valueN_1 ? 0 : 1)} 
                    mainColor={'purple'} 
                    style={{height : 50*data.length + 'px'}}/>
                :
                <div className="BarChart">
                    <LineChart
                        xAxisValues={data.map(el => el.name)}
                        series={
                            [
                                {
                                    name:'N',
                                    color:colorTheme.colorN,
                                    data: data.map(el => el.valueN)
                                },
                                {
                                    name:'N-1',
                                    color:colorTheme.colorN_1,
                                    data: data.map(el => el.valueN_1)
                                }
                            ]
                        }
                        style={{height : '500px'}}/>
                </div>
            )
                
        }
    </div>
    )
    
}