import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid';

export const errorSlice = createSlice({
  name: 'errors',
  initialState: [],
  reducers: {
    addErrorMessage: (state, action) => {
        let newError = {
            id: uuidv4(),
            timestamp : new Date().toISOString(),
            type: action.payload.type,
            message: action.payload.message,
            timeout : action.payload.noTimeout == null,
            active: true
        } 
        return [newError, ...state];
       
    },
    removeErrorMessage: (state, action) => {
        let index = state.map(function(e) { return e.id; }).indexOf(action.payload.id);
        state[index].active = false;
    },
  }
})

export const { addErrorMessage, removeErrorMessage } = errorSlice.actions;
export default errorSlice.reducer;
