import errorHandler from "../utils/ErrorHandler";

export async function previsionsRetour(params) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/retours/previsions', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
    });

    if (response.status>=400) throw new Error("previsionsRetour api error");
    let result = await response.blob();
    return result;

}


export async function getListeFichesEnCours(params) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/retours/fichesOuvertes', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
    });

    if (response.status>=500) throw new Error("getListeFichesEnCours api error");
    let result = await response.json();
    result = errorHandler(result);
    if (!result) return [];
    return result.fiches;

}

export async function retourArticle(params) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/retours/article', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
    });

    if (response.status>=500) throw new Error("retourArticle api error");
    let result = await response.json();
    return errorHandler(result);

}