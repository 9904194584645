export function updateStorageTheme (newTheme) {
    localStorage.setItem('theme', newTheme);
    window.dispatchEvent(new Event("storage"));    
}

export function getColors (theme) {
    switch(theme){
        case "purple" :
            return {
                "--main-color":"#ad89e2",
                "--main-color-2":"#7e5cb9",
                "--main-color-3":"#ffaa85",
                "--main-color-4":"#ff8fab",
                "--main-color-5":"#84b9d1",
                "--background-color-2" : "#dbc9f582",
                "--background-color-3" : "#f2daf762"
            };
        case "green" :
            return {
                "--main-color":"#379e7b",
                "--main-color-2":"#027c7e",
                "--main-color-3":"#a5d380",
                "--main-color-4":"#f5bf6c",
                "--main-color-5":"#a2d0bc",
                "--background-color-2" : "#abccab82",
                "--background-color-3" : "#e6f5e682"
            }
        case "yellow" :
            return {
                "--main-color":"#f9ad52",
                // "--main-color-2":"#f4845f",
                "--main-color-2":"#fe6a65",
                "--main-color-3":"#fd9090",
                "--main-color-4":"#f7c55f",
                "--main-color-5":"#ebcf75",
                "--background-color-2" : "#f5cc7662",
                "--background-color-3" : "#ffeccc62"
            }
        default :
            return {
                "--main-color":"#71a0c2",
                "--main-color-2":"#4f6f91",
                "--main-color-3":"#4cb9d2",
                "--main-color-4":"#35d1cd",
                "--main-color-5":"#84b9d1",
                "--background-color-2" : "#b7d3f062",
                "--background-color-3" : "#deeaf862"
            };
        
    }
}

export function getUrlLogo (theme) {
    switch(theme){
        case "purple" :
            return "NEXTOU_logo_violet.png";
        case "green" :
            return "NEXTOU_logo_vert.png";
        case "yellow" :
            return "NEXTOU_logo_jaune.png";
        default :
            return "NEXTOU_logo_bleu.png";
        
    }
}