import { InfoIcon, FilledCheckIcon, WarningIcon, ErrorIcon } from "../components/Icons";
// import forge from 'node-forge';

export function checkEmail (email){
    console.log("checkEmail "+email);
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
} 

export function checkPasswordStrength (password){
    // min length 8 chars max 72
    return /^(?:(?=.*\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[^A-Za-z0-9])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9]))(?!.*(.)\1{2,})[A-Za-z0-9!~<>,;:_=?*+#."&�%�()\|\[\]\-\$\^\@\/]{8,72}$/.test(password);
}


export function generatePassword (length=8) {
    var string = "abcdefghijklmnopqrstuvwxyz";
    var numeric = '0123456789';
    var punctuation = '!@#$&*()_+:;?></-=';
    var password = "";
    var character = "";
    let entity1, entity2, entity3, hold;
    while( password.length<length ) {
        entity1 = Math.ceil(string.length * Math.random()*Math.random());
        entity2 = Math.ceil(numeric.length * Math.random()*Math.random());
        entity3 = Math.ceil(punctuation.length * Math.random()*Math.random());
        hold = string.charAt( entity1 );
        hold = (password.length%2==0)?(hold.toUpperCase()):(hold);
        character += hold;
        character += numeric.charAt( entity2 );
        character += punctuation.charAt( entity3 );
        password = character;
    }
    password=password.split('').sort(function(){return 0.5-Math.random()}).join('');
    return password.substr(0,length);
}

export function getMessageIcon (type) {
    switch(type){
        case "error":
            return <ErrorIcon></ErrorIcon>;
        case "warning":
            return <WarningIcon></WarningIcon>;
        case "info":
            return <InfoIcon></InfoIcon>;
        case "success":
            return <FilledCheckIcon></FilledCheckIcon>;
        default:
            return null;
    }
}

export async function rsaEncrypt (pemKey, message) {
    let key = await importPublicKey(pemKey);
    let encrypted = await window.crypto.subtle.encrypt(
        {
            name: "RSA-OAEP"
        },
        key,
        new TextEncoder().encode(message)
    );
    return  window.btoa(ab2str(encrypted));
}


async function importPublicKey(spkiPem) {       
    return await window.crypto.subtle.importKey(
        "spki",
        getSpkiDer(spkiPem),
        {
            name: "RSA-OAEP",
            hash: "SHA-256",
        },
        false,
        ["encrypt"]
    );
}

function getSpkiDer(spkiPem){
    const pemHeader = "-----BEGIN PUBLIC KEY-----\n";
    const pemFooter = "-----END PUBLIC KEY-----\n";
    var pemContents = spkiPem.substring(pemHeader.length, spkiPem.length - pemFooter.length);
    var binaryDerString = window.atob(pemContents);
    return str2ab(binaryDerString); 
}

function str2ab(str) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
}
    
function ab2str(buf) {
    return String.fromCharCode.apply(null, new Uint8Array(buf));
}
  

export function sortItemsByIndice(a, b) {

    // equal items sort equally
    if (a.indice === b.indice) {
        return 0;
    }
    // nulls sort after anything else
    if (a.indice === null) {
        return 1;
    }
    if (b.indice === null) {
        return -1;
    }

    return a.indice < b.indice ? -1 : 1;

}