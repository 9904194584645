
import { Link, useNavigate } from 'react-router-dom';
import '../styles/homeHeader.css'
import { getUrlLogo } from '../utils/Theme'
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../api/authApi';
import { logoutSuccess } from '../features/authSlice';
import { ClientIcon, HelpIcon, LogoutIcon, SettingsIcon } from './Icons';

import '../styles/homeHeader.css';
import { consultationAide } from '../api/aideApi';

export function HomeHeader ({style, homeRoute}) {

    const navigate = useNavigate();
    const user= useSelector((state) => state.user);
    const dispatch = useDispatch();

    const handleLogout = async e =>{ //TODO : endpoint commun
        e.preventDefault();
        logout().then(() => {
            dispatch(logoutSuccess());
        }).then(() =>{
            navigate('/login');
        })
        .catch(err=>{
            console.log(err); //TODO
        });
    }

    const settingsRoute = user.role === "admin" ? "/admin/settings" : (user.role === "supervisor" ? "/supervisor/settings" :'/settings')

    return (
        <div className="homeHeader" style={style}>
            <img src={getUrlLogo(user.parametres.theme)} onClick={() => navigate(homeRoute)}/>
            <Link to={'/aide'} className='help' onClick={() => consultationAide('general')}><HelpIcon/></Link>
            <div className='dropdown'>
                <input id="toggleDropdown" type="checkbox"/>
                <div className='profile'>
                    <ClientIcon/>
                    <div>
                        <span>{user.username}</span>
                        <span>{user.shopName}</span>
                    </div>
                </div>
                <div className="menu">
                    <div onClick={() => navigate(settingsRoute)}>
                        <SettingsIcon/>
                        <span>Paramètres</span>
                    </div>
                    <div onClick={handleLogout}>
                        <LogoutIcon/>
                        <span>Déconnexion</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function HomeFooter ({}) {

    let nextouVersion = process.env.REACT_APP_NEXTOU_VERSION;
    let skilouVersion = process.env.REACT_APP_SKILOU_VERSION;

    return (
        <div className="homeFooter">
            <span>
                Nextou (v.{nextouVersion}) est une API de Skilou {skilouVersion != null ? "(v."+skilouVersion+")" : ""} - Hébergé et développé en France. Copyright © 2024 Skilou x Synergie73 - Tous droits réservés
            </span>
        </div>
    )
}