import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/Loader.js';
import { StandardButton } from '../../components/Buttons.js';
import { useEffect, useState } from 'react';
import { createShop, getShopById, updateShop } from '../../api/shopsApi.js';
import { useDispatch } from 'react-redux';
import { addErrorMessage } from '../../features/errorSlice.js';
import { LockedInput, SelectInput, TextInput } from '../../components/Inputs.js';
import { getLots } from '../../api/lotsApi.js';
import { ShopIcon } from '../../components/Icons.js';
import { Title } from '../../components/Title.js';


import '../../styles/shop.css';


export default function Shop () {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [shop, setShop] = useState({
        id: null,
        name: null,
        idBase: null,
        numeroMagasin: null,
        codeCaisse : null,
        idLot: null,
        lotName:null,
    });

    const [listeLots, setListeLots] = useState([]);


    useEffect(()=>{
        if (id !== "new") {
            getShopById(id)
            .then(res=>{
                if (res) setShop(res);
            })
            .catch(error =>{
                console.log(error);
                dispatch(addErrorMessage({type:"error"}));
            });        
        }
        else setShop({...shop, id:'new'});
    },[]);


    useEffect(()=>{
        getLots()
        .then(res=>{
            if (res) setListeLots(res);
        }).catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
        });   
    },[]);


    const handleChange = (e) => {
        let {name, value} = e.target;
        setShop({...shop, [name]:value})

    }

    const saveChanges = () => {
        if (shop.name === "" || shop.idBase === "" || shop.numeroMagasin === "" || shop.codeCaisse === "" || shop.idLot === ""){
            return;
        }

        setIsLoading(true);
        if (shop.id === 'new') {
            createShop({
                name: shop.name,
                idBase: shop.idBase,
                numeroMagasin: shop.numeroMagasin,
                codeCaisse : shop.codeCaisse,
                idLot: parseInt(shop.idLot)
            })
            .then(res=>{
                if (res) {
                    dispatch(addErrorMessage({type:"success", message:"Le magasin a été crée avec succès"}));
                    navigate('/admin/shops');
                }
                
            }).catch(error =>{
                console.log(error);
                dispatch(addErrorMessage({type:"error"}));
                setIsLoading(false);
            });  
        }
        else {
            updateShop({
                id: shop.id,
                name: shop.name,
                idBase: shop.idBase,
                numeroMagasin: shop.numeroMagasin,
                codeCaisse : shop.codeCaisse,
                idLot: parseInt(shop.idLot)
            })
            .then(res=>{
                if (res) {
                    dispatch(addErrorMessage({type:"success", message:"Les données du magasin ont été mises à jour avec succès"}));
                    navigate('/admin/shops');
                }
                
            }).catch(error =>{
                console.log(error);
                dispatch(addErrorMessage({type:"error"}));
                setIsLoading(false);
            });  
        }
        
    }
   

    return ( shop.id == null ?
        <Loader></Loader>
        : 
        <div className='Shop'>
            <Title title="Magasin" backTo='/admin/shops'><ShopIcon/></Title>
            <div className='Block'>
                <div className='form'>
                    {shop.id !== 'new' ? <LockedInput label="Id" className="id" value={shop.id}/> : null}
                    <TextInput label='Nom' name='name' className="name" value={shop.name} onChange={handleChange} required={true}/>
                    {shop.id !== 'new' ?
                        <LockedInput label='Id base' className="idBase" value={shop.idBase}/>
                        : <TextInput label='Id base' name='idBase' className="idBase" value={shop.idBase} onChange={handleChange} required={true}/>
                    
                    }
                    {shop.id !== 'new' ?
                        <LockedInput label='Numéro de magasin' className="numeroMagasin" value={shop.numeroMagasin}/>
                        : <TextInput label='Numéro de magasin' name='numeroMagasin' className="numeroMagasin" value={shop.numeroMagasin} onChange={handleChange} required={true}/>
                    }
                    {shop.id !== 'new' ? 
                    
                        <LockedInput label='Code caisse'className="codeCaisse" value={shop.codeCaisse}/>
                        : <TextInput label='Code caisse' name='codeCaisse' className="codeCaisse" value={shop.codeCaisse} onChange={handleChange} required={true}/>
                        
                    }
                    <SelectInput label='Lot' name='idLot' className="idLot" options={listeLots.map(el=> {return{value:el.id, name:el.name}})} value={shop.idLot} onChange={handleChange} required={true}/>
                </div>
                <div className='submitForm'>
                    {isLoading ? <Loader width={35} height={35}/> : <StandardButton label="Enregistrer" onClick={saveChanges}/>}
                </div>
            </div>
            
        </div>
    )
}
