import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addErrorMessage } from "../../features/errorSlice";
import { setSupervisorShops } from "../../features/shopSlice";
import ListShopsStatistiques from "../../components/ListShopsStatistiques";
import { getShops } from "../../api/shopsApi";


export default function ShopsStatistiques () {


    let dispatch = useDispatch();
    const shops = useSelector((state) => state.shops);

    useEffect(() => {
        if (shops.length === 0) {
            getShops()
            .then((res)=>{
                if (res) {
                    dispatch(setSupervisorShops({shops:res.sort((a,b) =>(a.name > b.name) ? 1 : -1) }));
                }
            })
            .catch(error =>{
                console.log(error);
                dispatch(addErrorMessage({type:"error"}));
            });
        }
    }, []);


    return <ListShopsStatistiques shops={shops}/>

}