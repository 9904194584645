import errorHandler from "../utils/ErrorHandler";

export async function mouvementStock(type, produits) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/stocks/'+type, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({produits : produits}),
    });

    if (response.status>=500) throw new Error("mouvementStock api error");
    let result = null;
    if (response.headers.get('content-type').includes('json')) {
        result = await response.json();
        result = errorHandler(result);
    }
    else result = await response.blob();

    return result;

}

export async function getHistoriqueMouvementsStock(type) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/stocks/transactions/'+type, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
    })

    if (response.status>=500) throw new Error("getHistoriqueMouvementsStock api error");
    let result = await response.json();
    result = errorHandler(result);
    if (!result) return [];
    return result.transactions;

}

export async function getDetailMouvementStock(code) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/stocks/transactions/detail/'+code, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
    });
    
    if (response.status>=500) throw new Error("getDetailMouvementStock api error");
    let result  = await response.blob();
    return result;

}