import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../features/authSlice.js';
import errorReducer from '../features/errorSlice.js';
import ficheReducer from '../features/ficheSlice.js';
import shopReducer from '../features/shopSlice.js';

export default configureStore({
  reducer: {
    user: userReducer,
    errors: errorReducer,
    fiche : ficheReducer,
    shops : shopReducer
  },
})