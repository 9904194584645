import errorHandler from "../utils/ErrorHandler";

export async function getConnectionsInfo() {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/connections', {
        method: 'GET',
        credentials: 'include',
    });

    if (response.status>=500) throw new Error("getConnectionsInfo api error");
    let result = await response.json();
    return errorHandler(result);

}


export async function getTokenPlanning(shopId = null) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/connections/planning'+(shopId == null ? '' : '/'+shopId), {
        method: 'GET',
        credentials: 'include',
    });

    if (response.status>=500) throw new Error("getTokenPlanning api error");
    let result = await response.json();
    return errorHandler(result);
}
