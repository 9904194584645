import React from 'react';
import ReactDOM from 'react-dom/client';
import store from './app/store'
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/fr';
import App from './App.js'
import dayjs from 'dayjs';

import './index.css';

//desactive les logs en production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

dayjs.locale('fr');

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
          <App/>
        </LocalizationProvider>
      </Provider>
  // </React.StrictMode>
);