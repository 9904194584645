import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { BackButton } from "./fiche/FicheButtons";
import { getProduits, sortFamilles, sortProduits } from "../api/produitsApi";
import { addErrorMessage } from "../features/errorSlice";
import Loader from "./Loader";
import SortableItemList from "./SortableItemList";
import { CheckIcon, EditIcon, InfoIcon, XIcon } from "./Icons";
import { InfoTooltip } from "./Tooltips";
import { sortItemsByIndice } from "../utils/Commons";

export default function ListProduitButtons ({onSelectCouleur, details=false, alerteStock0 = false, resetAffichageOnSelect = true}) {

    const dispatch = useDispatch();
    const [produits, setProduits] = useState([]);
    const [affichage, setAffichage] = useState({
        codeFamille: null,
        codeProduit: null,
        codeTaille: null,
    });
    const [sort, setSort] = useState(false);

    const handleClickButtonCouleur = (couleur, produit=null, taille=null) => {
        if (details) {
            if (produit == null) produit = getListeProduits(affichage.codeFamille).find((p) => p.code === affichage.codeProduit);
            if (taille == null) taille = produit.tailles.find((t) => t.code === affichage.codeTaille);
            console.log(produit);
            couleur.codeProduit = produit.code;
            couleur.nomProduit = produit.libelle;
            couleur.nomTaille = taille.libelle;
        }
        if (resetAffichageOnSelect) setAffichage({codeFamille: null, codeProduit: null, codeTaille: null});
        onSelectCouleur(couleur);
    }

    const getListeProduits = (codeFamille) => {
        let famille = produits.find((fam) => fam.code === codeFamille);
        if (famille === null) return [];

        console.log(famille.produits);
        return famille.produits;
    }

    const getListeTaille = (codeProduit) => {
        let prod = getListeProduits(affichage.codeFamille).find((prod) => prod.code === codeProduit);
        if (prod == null) return []; 

        return prod.tailles;
    }

    const getListeCouleur = (codeTaille) => {
        let taille = getListeTaille(affichage.codeProduit).find((taille) => taille.code === codeTaille);
        if (taille == null) return []; 

        return taille.couleurs;
    }

    const selectFamille = (famille) => {
        setAffichage({
            ...affichage,
            codeFamille:(famille == null ? null : famille.code)
        });
    }

    const selectProduit = (produit) => {
        if (produit != null && produit.tailles?.length == 1 && produit.tailles[0].couleurs?.length == 1){
            handleClickButtonCouleur(produit.tailles[0].couleurs[0], produit, produit.tailles[0]);
        }
        else {
            setAffichage({
                ...affichage,
                codeProduit:(produit == null ? null : produit.code)
            });
        }
    }

    const selectTaille = (taille) => {
        if (taille != null && taille.couleurs?.length == 1) {
            handleClickButtonCouleur(taille.couleurs[0], getListeProduits(affichage.codeFamille).find((p) => p.code === affichage.codeProduit), taille);
        }
        else {
            setAffichage({
                ...affichage,
                codeTaille:(taille == null ? null : taille.code)
            });
        }
    }

    
    useEffect(() => {
        if (!sort) {
            setProduits([]);
            getProduits(0, 0)
            .then(res=> {
                setProduits(res);
            })
            .catch(error =>{
                console.log(error);
                dispatch(addErrorMessage({type:"error", message:"Impossible de charger la liste des produits"})); 
            });
        }

    }, [sort]);

    return ( produits.length == 0 ? <Loader width={50} height={50} containerStyle={{color:'var(--main-color)'}}/> : 
        <div>
            {affichage.codeFamille === null ?
                (sort ?
                    <TriButtons setSort={setSort} listeProduits={produits.sort(sortItemsByIndice)} type="famille"/>
                    :
                    <div className='ButtonList'>
                        {produits
                            .sort(sortItemsByIndice)
                            .map(famille=>{
                                return <ProduitButton key={famille.code} libelle={famille.libelle} element={famille} stock={famille.stock} className="famille" onClick={selectFamille} stock0={alerteStock0} />
                            }
                        )}
                    </div>
                )
                : null
            }

            {affichage.codeFamille !== null && affichage.codeProduit === null ?
                (sort ? 
                    <TriButtons setSort={setSort} listeProduits={getListeProduits(affichage.codeFamille).sort(sortItemsByIndice)} type="produit"/>
                    : 
                    <div className='ButtonList'>
                        {getListeProduits(affichage.codeFamille)
                            .sort(sortItemsByIndice)
                            .map(prod=>{
                                let alerteStock = prod.tailles.filter((t) => t.couleurs.filter((c) => c.stock <= c.stockMini).length > 0).length > 0
                                return <ProduitButton key={prod.code} libelle={prod.libelle} element={prod} stock={prod.stock} alerteStock={alerteStock} className="produit" onClick={selectProduit} stock0={alerteStock0} />
                        })}
                        <BackButton className="produit" onClick={selectFamille}/>
                    </div>
                )
                : null
            }

            {affichage.codeProduit !== null && affichage.codeTaille === null ?
                <div className='ButtonList'>
                    {getListeTaille(affichage.codeProduit).map(taille=>{
                        let alerteStock = taille.couleurs.filter((c) => c.stock <= c.stockMini).length > 0
                        return <ProduitButton key={taille.code} libelle={taille.libelle} element={taille} stock={taille.stock} alerteStock={alerteStock} className="taille" onClick={selectTaille} stock0={alerteStock0} />
                    })}
                    <BackButton className="taille" onClick={selectProduit}/>
                </div>
                : null
            }

            {affichage.codeTaille !== null ?
                <div className='ButtonList'>
                    {getListeCouleur(affichage.codeTaille).map(couleur=>{
                        let alerteStock = couleur.stock <= couleur.stockMini;
                        return <ProduitButton key={couleur.code} libelle={couleur.libelle} element={couleur} stock={couleur.stock} alerteStock={alerteStock} className="couleur" onClick={handleClickButtonCouleur} stock0={alerteStock0} />
                    })}
                    <BackButton className="couleur" onClick={selectTaille}/>
                </div>
                : null
            }
            {affichage.codeProduit == null && !sort ?
                <div className="sortButtons">
                    <button onClick={() => setSort(true)}><EditIcon width={20} height={20}/></button>
                </div> 
                : null
            }
        </div>
    )

}


function ProduitButton({libelle, element, stock, className, alerteStock=false, onClick=null, stock0=false, style={}, displayStock=true}) {

    const onClickButton = () => {
        if (onClick && typeof onClick === "function") {
            onClick(element);
        }
    }

    const buttonClassName = className + (stock0 && stock <= 0 ? " transparent" : "");

    return (
        <button className={"ficheButton " + buttonClassName} onClick={onClickButton} style={style}>
            <span>{libelle}</span>
            {displayStock ? 
                <div className={"bulle" +(alerteStock? " alerte" : "")}>{stock}</div>
                : null
            }
        </button>
    )
}


function TriButtons({listeProduits, setSort, type}) {

    const [produits, setProduits] = useState(listeProduits.map(p => {return {libelle:p.libelle, code:p.code, id:p.code}}));
    const dispatch = useDispatch();

    const getProduitButton = (item) => {
        return (
            <ProduitButton libelle={item.libelle} className={type} style={{margin: '1em'}} displayStock={false}/>
        )
    }

    const onClickSaveButton = () => {
        let sortedList = produits.map((el, indice) => {
            return {
                code:el.code,
                indice:indice
            }
        })

        if (type == "famille") {
            sortFamilles({familles:sortedList})
            .then(res=> {
                setSort(false);
            })
            .catch(error =>{
                console.log(error);
                dispatch(addErrorMessage({type:"error"})); 
            });
        }

        else {
            sortProduits({produits:sortedList})
            .then(res=> {
                setSort(false);
            })
            .catch(error =>{
                console.log(error);
                dispatch(addErrorMessage({type:"error"})); 
            });
        }

    }

    return (
        <div>
            <SortableItemList items={produits} setItems={setProduits} getElementFromItem={getProduitButton}/>

            <div className="sortButtons">
                <InfoTooltip placement="left" title="Glissez/déposez les boutons dans l'ordre de votre choix.">
                    <InfoIcon width={15} height={15} style={{marginRight: '1em'}}/>
                </InfoTooltip>
                <button onClick={onClickSaveButton}><CheckIcon width={15} height={15}/></button>
                <button onClick={() => setSort(false)}><XIcon width={15} height={15}/></button>
            </div>   
        </div>
    );
}


