import { useEffect, useState } from "react"
import { getLogsConsultationLibelle, getLogsConsultationUser } from "../../api/aideApi"
import { useDispatch } from "react-redux";
import { addErrorMessage } from "../../features/errorSlice";
import Loader from "../Loader";
import { ClientIcon, ExpandIcon, HelpIcon, VideoIcon } from "../Icons";
import { LargeBlock } from "../statistiques/DataBlocks";
import { BarChart } from "../charts/BarChart";

import '../../styles/admin/aideConsultation.css';
import { useNavigate } from "react-router-dom";

export default function AideConsultationWidget (){

    const [by, setBy] = useState('user');
    const [data, setData] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (by === "user") {
            getLogsConsultationUser(0, 0)
            .then(res=>{
                console.log(res);
                setData(res);
            })
            .catch(error =>{
                console.log(error);
                dispatch(addErrorMessage({type:"error"}));
            });
        }
        else {
            getLogsConsultationLibelle('all', '', 0)
            .then(res=>{
                console.log(res);
                setData(res);
            })
            .catch(error =>{
                console.log(error);
                dispatch(addErrorMessage({type:"error"}));
            });
        }

    }, [by])

    const onChangeType = (newType) => {
        if (newType != by) {
            setData(null);
            setBy(newType);
        }
    }

    return (
        <div className="Block AideConsultation">
            <h3>
                <HelpIcon/>
                <span>Consultation de l'aide en ligne</span>
                <button onClick={() => navigate('aide')}><ExpandIcon/></button>
            </h3>
            <LargeBlock 
            valueN={data == null ?  0 : data.reduce((total, el) => (total = total + el.valueN),0)}
            valueN_1={data == null ?  0 : data.reduce((total, el) => (total = total + el.valueN_1),0)}
            title = "Total :"/>
            <div className="buttonsContainer">
                <button onClick={() => onChangeType("libelle")} className={by === "libelle" ? "selected" : ""}><ClientIcon/></button>
                <button onClick={() => onChangeType("user")} className={by === "user" ? "selected" : ""}><VideoIcon/></button>
            </div>
            <div className="chartContainer">
                { data == null ? 
                    <Loader/> : 
                    <BarChart 
                    title = {"Palmarès par " + (by === 'user' ? "type": "utilisateur")}
                    data={data.sort((a,b) => a.valueN > b.valueN_1 ? 0 : 1)} 
                    dataName={"nombre"} 
                    mainColor={'purple'} 
                    style={{height : 50*data.length + 'px'}}/>
                }
            </div>
        </div>
    )
}
