import { useDispatch } from "react-redux";
import { EuroIcon } from "../../components/Icons";
import { useEffect, useRef, useState } from "react";
import { BlockTitle, Title } from "../../components/Title";
import Filler from "../../components/Filler";
import Loader from "../../components/Loader";
import { SelectInput, SimpleTextInput } from "../../components/Inputs";
import { StandardButton } from "../../components/Buttons";
import { getGrillesPrix, updateGrillePrix } from "../../api/prixApi";
import { addErrorMessage } from "../../features/errorSlice";

import '../../styles/gestionTarifsLocation.css';

export default function GestionPrixLocation(){

    const dispatch = useDispatch();

    const [grilles, setGrilles] = useState(null);
    const [currentGrille, setCurrentGrille] = useState(null);


    useEffect(() => {
        if(currentGrille == null) {
            getGrillesPrix()
            .then(res=> {
                setGrilles(res);
            })
            .catch(err =>{
                console.log(err);
                dispatch(addErrorMessage({type:"error"})); 
            });
        }

    }, [currentGrille])
   



    return (
        <div className='GestionTarifsLocation'>
            <Title title="Administration des tarifs de location"><EuroIcon/></Title>
            <ListeGrillesPrix  setCurrentGrille={setCurrentGrille} grilles={grilles} currentGrille={currentGrille}/>
            <div className="Block formGrillePrix">
                {currentGrille == null ? 
                    <Filler text="Sélectionnez une grille de prix dans la liste pour modifier les tarifs associes"/> :
                    <EditGrillePrix grille={currentGrille} setCurrentGrille={setCurrentGrille}/>
                }
            </div>
        </div>
    )
}


function ListeGrillesPrix({grilles, setCurrentGrille, currentGrille}){
    return (
        <div className="Block listeProduits">
            <BlockTitle title="Mes grilles de prix"/>
            {grilles == null ? 
                <Loader width={50} height={50}/> :
                <div>
                    <SelectInput  
                    value={currentGrille == null ? "" : currentGrille.Code}
                    onChange={(e) => setCurrentGrille(grilles.find(g => g.Code === e.target.value))}
                    options={grilles.map(f => {return {name:f.Libelle, value:f.Code}})}/>
        
                    <table>
                        <tbody>
                            {
                                grilles.map((g) => {
                                    return  <LigneGrillePrix key={g.Code} grille={g} setCurrentGrille={setCurrentGrille} currentGrille={currentGrille}/>
                                })
                            }
                        </tbody>
                        
                    </table>
                </div>
            } 
        </div>
    )
}

function LigneGrillePrix({grille, setCurrentGrille, currentGrille}){

    const selected = currentGrille?.Code === grille.Code;

    return (
       <tr className={"LigneGrillePrix" + (selected ? " selected" : "")} onClick={() => setCurrentGrille(grille)}>
            <td>{grille.Libelle}</td>
       </tr>
    )

}

function EditGrillePrix({grille, setCurrentGrille}){

    const [nouveauxPrix, setNouveauxPrix] = useState([]);
    const dispatch = useDispatch();

    const updatePrix = (codeDetail, newPrix) => {
        setNouveauxPrix([
            ...nouveauxPrix.filter(el => el.CodeDetail !== codeDetail),
            {
                CodeDetail : codeDetail,
                Prix_depart: newPrix.toFixed(2)
            }
        ])
    }

    const enregistreGrillePrix = () => {
        updateGrillePrix(grille.Code, nouveauxPrix)
        .then(res=> {
            if (res) {
                dispatch(addErrorMessage({type:"success", message:"Les tarifs de location ont été enregistrés avec succès"}));
                setCurrentGrille(null);
            }
        })
        .catch(err =>{
            console.log(err);
            dispatch(addErrorMessage({type:"error"})); 
        });

    }


    return (
       <div className="editGrillePrix">
          <BlockTitle title={grille.Libelle}/>
            <div className="buttons">
                <StandardButton label="Enregistrer" onClick={enregistreGrillePrix}/>
                <StandardButton label="Abandon" onClick={() => setCurrentGrille(null)}/>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th>Nombre de jours</th>
                        <th>Prix</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        grille.Prix.map((p) => {
                            return <LignePrix key={p.CodeDetail} prix={p} updatePrix={updatePrix}/>
                        })
                    }
                </tbody>
            </table>
            <div className="flex">
            </div>

       </div>
    )

}

function LignePrix({prix, updatePrix}){

    const [value, setValue] = useState(prix.Prix_depart);
    const inputRef = useRef(null);

    const onBlurPrix = () => {
        let newPrix = parseFloat(value);
        if (isNaN(newPrix) || newPrix <0 || newPrix == prix.Prix_depart) {
            setValue(prix.Prix_depart);
            return;
        }
        updatePrix (prix.CodeDetail, newPrix);
    }

    
    const onKeyUp = (e) => {
        if (e.keyCode === 13) {
            inputRef.current.blur();
        }
    }


    return (
       <tr className="lignePrix">
            <td>{prix.Nb_ut_depart}</td>
            <td>
                <div>
                    <SimpleTextInput
                        ref={inputRef}
                        style={{width:70}}
                        value={value}
                        onChange={(e) => setValue(e.target.value)} 
                        onBlur={onBlurPrix}
                        onKeyUp={onKeyUp}
                    />
                    <span>{String.fromCodePoint(8364)}</span>
                </div>
            </td>
       </tr>
    )

}
