import errorHandler from "../utils/ErrorHandler";

export async function getProduits(stockActuel=0, produitsLocaux=0, shopId=null) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/produits/'+( shopId == null ? '' : shopId+'/' )+stockActuel+'/'+produitsLocaux, {
        method: 'GET',
        credentials: 'include'
    });

    if (response.status>=500) throw new Error("getProduits error");
    let result = await response.json();
    result = errorHandler(result);
    if (!result) return [];
    return formatListeProduits(result.produits);
       
}


export async function getParametresProduits() {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/produits/parametres', {
        method: 'GET',
        credentials: 'include'
    });

    if (response.status>=500) throw new Error("getParametresProduits error");
    let result = await response.json();
    result = errorHandler(result);
    if (!result) return [];
    return result.parametres;
    
}


export async function checkCodeProduit(data) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/produits/checkCode', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    })

    if (response.status>=500) throw new Error("checkCodeProduit error");
    let result = await response.json();
    return errorHandler(result);
   
}


export async function sortFamilles(data) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/produits/familles/sort', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    })

    if (response.status>=500) throw new Error("sortFamilles error");
    let result = await response.json();
    return errorHandler(result);
   
}


export async function sortProduits(data) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/produits/sort', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    })

    if (response.status>=500) throw new Error("sortFamilles error");
    let result = await response.json();
    return errorHandler(result);
   
}


export async function createProduits(produitsLocaux, data) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/produits/add/' +( produitsLocaux ? 'local' : 'siege'), {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    });

    if (response.status>=500) throw new Error("createProduits error");
    let result = await response.json();
    return errorHandler(result);

}

export async function updateProduits(produitsLocaux, data) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/produits/update/' +( produitsLocaux ? 'local' : 'siege'), {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    });

    if (response.status>=500) throw new Error("updateProduits error");
    let result = await response.json();
    return errorHandler(result);

}


function formatListeProduits (listeProduits) {
    let result = [];
    listeProduits.forEach((produit)=>{
        if (result.find((famille) => famille.code === produit.Famille_Code) == null) result.push({
            code: produit.Famille_Code,
            libelle: produit.Famille_Libelle,
            indice:produit.Famille_Indice,
            stock: 0,
            stockMini: null,
            produits: []
        });

        let famille = result.find((famille) => famille.code === produit.Famille_Code);
        if (famille.produits.find((prod) => prod.code === produit.Code) == null) famille.produits.push({
            code: produit.Code,
            libelle: produit.Libelle,
            fournisseur: produit.Fournisseur,
            famille: produit.Famille_Libelle,
            indice:produit.Indice,
            stock: 0,
            stockMini: null,
            tailles: []
        });

        let prod = famille.produits.find((prod) => prod.code === produit.Code);
        if (prod.tailles.find((taille) => taille.code === produit.Taille_Code) == null) prod.tailles.push({
            code: produit.Taille_Code,
            libelle: produit.Taille_Libelle,
            stock: 0,
            stockMini: null,
            couleurs: []
        });

        let taille = prod.tailles.find((taille) => taille.code === produit.Taille_Code);
        if (taille.couleurs.find((couleur) => couleur.code === produit.Couleur_Code && couleur.ean13 === produit.Ean13) == null) taille.couleurs.push({
            code: produit.Couleur_Code,
            ean13: produit.Ean13,
            libelle: produit.Couleur_Libelle,
            prixHT: produit.Prix_ht,
            prixAchatHT: produit.PrixAchat_ht,
            prixTTC: produit.Prix_ttc, 
            tva: produit.Tva, 
            stock: produit.Stock,
            stockMini: produit.Stock_mini,
        });

        taille.stock += produit.Stock
        prod.stock += produit.Stock;
        famille.stock += produit.Stock;

        taille.stockMini += produit.Stock_mini
        prod.stockMini += produit.Stock_mini;
        famille.stockMini += produit.Stock_mini;

    });

    return result;
}