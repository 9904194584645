import errorHandler from "../utils/ErrorHandler";

// export async function getShopsStatistiques () {
//     let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/statistiques/shops/', {
//         method: 'GET',
//         credentials: 'include',
//     });
    
//     if (response.status>=500) throw new Error("getShopsStatistiques api error");
//     let result = await response.json();
//     result = errorHandler(result);
//     if (!result) return {};
//     return result.shops;

// }


export async function getPrevisionsSorties (date) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/statistiques/previsionsSorties/'+date, {
        method: 'GET',
        credentials: 'include',
    });

    if (response.status>=500) throw new Error("getPrevisionsSorties api error");
    let result = await response.json();

    result = errorHandler(result);
    if (!result) return {};
    return result.data;

}

export async function getPalmares (of, debut, fin, id='all') {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/statistiques/palmares/'+id+'/top/'+of+'/'+debut+'/'+fin, {
        method: 'GET',
        credentials: 'include',
    });

    if (response.status>=500) throw new Error("getPalmares api error");
    let result = await response.json();

    result = errorHandler(result);
    if (!result) return {};
    return result.data;

}

export async function getChiffreAffairesGeneral (date, id) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/statistiques/chiffreAffaires/'+id+'/'+date, {
        method: 'GET',
        credentials: 'include',
    })

    if (response.status>=500) throw new Error("getChiffreAffaires api error");
    let result = await response.json();

    result = errorHandler(result);
    if (!result) return {};
    return result.data;
}

export async function getChiffreAffairesExercice (id) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/statistiques/chiffreAffaires/'+id, {
        method: 'GET',
        credentials: 'include',
    })

    if (response.status>=500) throw new Error("getChiffreAffairesExercice api error");
    let result = await response.json();

    result = errorHandler(result);
    if (!result) return {};
    return result.data;
}

export async function getCaisse (id, type, debut, fin) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/statistiques/caisse/'+id+'/'+type+'/'+debut+'/'+fin, {
        method: 'GET',
        credentials: 'include',
    })

    if (response.status>=500) throw new Error("getCaisse api error");
    let result = await response.json();

    result = errorHandler(result);
    if (!result) return {};
    return result.data;
}

export async function getSorties (id, date, top) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/statistiques/sorties/'+id+'/'+date+'/'+top, {
        method: 'GET',
        credentials: 'include',
    })

    if (response.status>=500) throw new Error("getSorties api error");
    let result = await response.json();

    result = errorHandler(result);
    if (!result) return {};
    return result.data;
}

export async function getChiffreAffairesCumul (date, id) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/statistiques/chiffreAffairesCumul/'+id+'/'+date, {
        method: 'GET',
        credentials: 'include',
    })

    if (response.status>=500) throw new Error("getChiffreAffairesCumul api error");
    let result = await response.json();

    result = errorHandler(result);
    if (!result) return {};
    return result.chiffreAffaires;
}