import errorHandler from "../utils/ErrorHandler";

export async function getLots() {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/lots', {
        method: 'GET',
        credentials: 'include',
    });

    if (response.status>=500) throw new Error("getLots api error");
    let result = await response.json();
    result = errorHandler(result);
    if (!result) return [];
    return result.lots;

}

export async function getLotById (id) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/lots/'+id, {
        method: 'GET',
        credentials: 'include',
    })

    if (response.status>=500) throw new Error("getLotById api error");
    let result = await response.json();
    result = errorHandler(result);
    if (!result) return {};
    return result.lot;


}

export async function createLot(data){
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/lots/new', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    
    });

    if (response.status>=500) throw new Error("getLotById api error");
    let result = await response.json();
    return errorHandler(result);

}


export async function updateLot(data){
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/lots/update', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    
    });

    if (response.status>=500) throw new Error("getLotById api error");
    let result = await response.json();
    return errorHandler(result);
}
