import errorHandler from "../utils/ErrorHandler";

export async function getPublicKey () {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/key/', {
        method: 'GET',
        credentials: 'include',
    })
    if (response.status>=500) throw new Error("getPublicKey api error");
    let result = await response.json();
    return errorHandler(result);

}
