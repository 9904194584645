export async function login(credentials) {
  let json = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/auth/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(credentials),
  
  })
  .then(response => {
    if (response.code>=500){
      throw new Error ("Une erreur est survenue");
    }
    return response.json();
  })
  .catch(err => {
    throw new Error (err);
  });
    
  return json;

}


export async function logout() {
    await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/auth/logout', {
      credentials: 'include',
      method: 'POST'
    
    }).then(response => response.json())
    .catch(err => {
      console.log(err);
      throw new Error(err);
    }); 
}

export async function checkAuth() {
  return await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/auth/checkauth', {
      credentials: 'include',
      method: 'GET',
    })
    .then(response => response.json())
    .catch(err=>{
      console.log("error");
      console.log(err);
      return null;
    });
      
}
  