import errorHandler from "../utils/ErrorHandler";

export async function getListePaiements() {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/paiements', {
        method: 'GET',
        credentials: 'include',
    });

    if (response.status>=500) throw new Error("getListePaiements api error");
    let result = await response.json();
    result = errorHandler(result);
    if (!result) return [];
    return result.paiements;

}
