import { HomeHeader } from "../../components/HomeHeaderFooter.js";
import { Masonry } from "@mui/lab";
import AideConsultationWidget from "../../components/admin/AideConsultation.js";
import ConnectionStatusWidget from "../../components/admin/ConnectionStatus.js";
import UserLoginFailed from "../../components/admin/UseLoginFailed.js";
import ActiveSessions from "../../components/admin/ActiveSessions.js";
import OpenedSockets from "../../components/admin/OpenedSockets.js";
import ChiffreAffairesCumul from "../../components/statistiques/ChiffreAffairesCumul.js";
import dayjs from "dayjs";
import { ExpandIcon, StatsIcon } from "../../components/Icons.js";
import { useNavigate } from "react-router-dom";


export default function AdminHome (){


    const navigate = useNavigate();

    return (
        <div className="AdminHome">
            <HomeHeader style={{width:'100%'}}/>
            <div>
                <Masonry columns={{ sm: 1, md: 2, xl: 3 }} spacing={2} sx={{ width: "auto" }}>
                    <ConnectionStatusWidget/>
                    <AideConsultationWidget/>
                    <UserLoginFailed/>
                    <ActiveSessions/>
                    <OpenedSockets/>
                    <div className="Block">
                        <h3>
                            <StatsIcon/>
                            <span>Chiffre d'affaires cumulé</span>
                            <button onClick={() => navigate('ca')}><ExpandIcon/></button>
                        </h3>
                        <ChiffreAffairesCumul date={dayjs().format('YYYY-MM-DD')} id={0}/>
                    </div>
                </Masonry>
            </div>
        </div>
    )
}

