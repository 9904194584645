import dayjs from "dayjs";
import { controleCaisse } from "../api/caisseApi";
import { useEffect, useRef, useState } from "react";
import errorHandler from "../utils/ErrorHandler";
import { ToggleSwitch, DateInput } from "../components/Inputs";
import { StandardButton } from "../components/Buttons";
import { editeCaisse } from "../api/caisseApi";
import { useDispatch, useSelector } from "react-redux";
import { addErrorMessage } from "../features/errorSlice";
import { FileIcon, PdfIcon, SaveIcon } from "../components/Icons";
import Loader from "../components/Loader";
import Filler from "../components/Filler";
import { ErrorTooltip } from "../components/Tooltips";
import { Title } from "../components/Title";

import '../styles/caisse.css';
import { updateCaisseSettings } from "../api/usersApi";
import { setParametres } from "../features/authSlice";
import { VideoLink } from "../components/VideoLink";
import { useSmallDevice } from "../app/hooks";

export default function Caisse (){

    const dispatch = useDispatch();

    const frameRef = useRef(null);

    const [checkControleCaisse, setCheckControleCaisse] = useState(-1);

    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        dataError : false,
        datesError : "",
        modePaiement: useSelector((state) => state.user.parametres.caisse.modePaiement),
        journalVente: useSelector((state) => state.user.parametres.caisse.journalVente),
        dateDebut: dayjs(),
        dateFin: dayjs(),
    })

    const onClickSaveSettings = () => {
       
        updateCaisseSettings({modePaiement: formData.modePaiement, journalVente:formData.journalVente})
        .then((res) =>{
            console.log(res);
            dispatch(setParametres({settings:res.settings}));
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
        });

    }

    
    const onClickEditeButton = () => {
        
        if (!formData.journalVente && !formData.modePaiement){
            setFormData({...formData, dataError: true});
            return; 
        }

        let today = dayjs();
        if(formData.dateDebut>today || formData.dateFin>today || formData.dateDebut>formData.dateFin){
            setFormData({...formData, datesError: "Les dates saisies sont invalides"});
            return;
        }

        if( formData.journalVente && formData.dateFin.diff(formData.dateDebut, 'days') > 7){
            setFormData({...formData, datesError: "Impossible d'éditer le journal des ventes sur une période de plus de 7 jours"});
            return;
        }

        setLoading(true);
        frameRef.current.src = 'about:blank';
        

        editeCaisse({
            modePaiement: formData.modePaiement,
            journalVente: formData.journalVente,
            dateDebut : formData.dateDebut.format('YYYY-MM-DD'),
            dateFin : formData.dateFin.format('YYYY-MM-DD')
        })
        .then((data) =>{
            console.log(data);
            if (data.type.includes('application/json')) throw new Error (data);
            frameRef.current.src = window.URL.createObjectURL(data);
            setLoading(false);
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error", message:"Impossible de charger l'état de caisse"}));
            setLoading(false);
        });
    }

    useEffect(() => {
        controleCaisse()
        .then((res)=>{
            console.log(res);
            let result = errorHandler (res);
            console.log(result);
            if (result !== false) setCheckControleCaisse(1);
            else setCheckControleCaisse(0)
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error", message:error.message}));
        });
        return () => {
        };
    }, []);


    const onChangeCheckbox = (e) =>{
        setFormData((prevFormData) => ({ ...prevFormData, [e.target.name]: e.target.checked, dataError:false }));
    }

    const handleDateDebutChange = (val) => {
        setFormData({
            ...formData,
            dateDebut:val,
            datesError:""
        })
    }

    const handleDateFinChange = (val) => {
        setFormData({
            ...formData,
            dateFin:val,
            datesError:""
        })
    }

    return (
        <div className='Caisse'>
                <Title title="Edition de caisse" backTo="/caisse"><FileIcon/></Title>
                <div className="Block filtres">
                    <div className="filtresColumn">
                        <ErrorTooltip title={'Aucune donnée n\'est sélectionnée'} open={formData.dataError} placement={useSmallDevice() ? "bottom" : "right"}>
                            <h3>Données :</h3>
                        </ErrorTooltip>
                        <ToggleSwitch label="Modes de paiement" name="modePaiement" value="1" isChecked={formData.modePaiement} onChange={onChangeCheckbox}/>
                        <ToggleSwitch label="Journal des ventes" name="journalVente" value="1" isChecked={formData.journalVente} onChange={onChangeCheckbox}/> 
                        <button className="saveSettings" onClick={onClickSaveSettings}>
                            <SaveIcon width={16} height={16}/>
                            Enregistrer les réglages
                        </button>
                    </div>
                    <div className="filtresColumn">
                        <ErrorTooltip title={formData.datesError} open={true} placement={useSmallDevice() ? "bottom" : "right"}>
                            <h3>Dates de l'édition :</h3>
                        </ErrorTooltip>
                        <div>
                            <span>du&nbsp;:&nbsp;</span>
                            <DateInput label="date" name="dateDebut" value={formData.dateDebut} onChange={handleDateDebutChange} style={{margin:'0 auto'}} maxDate={dayjs()}/>
                        </div>
                        <div>
                            <span>au&nbsp;:&nbsp;</span> 
                            <DateInput label="date" name="dateFin" value={formData.dateFin} onChange={handleDateFinChange} style={{margin:'0 auto'}} maxDate={dayjs()}/>
                        </div>
                    </div>
                    <div className="filtresColumn">
                        <StandardButton label="Editer" isLocked={checkControleCaisse<=0 || loading} onClick={onClickEditeButton}/>
                        {checkControleCaisse<0 ?
                            <div className="controleLoader">
                                <span>Controle de caisse en cours</span>
                                <Loader height={20} width={20}></Loader>
                            </div>
                            :null
                        }
                    </div>
                </div>
                <div className="Block">
                    <div className="frame">
                        <Filler 
                            loading={loading}
                            text="Sélectionnez la date d'édition et les données à afficher pour générer l'état de caisse">
                            <PdfIcon/> 
                        </Filler>
                        <iframe ref={frameRef}/>
                    </div>
                </div>
                <VideoLink link="https://www.youtube.com/embed/AeCSb3Hxf4I?si=XeD9zA_wdCRL3EWX" libelle='editionCaisse'/>
        </div>
    )
}
