export const getChartColorTheme = (color) =>{
    switch(color){
        case "purple" :
            return {
                colorN: "#7c86ec",
                colorN_1: "#9ca3ebB0",
                labelColorN: '#BEBFE6',
                labelColorN_1: '#7880EC'
            };
        case "orange" : 
            return {
                colorN: "#f5a278",
                colorN_1: "#f5bfa3b0",
                labelColorN: '#FFE3D3',
                labelColorN_1:'#EC945C'
            };
        case "green" : 
            return {
                colorN: "#b2e36d",
                colorN_1: "#d7ecbbB0",
                labelColorN: '#E4FCBF',
                labelColorN_1: '#B1CC87'
            };
        default : //bleu
            return {
                colorN: "#9DD3F3",
                colorN_1: "#BFE3F8B0",
                labelColorN: '#F4F8FE',
                labelColorN_1: '#8AB3C7'
            };
    }
    
  }