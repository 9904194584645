import { useState, forwardRef } from "react";
import { ShowIcon, HideIcon } from "./Icons";
import { DatePicker, DateTimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { frFR } from '@mui/x-date-pickers/locales';


import '../styles/inputs.css';

export const TextInput = forwardRef(({label, name, value, onChange, onKeyDown=null,onBlur, error, className, required, autoFocus, tabIndex, style, type='text', min=null, max=null, step=null}, ref) => {

    const onKeyDownInput = (e) => {
        if (onKeyDown && typeof onKeyDown === "function") {
            onKeyDown(e);
        }
    }

    const onBlurInput = (e) => {
        if (onBlur && typeof onBlur === "function") {
            onBlur(e);
        }
    }

    return (
        <div style={style} className={"input "+className}>
			<input 
            type={type} 
            ref={ref} 
            id={name} 
            min={min}
            max={max}
            step={step}
            name={name} 
            value={value} 
            placeholder=" "
            tabIndex={tabIndex} 
            required={required}
            autoFocus={autoFocus} 
            onBlur={onBlurInput}
            onChange={e=>{onChange(e)}} 
            onKeyDown={e=>{onKeyDownInput(e)}} />
			<label htmlFor={name} placeholder={label} alt={label}></label>
		</div>
    )
})

export function PasswordInput({label, name, value, onChange, style, required}){

    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className="input password" style={style}>
			<input 
            id={name} 
            name={name} 
            value={value} 
            placeholder=" " 
            required={required}
            onChange={e=>{onChange(e)}}
            type={showPassword? "text":"password"} />
			<label htmlFor={name} placeholder={label} alt={label}></label>
            <button tabIndex={-1} type="button" onClick={() => setShowPassword(!showPassword)}>
               {showPassword ? <ShowIcon/> : <HideIcon/>}
          </button>
		</div>
    )
}


export function DateInput({name, value, onChange, maxDate, minDate, style, className='', label}){
    return (
        <LocalizationProvider
            localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
            <DatePicker
                maxDate={maxDate}
                minDate={minDate}
                label={label}
                className="dateTime"
                value={value} 
                name={name}
                onChange={(val) => onChange(val)} 
            />
        </LocalizationProvider>
       
    )
}

export function DateTimeInput(props){
    return (
        <LocalizationProvider
            localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
            <DateTimePicker
                {...props}
                viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                }}
                className="dateTime"
            />
        </LocalizationProvider>
       
    )
}



export function ToggleSwitch({label, name, isChecked, onChange}){
    return (
        <div className="switchContainer">
            <label className="switch">
                <input type="checkbox" name={name} checked={isChecked} onChange={onChange}/>
                <span className="slider round"></span>
            </label>
            <label className="switchLabel">{label}</label>
        </div>
    )
}

export function TextAreaInput({name, value, onChange, className=''}){
    return (
        <textarea className={"textareaInput " + className} value={value} name={name} onChange={(e)=>onChange(e)}></textarea>
    )
}


export function SelectInput({name,label, value, onChange, className, style, options, required, withEmptyOption=true}){
    return (
        <div className={"select "+className} style={style}>
            <select  name={name} id={name} value={value} onChange={onChange} required={required}>
                {withEmptyOption ? <option></option> : null}
                {
                    options.map((opt) => {
                        return <option key={opt.value} value={opt.value}>{opt.name}</option>
                    })
                }
            </select>
			<label htmlFor={name} placeholder={label} alt={label}></label>
        </div>
    )
}

export const SimpleTextInput = forwardRef(({name, value, onChange, onBlur, onKeyUp, className, autoFocus, onFocus, style, type='text'}, ref) => {
        
    return (
        <div style={style} className={"simpleInput "+className}>
			<input 
            autoFocus={autoFocus}
            onFocus={onFocus}
            ref={ref}
            name={name}
            type={type} 
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            value={value}  
            onChange={e=>{onChange(e)}}  />
		</div>
    )
})

export const LockedInput = ({label, name, value, style, className}) => {
    return (
        <div style={style} className={"input locked "+className}>
			<input 
                name={name}
                value={value} 
                onChange={()=>{}}
                placeholder=" "/>
			<label htmlFor={name} placeholder={label} alt={label}></label>
		</div>
    )
}
