import { addErrorMessage } from "../features/errorSlice";
import store from '../app/store';
import { addArticleToFiche, updateSupplement, updateDuree, removePendingArticle, removeArticleFromFiche, setPendingFieldArticle } from "../features/ficheSlice";

export const onAddArticle = (response) => {
    console.log(response);
    if (!response.success) store.dispatch(addErrorMessage({type:"error", message:response.message}));
    else store.dispatch(addArticleToFiche({article: response.article})); 
    store.dispatch(removePendingArticle());
}

export const onUpdateSupplement = (response) => {
    console.log(response);
    if (!response.success) {
        store.dispatch(addErrorMessage({type:"error", message:response.message}));
        store.dispatch(setPendingFieldArticle({id:response.query.indice, fieldName:'supplement', pending : false}));
    }
    else {
        store.dispatch(updateSupplement({id: response.indice, supplement:response.supplement, prix:response.prix}));
        store.dispatch(setPendingFieldArticle({id:response.indice, fieldName:'supplement', pending : false}));
    } 
}

export const onUpdateDuree = (response) => {
    console.log(response);
    if (!response.success) {
        store.dispatch(addErrorMessage({type:"error", message:response.message}));
        store.dispatch(setPendingFieldArticle({id:response.query.indice, fieldName:'duree', pending : false}));
    }
    else {
        store.dispatch(updateDuree({id: response.indice, duree:response.duree, dateFin:response.dateFin, montant:response.montant, prix:response.prix})); 
        store.dispatch(setPendingFieldArticle({id:response.indice, fieldName:'duree', pending : false}));
    }
}

export const onRemoveArticle = (response) => {
    console.log(response);
    if (!response.success) {
        store.dispatch(addErrorMessage({type:"error", message:response.message}));
        store.dispatch(setPendingFieldArticle({id:response.query.indice, fieldName:'delete', pending : false}));
    }
    else store.dispatch(removeArticleFromFiche({id:response.indice}));
}


