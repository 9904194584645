import { useEffect, useState } from "react";
import { getChiffreAffairesGeneral} from "../../api/statistiquesApi";
import { useDispatch } from "react-redux";
import { addErrorMessage } from "../../features/errorSlice";
import Loader from "../Loader";
import { SmallBlock, StatistiquesTitle } from "./DataBlocks";
import dayjs from "dayjs";

export default function ChiffreAffaires ({date, id}) {

    const dispatch = useDispatch();
    const [data, setData] = useState({});

    useEffect(() => {
        getChiffreAffairesGeneral(date, id)
        .then(res=>{
            if (res) {
                setData(res);
            }
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
        });
    }, []);



    return (
        <div className="Block">
            <StatistiquesTitle title="Chiffre d'affaires" shopsKO={data.shopsKO}/>
            {
                data.shopsKO == null ?
                <Loader/>
                : 
                data.date == null ? 
                null :
                <div>
                    <span className="date">{dayjs(date).format('dddd D MMMM YYYY')}</span>
                    <div className="flex">
                        <SmallBlock title="jour J" valueN={data.chiffreAffairesJN} valueN_1={data.chiffreAffairesJN_1} unit={String.fromCodePoint(8364)}/>
                        <SmallBlock title="J-7" valueN={data.chiffreAffairesJ7N} valueN_1={data.chiffreAffairesJ7N_1} unit={String.fromCodePoint(8364)}/>
                    </div>
                    <div className="flex">
                        <SmallBlock title="J-30" valueN={data.chiffreAffairesJ30N} valueN_1={data.chiffreAffairesJ30N_1} unit={String.fromCodePoint(8364)}/>
                        <SmallBlock title="Exercice" valueN={data.chiffreAffairesExN} valueN_1={data.chiffreAffairesExN_1} unit={String.fromCodePoint(8364)}/>
                    </div>
                </div>  
            }
        </div>
    )
}