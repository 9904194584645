import { addProduitToFiche, updateQuantiteProduit, updatePrixProduit, removeProduitFromFiche, removePendingProduit, addPaiement, deletePaiement, setPendingFieldProduit, setPendingPaiement, removePendingPaiement } from "../features/ficheSlice";
import { addErrorMessage } from "../features/errorSlice";
import store from '../app/store';

export const onAddProduit = (response) => {
    console.log(response);
    if (!response.success) store.dispatch(addErrorMessage({type:"error", message:response.message}));
    else store.dispatch(addProduitToFiche({produit: response.produit})); 
    store.dispatch(removePendingProduit());
}

export const onUpdateQuantite = (response) => {
    console.log(response);
    if (!response.success) {
        store.dispatch(addErrorMessage({type:"error", message:response.message}));
        store.dispatch(setPendingFieldProduit({id:response.query.indice, fieldName:'quantite', pending : false}));
    }       
    else {
        store.dispatch(updateQuantiteProduit({id:response.indice, quantite:response.quantite, prix:response.prix}));
        store.dispatch(setPendingFieldProduit({id:response.indice, fieldName:'quantite', pending : false}));
    }
}

export const onUpdatePrix = (response) => {
    console.log(response);
    if (!response.success) {
        store.dispatch(addErrorMessage({type:"error", message:response.message}));
        store.dispatch(setPendingFieldProduit({id:response.query.indice, fieldName:'prix', pending : false}));
    }      
    else {
        store.dispatch(updatePrixProduit({id:response.indice, prix:response.prix, remise:response.remise}));
        store.dispatch(setPendingFieldProduit({id:response.indice, fieldName:'prix', pending : false}));
    }
}

export const onRemoveProduit = (response) => {
    console.log(response);
    if (!response.success) {
        store.dispatch(addErrorMessage({type:"error", message:response.message}));
        store.dispatch(setPendingFieldProduit({id:response.query.indice, fieldName:'delete', pending : false}));
    }
    else {
        store.dispatch(removeProduitFromFiche({id:response.indice}));
    }
}

export const onAddPaiement = (response) => {
    console.log(response);
    store.dispatch(removePendingPaiement());
    if (!response.success) store.dispatch(addErrorMessage({type:"error", message:response.message}));
    else store.dispatch(addPaiement({id: response.id, code: response.codePaiement, montant:response.montant, libelle:response.libelle}));
}

export const onDeletePaiement = (response) => {
    console.log(response);
    if (!response.success) {
        store.dispatch(addErrorMessage({type:"error", message:response.message}));
        store.dispatch(setPendingPaiement({id:response.query.idPaiement, pending:false}));
    }
    else store.dispatch(deletePaiement({id: response.idPaiement}));
}


