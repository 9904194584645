import errorHandler from "../utils/ErrorHandler";

export async function getArticles() {

    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/articles', {
        method: 'GET',
        credentials: 'include'
    });

    if (response.status>=500) throw new Error("getArticles api error");
    let result = await response.json();
    result = errorHandler(result);
    if (!result) return [];
    return formatListeArticles(result.articles);

}

export async function getDisponibiliteArticles(data) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/articles/disponibilites', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    });

    if (response.status>=500) throw new Error("getDisponibiliteArticles api error");
    let result = await response.json();
    return errorHandler(result);
}


export async function getPacks() {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/articles/packs', {
        method: 'GET',
        credentials: 'include',
    });

    if (response.status>=500) throw new Error("getPacks api error");
    let result = await response.json();
    return errorHandler(result);
}

export async function getQuantites(pack) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/articles/packs/quantites/'+pack, {
        method: 'GET',
        credentials: 'include',
    });

    if (response.status>=500) throw new Error("getQuantites api error");
    let result = await response.json();
    return errorHandler(result);

}

export async function updateQuantites(pack, data) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/articles/packs/quantites/'+pack, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    });

    if (response.status>=500) throw new Error("updateQuantites api error");
    let result = await response.json();
    return errorHandler(result);

}

export async function createReduction(pack, data) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/articles/packs/quantites/reduction/'+pack, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    });

    if (response.status>=500) throw new Error("createReduction api error");
    let result = await response.json();
    return errorHandler(result);
    
}

export async function sortArticles(data) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/articles/sort', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    })

    if (response.status>=500) throw new Error("sortArticles error");
    let result = await response.json();
    return errorHandler(result);
}

export async function disabledPrevisionsSortiesPack(data) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/articles/packs/previsions/disable', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    })

    if (response.status>=500) throw new Error("disabledPrevisionsSortiesPack error");
    let result = await response.json();
    return errorHandler(result);
}

function formatListeArticles (listeArticles) {

    let result = [];
    
    listeArticles.forEach((article)=>{
        if (result.find((famille) => famille.code === article.FamilleProduit) == null) result.push({
            code: article.FamilleProduit,
            articles: []
        });

        let famille = result.find((famille) => famille.code === article.FamilleProduit);
        if (famille.articles.find((a) => a.CodeProduit === article.CodeProduit && a.Code_ean13 === a.ean13) == null) famille.articles.push({
            code: article.CodeProduit,
            categorie: article.Categorie,
            ean13:article.Code_ean13,
            taille: article.Taille,
            idPack : article.IdPack,
            indice: article.Indice
        });

    });

    return result;
}