import MainGestionProduits from "../components/gestionProduits/MainGestionProduits";
import { VideoLink } from "../components/VideoLink";

export default function GestionProduitsLocaux (){

    
    return (
        <MainGestionProduits produitsLocaux={1}>
            <VideoLink link={"https://www.youtube.com/embed/ARUIBxDzj1I?si=1jwEkJwv9fvzF5Hy"} libelle='produitsLocaux'/>
        </MainGestionProduits>
    )
}