import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addErrorMessage } from "../../features/errorSlice";
import { LockIcon } from "../Icons";

import '../../styles/admin/connectionStatus.css';
import { getListeLoginFailed, unlockAccount } from "../../api/sessionsApi";
import Loader from "../Loader";

import '../../styles/admin/userLoginFailed.css';

export default function UserLoginFailed () {

    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    
    useEffect(() => {
        if (data == null) {
            getListeLoginFailed()
            .then((res) => {
                setData(res);
            })
            .catch(error =>{
                setData([]);
                dispatch(addErrorMessage({type:"error"}));
            });
        }
    }, [data])

    return (
        <div className="Block UserLoginFailed">
            <h3>
                <LockIcon/>
                <span>Tentatives de connexions en échec</span>
            </h3>
            <div>
                { data == null ? <Loader/> :
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Utilisateur</th>
                                <th>Nombre de tentatives</th>
                                <th>Débloquer</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(a => {
                                return <AccountRow account={a} setData={setData} key={a.username}/>
                            })}
                        </tbody>
                    </table> 
                }
            </div>
            
        </div>
    )

}

function AccountRow ({account, setData}) {

    const onClickUnlockButton = () => {
        unlockAccount({username: account.username})
        .then(() => {
            setData(null);
        })
    }

    return (
        <tr className={account.locked ? "locked" : ""}>
            <td>{account.username}</td>
            <td>{account.nbFailed}</td>
            <td>{account.locked ?
                    <button onClick={onClickUnlockButton}><LockIcon/></button> : 
                    null
                }
            </td>
        </tr>
    )
}