import { useEffect, useState, useRef } from "react";
import { getPalmares } from "../../api/statistiquesApi";
import { useDispatch } from "react-redux";
import { addErrorMessage } from "../../features/errorSlice";
import { Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Loader from "../Loader";

import 'swiper/css/bundle';
import { NextIcon, PrevIcon } from "../Icons";
import dayjs from "dayjs";
import { StatistiquesTitle } from "./DataBlocks";
import { BarChart } from "../charts/BarChart";

export default function Palmares ({of, date, top, id=null}) {

    let dispatch = useDispatch();
    let [data, setData] = useState({});
    const sliderRef = useRef(null)
    const navigationNextRef = useRef(null);
    const navigationPrevRef = useRef(null);

    useEffect(() => {
        getPalmares(of, '@EXERCICEDEBUT@', date, id || 0)
        .then(res=>{
            if (res) {
                setData(res);
            }
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
        });
    }, []);           

    const nextSlide = () => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }

    const prevSlide = () => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }

    return (
        <div className="Block">
            <StatistiquesTitle title={"Palmarès "+ of} shopsKO={data.shopsKO}/>
            {data.shopsKO == null ?
                <Loader/>
                : 
                data.dateDebut == null ? 
                    null :
                    [

                        <span key="date" className="date">{'du ' + dayjs(data.dateDebut).format('dddd D MMMM YYYY') + ' au ' + dayjs(data.dateFin).format('dddd D MMMM YYYY')}</span>,
                        <div key="swiper" style={{height:100+55*top, width:'100%', position:'relative'}}>
                            <Swiper
                                // width={400}
                                ref={sliderRef}
                                style={{ "--swiper-pagination-color": "var(--main-color)"}}
                                modules={[Navigation, Pagination, A11y]}
                                navigation={{
                                    prevEl: navigationPrevRef.current,
                                    nextEl: navigationNextRef.current,
                                }}
                                onBeforeInit={(swiper) => {
                                    swiper.navigation.nextEl = navigationNextRef.current;
                                    swiper.navigation.prevEl = navigationPrevRef.current;
                                }}
                                pagination={{ clickable: false }}>
                                <SwiperSlide><Slide data={data.byQuantite == null ? [] :data.byQuantite} dataName={'quantite'}/></SwiperSlide>
                                <SwiperSlide><Slide data={data.byMontant == null ? [] :data.byMontant} dataName={'montant'}/></SwiperSlide>
                                <SwiperSlide><Slide data={data.byMarge == null ? [] :data.byMarge} dataName={'marge'}/></SwiperSlide>
                                <SwiperSlide><Slide data={data.byCoeff == null ? [] :data.byCoeff} dataName={'coeff'}/></SwiperSlide>
                            </Swiper>
                            <button className="swiperNavigationPrev" onClick={prevSlide}><PrevIcon/></button>
                            <button className="swiperNavigationNext" onClick={nextSlide}><NextIcon/></button>
                        </div>
                    ]
            }
        </div>
    )
}

function Slide ({data, dataName}){

    const getMainColor = (dataName) =>{
        switch(dataName){
            case "quantite" :
                return "purple";
            case "montant" : 
                return "orange";
            case "marge" : 
                return "green";
            default :
                return "blue";
        }
    }

    let height = 55*data.length;

    return (
        <div className="slidePalmares">
            <BarChart 
                data={data.map (el => {return {name: el.nom, valueN: el[dataName+'N'], valueN_1: el[dataName + 'N-1']}})} 
                title={"Par "+dataName} 
                dataName={dataName} 
                mainColor={getMainColor(dataName)} 
                style={{height:height}}/>
        </div>
    )
}




