import errorHandler from "../utils/ErrorHandler";


export async function getListeActiveSessions() {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/sessions', {
        method: 'GET',
        credentials: 'include',
    });

    if (response.status>=500) throw new Error("getListeActiveSessions api error");
    let result = await response.json();
    result = errorHandler(result);
    if (!result) return [];
    return result.sessions;

}

export async function getListeOpenedSockets() {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/sessions/sockets', {
        method: 'GET',
        credentials: 'include',
    });

    if (response.status>=500) throw new Error("getListeOpenedSockets api error");
    let result = await response.json();
    result = errorHandler(result);
    if (!result) return [];
    return result.sockets;

}

export async function getListeLoginFailed() {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/sessions/login/failed', {
        method: 'GET',
        credentials: 'include',
    });

    if (response.status>=500) throw new Error("getListeLoginFailed api error");
    let result = await response.json();
    result = errorHandler(result);
    if (!result) return [];
    return result.accounts;

}

export async function unlockAccount(params) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/sessions/accounts/unlock', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
    });

    if (response.status>=500) throw new Error("unlockAccount api error");
    let result = await response.json();
    return errorHandler(result);

}