import { getListePaiements } from "../../api/paiementsApi";
import { CBIcon, RefreshIcon, XIcon } from "../Icons";
import { PaymentMethodButton, CustomableButton } from "./FicheButtons";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addErrorMessage } from "../../features/errorSlice";
import Loader from "../Loader";
import { addPendingPaiement, setPendingPaiement } from "../../features/ficheSlice";
import {Dialog, DialogWithLoading} from "../Modals";
import { SimpleTextInput, ToggleSwitch } from "../Inputs";
import { InfoTooltip } from "../Tooltips";
import { BlockTitle } from "../Title";

import '../../styles/reglement.css';

export function Reglement({socket}) {

    
    const fiche = useSelector((state) => state.fiche);
    const voucher = useSelector((state) => state.user.parametres.voucher);



    const onChangeVoucher = (e) => {
        localStorage.setItem('voucher', e.target.checked);
        window.dispatchEvent(new Event("storage"));
    }


    return (
        <div className='Block Reglement'>
            <BlockTitle title={"Règlement"} icon={<CBIcon/>}></BlockTitle>

            {fiche.reservation == null ? <PaiementFiche socket={socket}/> : <PaiementReservation socket={socket}/>}
           

            {fiche.type === 'Vente' ?
                null :
                <div className="options">
                    <ToggleSwitch label="Télécharger le récapitulatif à la validation" name="voucher" isChecked={voucher} onChange={onChangeVoucher}/>
                </div>
            }

        </div>
    )
}


function PaiementFiche ({socket}) {

    const fiche = useSelector((state) => state.fiche);

    const totalLocation = fiche.articles.reduce(
        (total, product) => (total = total + product.prix),0);

    const totalVente = fiche == null ? 0: fiche.produits.reduce( function(a, b){
        return a + b.prixTotal;
    }, 0);

    return (
        <div>
            {fiche.type === 'Location' ? <DetailLocation totalLocation={totalLocation}/> : null}
            {fiche.type === 'Vente' ? <DetailVente totalVente={totalVente}/> : null}


            <h3 className='total'>
                <span>Total :</span>
                <span>{(totalVente + totalLocation).toFixed(2)}&nbsp;&euro;</span>
            </h3>

            <Paiements total={totalLocation+totalVente} socket={socket}/>

        </div>
    )
}

function PaiementReservation({socket}) {

    const reservation = useSelector((state) => state.fiche.reservation);
    const client = useSelector((state) => state.fiche.client);
    const [isLoading, setIsLoading] = useState(false);

    const useReservation = () => {
        setIsLoading(true);
        socket.current.emit("useReservation", {idResa:reservation?.IdResa});
    }

    const onUseReservation = () => {
        setIsLoading(false);
    }

    
    useEffect(() => {
        socket.current.on("useReservation", onUseReservation);
        return () => {
            socket.current.off("useReservation", onUseReservation);
        }
    }, []);

    return (
        <div className="ButtonList PaiementReservation">
            <div>Réservation E-Season</div>
            {isLoading ?
                <Loader width={42} height={42}/>
                :  
                <InfoTooltip title={"Veuillez compléter les informations client"} disabled={client != null} placement="left" arrow={false}>
                    <span>
                        <CustomableButton label={"Valider la location"} className="multi" isLocked={client == null} onClick={useReservation}/>
                    </span>
                </InfoTooltip>
                
                
            }
            
        </div>       
    )

}

function DetailLocation ({totalLocation}){

    const fiche = useSelector((state) => state.fiche);

    const totalSupplement = fiche.articles.reduce(
        (total, product) => (total = total + product.supplement),0);  

    return (
        <div>

            <p className='subTotal'>
                <span>Total Location:</span>
                <span>{totalLocation.toFixed(2)}&euro;</span>
            </p>
    
            {totalSupplement !== 0 ?
                <p className='subTotalInfo'>
                    <span>dont ajustements :</span>
                    <span>{totalSupplement.toFixed(2)}&euro;</span> 
                </p> 
                : null
            }
        </div>
    )
}


function DetailVente ({totalVente}){

    const fiche = useSelector((state) => state.fiche);

    const totalBaseVente = fiche== null ? 0: fiche.produits.reduce( function(a, b){
        return a + b.quantite*b.prixUnitaire;
    }, 0);

    return (
        <div>
            <p className='subTotal'>
                <span>Total Vente:</span>
                { totalBaseVente !== totalVente ? <span className="totalBase">{totalBaseVente.toFixed(2)}&nbsp;&euro;</span>: null}
                <span>{totalVente.toFixed(2)}&euro;</span>
            </p>

            {totalBaseVente !== totalVente && totalBaseVente>0 ?
                <p className='subTotalInfo'>
                    <span>soit une réduction de :</span>
                    <span>{(100*(totalBaseVente-totalVente)/totalBaseVente).toFixed(0)}&nbsp;%</span> 
                </p> 
                : null
            }
        </div>
    )
}


function Paiements ({total, socket}){

    const dispatch = useDispatch ();
    
    const paiements = useSelector((state) => state.fiche.paiements);
    const produits = useSelector((state) => state.fiche.produits);
    const pendingProduits = useSelector((state) => state.fiche.pendingProduits);
    const pendingArticles = useSelector((state) => state.fiche.pendingArticles);
    const articles = useSelector((state) => state.fiche.articles);
    const type = useSelector((state) => state.fiche.type);
    const numeroFiche = useSelector((state) => state.fiche.numeroFiche);

    const [multiPaiement, setMultiPaiement] = useState(false);
    const [montantManuel, setMontantManuel] = useState(0);
    const montantManuelRef = useRef(null);

    const [openModalSave, setOpenModalSave] = useState(false);
    const [isLoadingModalSave, setIsLoadingModalSave] = useState(false);

    
    const totalLocation = articles.reduce((total, product) => (total = total + product.prix),0);
    const totalVente = produits.reduce((total, product) => (total = total + product.prixTotal),0);
    const montantPaye = paiements.reduce((total, product) => (total = total + product.montant),0);
    
    
    const hasPendingUpdateProduit = (produit) => {
        return produit.pendingUpdate.quantite || produit.pendingUpdate.prix || produit.pendingUpdate.delete
    }

    const hasPendingUpdateArticle = (article) => {
        return article.pendingUpdate.duree || article.pendingUpdate.supplement || article.pendingUpdate.delete
    }

    const pendingUpdateFiche = pendingProduits >0 || pendingArticles>0 
    || produits.filter(el => hasPendingUpdateProduit(el)).length>0
    || articles.filter(el => hasPendingUpdateArticle(el)).length>0;

    const selectOnFocus = (event) => {
        event.target.select();
    }

    const saveMoyenPaiement = (codePaiement) => {
        let montant=montantManuel;
        if (!multiPaiement) montant = total; 
        if (montant == 0) return;
        dispatch(addPendingPaiement());
        socket.current.emit("addPaiement", {codePaiement:codePaiement, montant:parseFloat(montant)});
    }

    
    const deletePaiement = (idPaiement) => {
        dispatch(setPendingPaiement({id:idPaiement, pending:true}))
        socket.current.emit("deletePaiement", {idPaiement:idPaiement});
    }

    const deleteLastPaiement = ()=>{
        if (paiements.length > 0) {
            let lastIdPaiement = Math.max.apply(Math, paiements.map((el) => { return el.id }));
            deletePaiement(lastIdPaiement);
        }
        else setOpenModalSave(false);
        
        setIsLoadingModalSave(false);
    }

    const enregistreFiche = () => {
        console.log("enregistre fiche");
        setIsLoadingModalSave(true);
        if (type === 'Vente') socket.current.emit("enregistreVente");
        else if (type === 'Location') socket.current.emit("enregistreLocation");
    }

       
    useEffect(() => {
        setMontantManuel((total - montantPaye).toFixed(2));
        if (montantManuelRef.current != null) montantManuelRef.current.focus();
        
    }, [montantPaye, total]);

    const onEnregistreFiche =  (response) => {
        if (!response.success){
            setIsLoadingModalSave(false);
        }
    }


    useEffect(() => {
        socket.current.on("enregistreVente", onEnregistreFiche);
        socket.current.on("enregistreLocation", onEnregistreFiche);

        return () => {
            socket.current.off("enregistreVente", onEnregistreFiche);
            socket.current.off("enregistreLocation", onEnregistreFiche);
        }
    }, []);

    return (
        <div>
            <div>
                {paiements.map((pai) => {
                    return <LignePaiement key={pai.id} paiement={pai} onDelete={deletePaiement}/>
                })}
            </div>
                    
            <p className='subTotal resteAPayer'>
                <span>Reste a payer : </span>
                <span>{(total-montantPaye).toFixed(2)} &euro;</span>
            </p>
        
            <div className="paiements">
                {(produits.length > 0 || articles.length >0) && total === 0 ?
                    <div className='ButtonList'>
                        <CustomableButton label={"Enregistrer la "+type} className="multi" onClick={() =>setOpenModalSave(true)}/> 
                    </div>
                    : 
                    [
                        (multiPaiement? 
                            <div className="montantManuel" key="montantManuel">
                                <SimpleTextInput
                                    autoFocus={true}
                                    type='number'
                                    onFocus={selectOnFocus}
                                    style={{width:'100px', margin:'0.5em'}} 
                                    ref={montantManuelRef}
                                    value={montantManuel}
                                    onChange={(e) => setMontantManuel(e.target.value)}/>
                                <span>{String.fromCodePoint(8364)}</span>
                            </div>  
                            : null
                        ),
                    
                        ((articles.length>0 || produits.length>0) ?
                            <div key="listePaiements">
                                <ListeMoyenPaiements onClickButton={saveMoyenPaiement}/>
                                {!multiPaiement ? <div className='ButtonList'><CustomableButton label={"Multi Paiement"} className="multi" onClick={()=> setMultiPaiement(true)}/></div> : null}
                                {pendingUpdateFiche ? <div className="overlay"><Loader/></div> : null}
                            </div>
                            : null
                        ) 
                    ]
                }
            </div>
            {  numeroFiche == null && (montantPaye!=0 && totalVente + totalLocation == montantPaye || openModalSave) ? 
                <DialogWithLoading confirmText="Oui"
                    confirmCallback={enregistreFiche}
                    cancelText="Non"
                    cancelCallback={deleteLastPaiement}
                    loading={isLoadingModalSave}
                    setLoading={setIsLoadingModalSave}>
                    <span>{"Enregistrer la "+ type?.toLowerCase()}</span>
                </DialogWithLoading>
                : null
            }  
        </div>
    )
}

function ListeMoyenPaiements ({onClickButton}){

    const dispatch = useDispatch();
    const [listeMoyenPaiements, setListeMoyenPaiements] = useState(null);

    useEffect(() => {
        if (listeMoyenPaiements == null){
            getListePaiements()
            .then(res=> {
                setListeMoyenPaiements(res);
            })
            .catch(error =>{
                setListeMoyenPaiements([]);
                console.log(error);
                dispatch(addErrorMessage({type:"error", message:"Impossible de charger la liste des moyens de paiement"}));
            });
        }
    }, [listeMoyenPaiements]);


    return (
        <div className='ButtonList'>
            {listeMoyenPaiements == null ? 
                <Loader width={50} height={50}/> :
                (
                    listeMoyenPaiements.length == 0 ? 
                        <button className="refreshButton" onClick={()=>setListeMoyenPaiements(null)}><RefreshIcon/></button> :
                        listeMoyenPaiements.map((paiement) => {
                            return <PaymentMethodButton key={paiement.Code} paiement={paiement} onClick={onClickButton}></PaymentMethodButton>
                        })
                )
            }
        </div>
    )
}


function LignePaiement ({paiement, onDelete}){

    const [modalOpen, setModalOpen] = useState(false);

    const onConfirmDelete = () => {
        setModalOpen(false);
        onDelete(paiement.id)
    }

    return (
        <div className="lignePaiement">
            {
                paiement.pending ? <Loader width={15} height={15}/> : <button onClick={() => setModalOpen(true)}><XIcon/></button>
            }
            <span> { paiement.libelle }</span>
            <span> { paiement.montant } &euro;</span>

            {modalOpen ? 
                <Dialog confirmText="Oui"
                    confirmCallback={onConfirmDelete}
                    cancelText="Non"
                    cancelCallback={() => setModalOpen(false)}>
                    <span>Supprimer le paiement ?</span>
                </Dialog>
                :null
            }   
        </div>
    )
}