import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuantites, updateQuantites } from "../api/articlesApi";
import {BlockTitle, Title} from "../components/Title";
import { addErrorMessage } from "../features/errorSlice";
import Loader from "../components/Loader";
import { SimpleTextInput } from "../components/Inputs";
import { GaugeIcon } from "../components/Icons";
import Filler from "../components/Filler";
import ListePacks from "../components/ListePacks";
import { StandardButton } from "../components/Buttons";

import '../styles/allotements.css'
import { VideoLink } from "../components/VideoLink";

export default function Allotements (){

    const [selectedPack, setSelectedPack] = useState(null);

    return (
        <div className="Allotements">
            <Title title="Gestion du parc" backTo="/gestion"><GaugeIcon/></Title>
            <div className="Block">
                <BlockTitle title="Mes catégories de location"/>
                <ListePacks onClickPack={setSelectedPack} selectedPack={selectedPack}/>
            </div>
            <div className="Block">
                {selectedPack == null ? 
                    <Filler text="Sélectionnez un article dans la liste pour modifier les quantités"/> :
                    <FormAllotement pack={selectedPack} setSelectedPack={setSelectedPack}/>
                }
            </div>
            <VideoLink link="https://www.youtube.com/embed/6TVap2xilYg?si=Kds2WI6xoc6JnSkq" libelle='allotements'/>
        </div>
    )
}

function FormAllotement ({pack, setSelectedPack}){

    const [quantites, setQuantites] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [globalValue, setGlobalValue] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        getQuantites(pack.id)
        .then(res=> {
            console.log(res);
            setQuantites(res.quantites);
            setIsLoading(false);
        })
        .catch(err =>{
            console.log(err);
            dispatch(addErrorMessage({type:"error", message:"Impossible de charger les données de l'article"}));
            setIsLoading(false);
        });

    }, [pack]);


    const enregistreQuantites = () => {
        let data = quantites.map((el)=> {
            return {
                semaine:el.semaine,
                valeur:el.quantite
            }
        });

        updateQuantites(pack.id, {quantites: data})
        .then(res=> {
            dispatch(addErrorMessage({type:"success", message:"Les quantités ont été mises à jour"}));
        })
        .catch(err =>{
            console.log(err);
            dispatch(addErrorMessage({type:"error"}));
        });

    }

   const updateAllQuantites = (e) => {
        let newValue = parseInt(e.target.value);
        if (isNaN(newValue) || newValue<0 || newValue>999) newValue = null;

        setGlobalValue(newValue);
        setQuantites(quantites.map (q => {return {...q , quantite:newValue}}))
   }

   const onChangeQuantiteSemaine = (index, quantite) => {
        let newQuantites = [...quantites];
        newQuantites[index] = {...quantites[index], quantite:quantite};

        setQuantites(newQuantites);
   }

    return (
        <div className="FormAllotement">
            {isLoading ?
                <Loader/> :
                <div>
                    <BlockTitle title={pack.nom}/>
                    <div className="buttons">
                        <StandardButton label="Enregistrer" onClick={enregistreQuantites}/>
                        <StandardButton label="Abandon" onClick={() => setSelectedPack(null)}/>
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Semaine</th>
                                <th>Quantité disponible</th>
                            </tr>
                            <tr>
                                <th>Appliquer pour toutes les semaines</th>
                                <th>
                                <SimpleTextInput 
                                    type='number'
                                    value={globalValue}
                                    onChange={updateAllQuantites}
                                    style={{width : '50px'}}/>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                quantites.map((sem, index)=>{
                                    return <LigneSemaine key={sem.semaine} semaine={sem} index={index} onChangeQuantiteSemaine={onChangeQuantiteSemaine}/>
                                })
                            }
                        </tbody> 
                    </table>
                </div>    
            }
        </div>
    )
}

function LigneSemaine ({semaine, index, onChangeQuantiteSemaine}){

    const onChangeValue = (e) => {
        let newValue = parseInt(e.target.value);
        if (isNaN(newValue) || newValue<0 || newValue>999) newValue = null;
        onChangeQuantiteSemaine(index, newValue);
    }

    return (
        <tr>
            <td>
                {'du ' + dayjs(semaine.debut).format('DD/MM') + ' au ' + dayjs(semaine.fin).format('DD/MM')}
            </td>
            <td>
                <SimpleTextInput 
                    type='number'
                    onChange={onChangeValue}
                    value={semaine.quantite == null ? "" : semaine.quantite}
                    style={{width : '50px'}}/>                
            </td>
        </tr>
    )
}
