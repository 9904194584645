import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { setConnections, setPopupNouveautes } from '../features/authSlice.js';
import { BackIcon, BikeIcon, BoxIcon, CaisseIcon, ClientIcon, ClipboardIcon, EditIcon, ExitIcon, FileIcon, GaugeIcon, LocIcon, PaymentMethodIcon, PiggyBank, PiggyBankIcon, PlanningIcon, SettingsIcon, SliderIcon, StatsIcon, TagIcon, TeddyBearIcon, TimeBackIcon, TruckIcon, VideoIcon } from "../components/Icons.js";
import { useEffect, useState } from "react";
import { getConnectionsInfo } from "../api/connectionsApi.js";
import { addErrorMessage } from "../features/errorSlice.js";
import { HomeFooter, HomeHeader } from "../components/HomeHeaderFooter.js";


import '../styles/home.css';
import Loader from "../components/Loader.js";
import { Modal } from "../components/Modals.js";
import { disablePopupNouveautes } from "../api/usersApi.js";
import { ToggleSwitch } from "../components/Inputs.js";


export default function Home ({section}){

    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    
    const connections = useSelector((state) => state.user.connections);
    const popupNouveautes = useSelector((state) => state.user.parametres.popupNouveautes);
    

    useEffect(() => {
        if (connections == null) {
            setIsLoading(true);
            getConnectionsInfo()
            .then(res => {
                if (!res.skilouApp) dispatch(addErrorMessage({type:"error", message:"Le magasin n'est pas connecté au serveur api, veuillez contacter votre maintenance"}));
                if (!res.mySkilou) dispatch(addErrorMessage({type:"error", message:"Le magasin n'est pas connecté au serveur dashboard, veuillez contacter votre maintenance"}));
               
                dispatch(setConnections({mySkilou:res.mySkilou, skilouApp:res.skilouApp}));
                setIsLoading(false);
            })
            .catch(error =>{
                console.log(error);
                setIsLoading(false);
                dispatch(addErrorMessage({type:"error"}));
            });
        }
    }, []);

    const getButtons = (section, connections) => {
        switch(section) {
            case "Caisse":
                return <CaisseHome connections={connections}/>
            case "GestionLocation":
                return <GestionLocationHome connections={connections}/>
            case "Planning":
                return <PlanningHome connections={connections}/>
            default:
                return <MainHome connections={connections}/>
        }
    }


    return (
        <div className='Home'>
            <div>
                <HomeHeader homeRoute='/'/>
                {
                    section != null ? 
                    <div className="homeBackButton">
                        <button onClick={() => navigate('/')}><BackIcon/></button>
                    </div>
                    :null
                }
            </div>
            {getButtons(section, connections)}
            {isLoading ?
                <div className="overlay"><Loader/></div>
                :null
            }
            {popupNouveautes ?<ModalNouveautes/> : null }
            <HomeFooter/>
        </div>
    )
}


function ModalNouveautes ({}) {

    const dispatch = useDispatch();

    const onCloseModalNouveautes = (disable) => {
        if (!disable) {
            dispatch(setPopupNouveautes({popupNouveautes:false}));
            return;
        }
        disablePopupNouveautes()
        .then(res => {
            dispatch(setPopupNouveautes({popupNouveautes:false}));
        })
        .catch(error =>{
            console.log(error);
        });
    }

    return (
        <Modal className="ModalNouveautes" width="70vw" height="90vh" closeCallback={() => onCloseModalNouveautes(false)}>
            <div className="content">
                <h2>{"Nextou version "+process.env.REACT_APP_NEXTOU_VERSION+" nouveautés"}</h2>
                <h3>Location :</h3>
                <ul>
                    <li>
                        <ClientIcon/>
                        <span>Un nouveau planning trié par client est désormais disponible. Retrouvez-le dans le menu "Consulter les plannings de location".</span>
                    </li>
                    <li>
                        <PlanningIcon/>
                        <span>Dans le planning matériel, une nouvelle coche vous permet de considérer le matériel rendu la veille du départ du client.</span>
                    </li>
                    <li>
                        <EditIcon/>
                        <span>Un nouveau bouton "éditer" vous permet de trier et filtrer les articles affichés lors de la création d'une location.</span>
                    </li>
                    <li>
                        <ClipboardIcon/>
                        <span>Vous avez désormais la possibilité de filtrer les articles affichés dans les prévisions de sorties.</span>
                    </li>
                </ul>
                <h3>Vente :</h3>
                <ul>
                    <li>
                        <EditIcon/>
                        <span>Comme pour la location, vous pouvez désormais trier les familles et articles affichés lors de la création d'une vente</span>
                    </li>
                </ul>
                <h3>Divers :</h3>
                <ul>
                    <li>
                        <SettingsIcon/>
                        <span>Un nouveau menu paramètres est disponible en cliquant sur votre nom d'utilisateur. Vous pouvez notamment y changer le thème couleur de votre interface Nextou.</span>
                    </li>
                    <li>
                        <VideoIcon/>
                        <span>Retrouvez en bas à droite de chaque menu une vidéo explicatives des fonctionnalités de la fenetre concernée.</span>
                    </li>
                </ul>

            </div>
            <div className="footer">
                <ToggleSwitch onChange={() => onCloseModalNouveautes(true)} label="Ne plus afficher ce message"/>
            </div>
        </Modal>
    )



}



function MainHome ({connections}) {

    return (
        <div className="buttonWrapper">
            <Link to={connections?.skilouApp ? '/vente': '#'} className={connections?.skilouApp ? 'vente': ' vente disabled'}>
                <TagIcon/>
                <span>Vendre des produits</span>
                <span className="short">Vendre</span>
            </Link>
            <Link to={connections?.skilouApp ? '/location': '#'} className={connections?.skilouApp ? 'loc': 'loc disabled'}>
                <LocIcon/>
                <span>Louer du matériel</span>
                <span className="short">Louer</span>
            </Link>
            <Link to={connections?.skilouApp ? '/caisse': '#'} className={(connections?.skilouApp ? '': ' disabled')}>
                <CaisseIcon/>
                <span >Gérer la caisse</span>
                <span className="short">Caisse</span>
            </Link>
            <Link to={connections?.mySkilou ? '/statistiques': '#'} className={connections?.mySkilou ? '': 'disabled'}>
                <StatsIcon/>
                <span>Consulter les statistiques</span>
                <span className="short">Statistiques</span>
            </Link>
            <Link to={connections?.skilouApp ? '/planning': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <PlanningIcon/>
                <span>Consulter les plannings de location</span>
                <span className="short">Plannings</span>
            </Link>
            <Link to={connections?.skilouApp ? '/stocks/etat': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <BoxIcon/>
                <span>Contrôler l'état des stocks</span>
                <span className="short">Etat des stocks</span>
            </Link>
            <Link to={connections?.skilouApp ? '/stocks/entree': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <TruckIcon/>
                <span>Saisir une entrée en stock</span>
                <span className="short">Entrée en stock</span>
            </Link>
            <Link to={connections?.skilouApp ? '/produits': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <TeddyBearIcon/>
                <span>Administrer les produits locaux</span>
                <span className="short">Produits locaux</span>
            </Link>
            <Link to={connections?.skilouApp ? '/gestion': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <SliderIcon/>
                <span>Gérer les locations</span>
                <span className="short">Location</span>
            </Link>
        </div>
    )
}

function CaisseHome ({connections}) {

    return (
        <div className="buttonWrapper caisse">
            <Link to={connections?.skilouApp ? '/caisse/edition': '#'} className={connections?.skilouApp ? 'edition': 'disabled'}>
                <FileIcon/>
                <span>Editer la caisse</span>
                <span className="short">Edition</span>
            </Link>
            <Link to={connections?.skilouApp ? '/caisse/correction': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <PaymentMethodIcon/>
                <span>Corriger la caisse</span>
                <span className="short">Correction</span>
            </Link>
            <Link to={connections?.skilouApp ? '/caisse/remises': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <PiggyBankIcon/>
                <span>Effectuer une remise en banque</span>
                <span className="short">Remise en banque</span>
            </Link>
        </div>
    )
}

function PlanningHome ({connections}) {


    return (
        <div className="buttonWrapper planning">
            <Link to={connections?.skilouApp ? '/planning/materiel': '#'} className={connections?.skilouApp ? 'edition': 'disabled'}>
                <BikeIcon/>
                <span>Planning par matériel</span>
                <span className="short">Matériel</span>
            </Link>
            <Link to={connections?.skilouApp ? '/planning/client': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <ClientIcon/>
                <span>Planning par client</span>
                <span className="short">Client</span>
            </Link>
        </div>
    )
}

function GestionLocationHome ({connections}) {

    return (
        <div className="buttonWrapper retours">
            <Link to={connections?.skilouApp ? '/previsions': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <ClipboardIcon/>
                <span>Télécharger les prévisions de sorties/retours</span>
                <span className="short">Prévisions</span>
            </Link>
            <Link to={connections?.skilouApp ? '/retours/manuels': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <TimeBackIcon/>
                <span>Effectuer un retour anticipé</span>
                <span className="short">Retour anticipé</span>
            </Link>
            <Link to={connections?.skilouApp ? '/articles/reduction': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <ExitIcon/>
                <span>Sortir un article du parc</span>
                <span className="short">Sortie du parc</span>
            </Link>
            <Link to={connections?.skilouApp ? '/articles/quantites': '#'} className={connections?.skilouApp ? '': 'disabled'}>
                <GaugeIcon/>
                <span>Définir les quantités disponibles</span>
                <span className="short">Quantités</span>
            </Link>
        </div>
    )
}