import errorHandler from "../utils/ErrorHandler";

export async function getUsers() {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/users', {
        method: 'GET',
        credentials: 'include',
    });

    if (response.status>=500) throw new Error("getUsers api error");
    let result = await response.json();
    result = errorHandler(result);
    if (!result) return [];
    return result.users;
}

export async function getUser(id, shops=0) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/users/'+id+'/'+shops, {
        method: 'GET',
        credentials: 'include',
    })

    if (response.status>=500) throw new Error("getUser api error");
    let result = await response.json();
    result = errorHandler(result);
    if (!result) return [];
    return result.user;
  
}
  
export async function createUser(user){
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/users/new', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user),
    });

    if (response.status>=500) throw new Error("createUser api error");
    let result = await response.json();
    return errorHandler(result);
}

export async function updateUser(userData){
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/users/update', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData),
    
    
    });

    if (response.status>=500) throw new Error("updateUser api error");
    let result = await response.json();
    return errorHandler(result);

}

export async function updateSettings(data){
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/users/settings', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    });

    if (response.status>=500) throw new Error("updateSettings api error");
    let result = await response.json();
    return errorHandler(result);
}
  

export async function updateCaisseSettings(data){
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/users/settings/caisse', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    });

    if (response.status>=500) throw new Error("updateCaisseSettings api error");
    let result = await response.json();
    return errorHandler(result);
}

export async function disablePopupNouveautes(){
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/users/settings/news', {
        method: 'POST',
        credentials: 'include',
    });

    if (response.status>=500) throw new Error("disablePopupNouveautes api error");
    let result = await response.json();
    return errorHandler(result);
}
  
  