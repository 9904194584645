import { useEffect, useState } from "react";
import { LockedInput, TextInput } from "../Inputs";
import { StandardButton, UnderlindedButton } from "../Buttons";

import '../../styles/gestionProduits.css';
import '../../styles/table.css';
import { useDispatch, useSelector } from "react-redux";
import { updateProduits } from "../../api/produitsApi";
import { addErrorMessage } from "../../features/errorSlice";
import Loader from "../Loader";

export default function ModificationProduit ({currentProduit, setCurrentProduit}){


    const role = useSelector((state) => state.user.role);
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({
        prixAchat: getPrixProduit(currentProduit, "prixAchatHT"),
        prixVente:  getPrixProduit(currentProduit, "prixTTC"),
        error: false,
    });

    const listeTailles = currentProduit.tailles.reduce(function(a, b){
        return a +" "+ b.libelle+",";
    }, "");

    const listeCouleurs = getListeCouleurs(currentProduit).reduce(function(a, b){
        return a +" "+ b.libelle+",";
    }, "");;
    
    const handleChange = (e) =>{
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const enregistrePrixProduit = () => {
        let prixAchat = parseFloat(parseFloat(formData.prixAchat).toFixed(2));
        let prixVente = parseFloat(parseFloat(formData.prixVente).toFixed(2));
        if (isNaN(prixAchat) || isNaN(prixVente)) {
            setFormData({...formData, error: true});
            return;
        }

        let produits = [];

        currentProduit.tailles.forEach(t => {
            t.couleurs.forEach(c => {
                produits.push(c.ean13);
            })
        })
        setIsLoading(true);
        updateProduits(role === "user", {produits: produits.map (p => {return {ean13: p, prixAchat: prixAchat, prixVente: prixVente}})})
        .then(res=>{
            console.log(res);
            if (res){
                dispatch(addErrorMessage({type:"success", message:"Le produit a été modifié avec succès"}));
                setCurrentProduit(null, false);
                //TODO : refresh la liste des produits locaux
            }
            setIsLoading(false);
        })
        .catch((err) => {
            console.log(err);
            dispatch(addErrorMessage({type:"error", message:"Une erreur s'est produite lors de la modification du produit, veuillez réessayer"}));
            setIsLoading(false);
        });

    }

    useEffect(() => {
        setFormData({
            prixAchat: getPrixProduit(currentProduit, "prixAchatHT"),
            prixVente:  getPrixProduit(currentProduit, "prixTTC")
        });

    }, [currentProduit]);

    return (
        [
            <div key="form">
                <div className="formModification">
                    <LockedInput
                        name="famille"
                        label="Famille"
                        className="famille"
                        value={currentProduit.famille}/>
                    <LockedInput
                        name="fournisseur"
                        label="Fournisseur"
                        className="fournisseur"
                        value={currentProduit.fournisseur}/>
                    <LockedInput
                        name="libelle"
                        label="Libellé"
                        className="libelle"
                        value={currentProduit.libelle}/>
                    <LockedInput
                        name="codeProduit"
                        label="Code Produit"
                        className="codeProduit"
                        value={currentProduit.code}/>
                    <LockedInput
                        name="tailles"
                        label="Tailles"
                        className="taille"
                        value={listeTailles.substring(0, listeTailles.length - 1)}/>
                    <LockedInput
                        name="couleurs"
                        label="Couleurs"
                        className="couleur"
                        value={listeCouleurs.substring(0, listeCouleurs.length - 1)}/>                
                    {/* <TextInput 
                        className={"prixAchat "+ (isNaN(formData.prixAchat) ? "error": null)} 
                        label="Prix d'achat HT" 
                        name="prixAchat"
                        value={formData.prixAchat}
                        onChange={handleChange}/>
                    <TextInput 
                        className={"prixVente "+ (isNaN(formData.prixVente) ? "error": null)} 
                        label="Prix de vente TTC" 
                        name="prixVente"
                        value={formData.prixVente}
                        onChange={handleChange}/>  */}
                    <LockedInput 
                        className={"prixAchat "+ (isNaN(formData.prixAchat) ? "error": null)} 
                        label="Prix d'achat HT" 
                        name="prixAchat"
                        value={formData.prixAchat}/>
                    <LockedInput 
                        className={"prixVente "+ (isNaN(formData.prixVente) ? "error": null)} 
                        label="Prix de vente TTC" 
                        name="prixVente"
                        value={formData.prixVente}/> 
                </div>
            </div>,
            <div key="footer" className="footer">
                {isLoading ? 
                    <Loader width={35} height={35}/> :
                    [
                        <UnderlindedButton key="abandon" label="Abandon" onClick={() => setCurrentProduit(null)}/>,
                        <StandardButton key="enregistrer" label="Enregistrer" onClick={enregistrePrixProduit}/>
                    ]
                }
                
            </div>
        ]
    )
}


const getListeCouleurs = (produit) => {
    let couleurs = [];

    produit.tailles.forEach((t) => {
        t.couleurs.forEach((c) => {
            if (couleurs.find((el) => el.code === c.code) == null){
                couleurs.push({
                    code:c.code,
                    libelle:c.libelle
                })
            }
        });
    });

    return couleurs;
}

function getPrixProduit (produit, typePrix) {
    let res = "";
    for (const t of  produit.tailles) {
        for (const c of t.couleurs) {
            if (res != "" && c[typePrix] != res) return "";
            res = c[typePrix];
        }
    }
    return res;
}
