import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getShopsStatus } from "../../api/shopsApi";
import { addErrorMessage } from "../../features/errorSlice";
import { ConnectionIcon, ShopIcon } from "../Icons";

import '../../styles/admin/connectionStatus.css';


export default function ConnectionStatusWidget () {

    const dispatch = useDispatch();
    const [data, setData] = useState([]);

    const nbShopsSkilouApp = data.filter(e => e.isConnectedSkilouApp).length;
    const nbShopsMySkilou = data.filter(e => e.isConnectedMySkilou).length;

    useEffect(() => {
        getShopsStatus(0)
        .then((res) => {
            setData(res);
        })
        .catch(error =>{
            setData([]);
            dispatch(addErrorMessage({type:"error"}));
        });
    }, [])

    return (
        <div className="Block ConnectionStatus">
            <h3>
                <ShopIcon/>
                <span>Magasins connectés</span>
            </h3>
            <div>
                <div className="connection">
                    <img src='NEXTOU_icon_violet.png'/>
                    <span>API SkilouApp, magasins connectés : </span>
                    <span>{nbShopsSkilouApp} / {data.length}</span>
                    
                </div>
                {nbShopsSkilouApp !== data.length ?
                    <div className="errors">
                        <span>magasins déconnectés : </span>
                        <span>{data.length - nbShopsSkilouApp}</span>
                    </div>
                    :null
                }
            </div>
            <div>
                <div className="connection">
                    <img src='SKILOU_icon.png'/>
                    <span>API MySkilou, magasins connectés : </span>
                    <span>{nbShopsMySkilou} / {data.length}</span>
                </div>
                {nbShopsMySkilou !== data.length ?
                    <div className="errors">
                        <span>magasins déconnectés : </span>
                        <span>{data.length - nbShopsMySkilou}</span>
                    </div>
                    :null
                }
            </div>
        </div>
    )

}