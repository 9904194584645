import { EChart } from "./EChart";


export function LineChart ({series, xAxisValues, style}){


  const tooltip = {trigger: 'axis'}; 
  const fontSize = 12;

  style = {
      ...style,
      width:'96%'
  }

  let options = {
    tooltip: tooltip,
    legend: {
        data: series.map (el => el.name)
    },
    grid: {
        left: 10,
        top: 50,
        right: 10,
        bottom: 10,
        containLabel:true,
    },
    xAxis: {
        type: 'category',
        data: xAxisValues,
        axisLabel:{
            rotate:45,
            fontSize: fontSize
        }
    },
    yAxis: {
        type: 'value',
        axisLabel:{
            fontSize: fontSize
        }
    },
    series: 
    series.map ((s) => {
        return {
            name: s.name,
            data: s.data,
            lineStyle: {
                color: s.color,
                type: s.type
            },
            itemStyle: {
                color: s.color,
            },
            type: 'line',
            smooth:true,
        }
    })

  }

  return  (
      <EChart option={options} style={style}/> 
  )

}

// export function LineChart ({data, mainColor, style, title}){

//     let colorTheme = getChartColorTheme(mainColor);

//     const tooltip = {trigger: 'axis'}; 
//     const fontSize = 12;

//     style = {
//         ...style,
//         width:'96%'
//     }
  
//     let options = {
//         tooltip: tooltip,
//         grid: {
//             left: 10,
//             top: 10,
//             right: 10,
//             bottom: 10,
//             containLabel:true,
//         },
//         xAxis: {
//           type: 'category',
//           data: data.map(el => el.name),
//           axisLabel:{
//             rotate:45,
//             fontSize: fontSize
//           }
//         },
//         yAxis: {
//           min: Math.floor(Math.min(...data.map(el => Math.min(el.valueN, el.valueN_1)))*0.9),
//           type: 'value',
//           axisLabel:{
//             fontSize: fontSize
//           }
//         },
//         series: [
//           {
//             name:'N',
//             data: data.map(el => el.valueN),
//             lineStyle: {
//                 color: colorTheme.colorN,
//             },
//             itemStyle: {
//                 color: colorTheme.colorN,
//             },
//             type: 'line',
//             smooth:true
//           },
//           {
//             name: 'N-1',
//             data: data.map(el => el.valueN_1),
//             lineStyle: {
//                 color: colorTheme.colorN_1,
//             },
//             itemStyle: {
//                 color: colorTheme.colorN_1,
//             },
//             type: 'line',
//             smooth:true

//           }
//         ]
//     }
  
//     return  (
//         <div className="BarChart">
//           <div style={{color:colorTheme.colorN}}>{title}</div>
//           <div>
//               <span style={{color:colorTheme.colorN}}>N</span><span style={{color:colorTheme.colorN_1}}>N-1</span>
//           </div>
//           <EChart option={options} style={style}/> 
//         </div>
//     )
  
// }