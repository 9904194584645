import { useState } from "react";
import { Title } from "../Title";
import ListeProduits from "./ListeProduits";
import Filler from "../Filler";
import CreationProduit from "./CreationProduit";
import ModificationProduit from "./ModificationProduit";
import { Dialog } from "../Modals";
import { TeddyBearIcon } from "../Icons";

export default function MainGestionProduits ({produitsLocaux, children}){


    const [currentProduit, setCurrentProduit] = useState(null);
    const [tmpProduit, setTmpProduit] = useState(false);

    const changeCurrentProduit = (newVal, warning=true) => {
        console.log(newVal);
        if (currentProduit === 'create' && warning) {
            setTmpProduit(newVal);
            return;
        }
        setTmpProduit(false);
        setCurrentProduit(newVal);
    }
    
    return (
        <div className='GestionProduits'>
            <Title title="Administration des produits"><TeddyBearIcon/></Title>
            <ListeProduits produitsLocaux={produitsLocaux} setCurrentProduit={changeCurrentProduit} currentProduit={currentProduit}/>
            <div className="Block formProduit">
                {currentProduit == null ? 
                    <Filler text={"Sélectionner un produit dans la liste pour le modifier ou créer un nouveau produit" +( produitsLocaux === 1 ? " local": "")} /> :
                    currentProduit === "create" ?
                        <CreationProduit setCurrentProduit={changeCurrentProduit}/> :
                        <ModificationProduit currentProduit={currentProduit} setCurrentProduit={changeCurrentProduit}/>
                }
            </div>
            {tmpProduit !== false ?
                <Dialog 
                    confirmText="Oui"
                    confirmCallback={() => {setCurrentProduit(tmpProduit); setTmpProduit(false)}}
                    cancelText="Non"
                    cancelCallback={() => setTmpProduit(false)}>
                    <span>{"Abandonner les modifications ?"}</span>        
                </Dialog>
                : null
            }  
            {children}
        </div>
    )
}