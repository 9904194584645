import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUsers } from "../../api/usersApi";
import { BlockTitle, Title } from "../../components/Title";
import { addErrorMessage } from "../../features/errorSlice.js";
import { SelectInput } from "../../components/Inputs.js";
import { getShops } from "../../api/shopsApi.js";
import ChiffreAffairesCumul from "../../components/statistiques/ChiffreAffairesCumul.js";
import dayjs from "dayjs";
import { StatsIcon } from "../../components/Icons.js";

export default function ChiffreAffairesCumulDetail () {

    const [shops, setShops] = useState(null);
    const [selectedShop, setSelectedShop] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        getShops().then(res=>{
            if (res) setShops(res.sort((a,b) => a.name >b.name ? 1 : -1));
            setSelectedShop(0);
        }).catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error", message: "Impossible de charger la liste des utilisateurs"}));
        });

    }, []);

    const onChangeShop = (e) => {
        setSelectedShop(e.target.value);
    }
      

    return (
        <div className='ChiffreAffairesCumulDetail'>
            <Title title="Chiffre d'affaires cumulé"><StatsIcon/></Title>   
            <div className='Block'>
                {shops ?
                    <div>
                        <SelectInput 
                            withEmptyOption={false}
                            value={selectedShop}
                            onChange={onChangeShop}
                            options={[{name:"tous", value:0}, ...shops.map(el => {return {name: el.name, value: el.id}})]}/>
                        
                        <ChiffreAffairesCumul date={dayjs().format('YYYY-MM-DD')} id={selectedShop} chartHeight={'700px'}/>
                       
                    </div>
                    : null
                }
            </div>                    
        </div>
    )
}


