import { useState, useEffect } from 'react';
import Loader from '../../components/Loader.js';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { addErrorMessage } from '../../features/errorSlice.js';
import { StandardButton } from '../../components/Buttons.js';
import { getLots } from '../../api/lotsApi.js';
import { Title } from '../../components/Title.js';
import { GroupIcon } from '../../components/Icons.js';


export default function LotList (){

    const [lots, setLots] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => { 
        getLots()
        .then(res=>{
            setLots (res);
            
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
        });
        setIsLoading(false);

    }, []);

    
    return ( 
        <div className='ItemList'>
            <Title title="Liste des lots" backTo="/admin"><GroupIcon/></Title>
            <div className='Block'>
                <div className='header'>
                    <StandardButton label="Créer un lot" onClick={() => navigate('new')}/>
                </div>
                {isLoading ?
                    <Loader></Loader> :
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Nom</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                lots.map((lot) => {
                                    return <LotRow key={lot.id} lot={lot}></LotRow>
                                })
                            } 
                        </tbody>
                    </table> 
                }
            </div>
        </div> 
    )
}


function LotRow ({lot}) {

    return (
       <tr className='ItemRow'>
            <td>{lot.id}</td>
            <td>
                <Link to={'/admin/lots/'+lot.id}>{lot.name}</Link>
            </td>
       </tr>
     )
 }
 