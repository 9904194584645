export function LigneArticleReservation ({article}) {
 
    return (
        <tr className="LignePanier">
            <td>{article.Code_cat}</td>
            <td>{article.Libelle_pack}</td>
            <td>{article.Date_debut}</td>
            <td>{article.Date_fin}</td>
            <td>{article.Nb_Jours}</td>
        </tr>
     )
 }