import { useState } from "react";
import { TagIcon, ChevronIcon } from "../Icons";
import { LigneProduit } from "./LigneProduit";
import { useDispatch, useSelector } from "react-redux";
import Loader from '../Loader';
import { addPendingProduit } from "../../features/ficheSlice";
import ListProduitButtons from "../ListProduitButtons";
import { BlockTitle } from "../Title";

export function Vente({socket}) {

    const produits = useSelector((state) => state.fiche.produits);
    const [isOpen, setIsOpen] = useState(true);

    const totalVente = produits.reduce((total, product) => (total = total + product.prixTotal),0);

    const dispatch = useDispatch();

    const addProduit = (produit) => {
        dispatch(addPendingProduit());
        socket.current.emit("addProduit", {ean13:produit.ean13});
    }

    return (
        <div className='Block Vente'>
            <BlockTitle title={"Vente"} onClick={() => setIsOpen(!isOpen)} icon={<TagIcon/>}>
                {totalVente > 0 ? <span>{totalVente.toFixed(2)}&euro;</span> : null}
                <button tabIndex={-1} className={isOpen ? 'open':'close'}><ChevronIcon/></button>
            </BlockTitle>
  
            <div style={{display : isOpen ? "block":"none"}}>
                <ListProduitButtons onSelectCouleur={addProduit} alerteStock0={true}/>
                <PanierVente socket={socket}/>
            </div>
        </div>
    )
}

function PanierVente ({socket}) {

    const produits = useSelector((state) => state.fiche.produits);
    const pendingProduits = useSelector((state) => state.fiche.pendingProduits);

    return(
        <table className='Panier Vente'>
        <thead>
            <tr>
                <th className="code"><span>Code</span></th>
                <th className="lib"><span>Libellé</span></th>
                <th className="stk"><span>Stock</span></th>
                <th>Taille</th>
                <th className="coul"><span>Couleur</span></th>
                <th className="pu"><span>Prix Unit.</span></th>
                <th className="qte"><span>Quantité</span></th>
                <th className="rem"><span>Remise</span></th>
                <th><span>Total</span></th>
                <th></th>
            </tr>
        </thead>

        <tbody>
            {pendingProduits>0 ? Array(pendingProduits).fill(true).map((_, i) => <tr key={i}><td colSpan={10}><Loader height={20} width={20}/></td></tr>):null}

            {produits.slice().reverse().map((produit) => {
                return <LigneProduit key={produit.indice} produit={produit} socket={socket}></LigneProduit>
            })} 
        </tbody>
    </table>
    )
}