import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DateTimeInput, SelectInput } from "../components/Inputs";
import { StandardButton } from "../components/Buttons";
import { correctionCaisse, listeActionsCaisse } from "../api/caisseApi";
import { useDispatch } from "react-redux";
import { addErrorMessage } from "../features/errorSlice";
import { CaisseIcon } from "../components/Icons";
import Filler from "../components/Filler";
import { ErrorTooltip } from "../components/Tooltips";
import { Title } from "../components/Title";
import { getListePaiements } from "../api/paiementsApi";
import { Dialog } from "../components/Modals";


import '../styles/caisse.css';
import '../styles/correctionCaisse.css';
import { VideoLink } from "../components/VideoLink";
import { useSmallDevice } from "../app/hooks";

export default function CorrectionCaisse (){

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [actions, setActions] = useState(null);
    const [paiements, setPaiements] = useState([]);

    const [formData, setFormData] = useState({
        datesError : "",
        dateDebut: dayjs().hour(0).minute(1),
        dateFin: dayjs().hour(23).minute(59),
    })

    
    const onClickEditeButton = () => {

        let today = dayjs();
        if(formData.dateDebut>today || formData.dateDebut>formData.dateFin){
            setFormData({...formData, datesError: "Les dates saisies sont invalides"});
            return;
        }

        if(formData.dateFin.diff(formData.dateDebut, 'days') > 7){
            setFormData({...formData, datesError: "Impossible de rechercher sur une période de plus de 7 jours"});
            return;
        }

        setLoading(true);

        getListeActions();
    }

    const handleDateDebutChange = (val) => {
        setFormData({
            ...formData,
            dateDebut:val,
            datesError:""
        })
    }

    const handleDateFinChange = (val) => {
        setFormData({
            ...formData,
            dateFin:val,
            datesError:""
        })
    }

    const getListeActions = () => {
        setActions(null)
        if (formData.dateDebut == null || formData.dateFin == null) return;
        console.log(formData);
        listeActionsCaisse({
            dateDebut : formData.dateDebut.format('YYYY-MM-DD HH:mm'),
            dateFin : formData.dateFin.format('YYYY-MM-DD HH:mm')
        })
        .then((res) =>{
            setActions(res.actions);
            setLoading(false);
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error", message:"Impossible de charger la liste des encaissements"}));
            setLoading(false);
        });
    }

    useEffect(() => {
        getListePaiements() 
        .then(res=> {
            setPaiements(res);
        })
        .catch(error =>{
            setPaiements([]);
            console.log(error);
            dispatch(addErrorMessage({type:"error", message:"Impossible de charger la liste des moyens de paiement"}));
        });
    }, []);

    return (
        <div className='CorrectionCaisse'>
            <Title title="Correction de caisse" backTo="/caisse"><CaisseIcon/></Title>
            <div className="Block filtres">
                <div className="filtresColumn">
                    <ErrorTooltip title={formData.datesError} open={true} placement={useSmallDevice() ? "bottom" : "right"}>
                        <h3>Lister les encaissements :</h3>
                    </ErrorTooltip>
                    <div>
                        <span>du&nbsp;:&nbsp;</span>
                        <DateTimeInput 
                            name="dateDebut" 
                            value={formData.dateDebut} 
                            onChange={handleDateDebutChange} 
                            style={{margin:'0 auto'}} 
                            maxDate={dayjs()}/>
                    </div>
                    <div>
                        <span>au&nbsp;:&nbsp;</span> 
                        <DateTimeInput name="dateFin" value={formData.dateFin} onChange={handleDateFinChange} style={{margin:'0 auto'}} maxDate={dayjs()}/>
                    </div>
                </div>
                <div className="filtresColumn">
                    <StandardButton label="Rechercher" isLocked={loading} onClick={onClickEditeButton}/>
                </div>
                
            </div>
            <div className="Block">
                <div className="frame">
                    {
                        actions == null ?
                        <Filler 
                            loading={loading}
                            text="Sélectionner une plage de dates pour rechercher les encaissements effectués">
                        </Filler> 
                        : <ListeActions actions={actions} paiements={paiements} getListeActions={getListeActions}/>
                    }
                </div>
            </div>
            <VideoLink link="https://www.youtube.com/embed/4rIKbTpcdAA?si=bnKLeMflr7m0kyXq" libelle='correctionCaisse'/>
        </div>
    )
}


function ListeActions ({actions, paiements, getListeActions}){

    const [editAction, setEditAction] = useState(null);

    return (
        actions.length>0 ?
            <table className="table ListeActions">
                <thead>
                    <tr>
                        <th className="code">Code</th>
                        <th>Code Transaction</th>
                        <th>Date</th>
                        <th>
                            <span>Montant</span>
                            <span className="short">Mnt.</span>
                        </th>
                        <th>
                            <span>Mode de paiement</span>
                            <span className="short">Mode de p.</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        actions.map (a => {
                            return <LigneAction key={a.Code} action={a} editAction={editAction} setEditAction={setEditAction} paiements={paiements} getListeActions={getListeActions}/>
                        })
                    }               
                </tbody>
            </table>
            :
            <Filler 
                loading={false}
                text="Aucun encaissement n'a été effectué à ces dates">
            </Filler>
    )
}


function LigneAction ({action, editAction, setEditAction, paiements, getListeActions}){

    
    let [newPaiement, setNewPaiement] = useState(action.CodePaiement);

    let dispatch = useDispatch();
    let montant = action.Credit_caisse > 0 ? "+"+action.Credit_caisse : "-"+action.Debit_caisse;
    let devise = action.Devise === 'Euro' ? String.fromCodePoint(8364) : action.Devise;

    const onChangePaiement = (e) => {
        setNewPaiement(e.target.value);
    }

    const cancelEnregistrePaiement = (e) => {
        setNewPaiement(action.CodePaiement);
        
    }

    const confirmEnregistrePaiement = (e) => {
        if (newPaiement == null || newPaiement == "") return;
        correctionCaisse({action:action.Code, paiement:newPaiement})
        .then(res=> {
            if (res) {
                setEditAction(null);
                getListeActions();
                dispatch(addErrorMessage({type:"success", message:"L'encaissement a été modifié avec succès"}));
            }
        })
        .catch(error =>{
            setNewPaiement(action.CodePaiement);
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
        });
        
    }

    return (
        <tr>
            <td className="code">{action.Code}</td>
            <td>{action.CodeTransaction}</td>
            <td>{dayjs(action.Date_action).format('DD/MM hh:mm')}</td>
            <td>{montant+" "+devise}</td>
            {editAction === action.Code ?
                <td>
                    <SelectInput
                        value={newPaiement}
                        withEmptyOption={false}
                        onChange={onChangePaiement}
                        options={paiements.map((el)=> { return {value:el.Code, name:el.Libelle}})} />
                    
                    {newPaiement !== action.CodePaiement ? 
                        <Dialog
                            confirmText="Oui"
                            cancelText="Non"
                            cancelCallback={cancelEnregistrePaiement}
                            confirmCallback={confirmEnregistrePaiement}
                            >
                            <span>Modifier le mode de paiement de cet encaissement ?</span>
                        </Dialog>
                        : null
                    }
                </td> 
                :
                <td className="paiement" onClick={() => setEditAction(action.Code)}>
                    <span>{action.Paiement}</span>
                    <span className="short">{action.CodePaiement}</span>
                </td>
            }
        </tr>
    )
}