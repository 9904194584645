import errorHandler from "../utils/ErrorHandler";

export async function controleCaisse() { //TODO : revoir 
    let json = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/caisse/controle', {
        method: 'GET',
        credentials: 'include',
    })
    .then(response => { 
        if (response.status>=500){
            throw new Error ("Erreur controleCaisse");
        }
        return response.json();
    })
    .catch(err=>{
        throw new Error (err);
    })

    return json;
}


export async function editeCaisse(params) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/caisse/edition', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
    })

    if (response.status>=500) throw new Error("editeCaisse api error");
    let result = await response.blob();
    return result;

}

export async function listeActionsCaisse(params) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/caisse/actions', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
    });

    if (response.status>=500) throw new Error("listeActionsCaisse api error");
    let result = await response.json();
    return errorHandler(result);

}

export async function correctionCaisse(params) {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/caisse/correction', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
    });

    if (response.status>=500) throw new Error("correctionCaisse api error");
    let result = await response.json();
    return errorHandler(result);

}