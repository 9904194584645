import { Link } from "react-router-dom";
import { EuroIcon, HelpIcon, SliderIcon, TeddyBearIcon } from "../../components/Icons.js";



import '../../styles/home.css';
import { HomeFooter, HomeHeader } from "../../components/HomeHeaderFooter.js";


export default function SupervisorHome (){

    return (
        <div className='Home Supervisor'>
            <HomeHeader/>
            <div className="buttonWrapper">
                <Link to={'produits'} className="produits">
                    <TeddyBearIcon/>
                    <span></span>
                </Link>  
                <Link to={'prix'} className="prix">
                    <EuroIcon/>
                    <span></span>
                </Link>   
                <Link to={'shops'} className="shops">
                    <SliderIcon/>
                    <span></span>
                </Link>   
                <Link to={'aide'} className="aide">
                    <HelpIcon/>
                    <span></span>
                </Link>       
            </div>
            <HomeFooter/>
        </div>
    )
}