import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { createUser, getUser, updateUser } from '../../api/usersApi.js';
import Loader from '../../components/Loader.js';
import { checkPasswordStrength, generatePassword } from '../../utils/Commons.js';
import { useDispatch } from 'react-redux';
import { TextInput, PasswordInput, LockedInput, SelectInput } from '../../components/Inputs.js';
import { StandardButton } from '../../components/Buttons.js';
import { addErrorMessage } from '../../features/errorSlice.js';
import { getShops } from '../../api/shopsApi.js';
import { FilledCheckIcon, ClientIcon } from '../../components/Icons.js';
import { BlockTitle, Title } from '../../components/Title.js';
import { ErrorTooltip } from '../../components/Tooltips.js';

import '../../styles/user.css';

export default function User () {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const themes = ['blue', 'purple', 'green', 'yellow'];

    const [isLoading, setIsLoading] = useState(false);
    const [shopList, setShopList] = useState([]);
    const [user, setUser] = useState({
        id: null,
        username: "",
        role: "",
        theme: "blue",
        shop: null,
        shopsStats: [],
        password: "",
        password2: "",
        error: null
    });

    useEffect(() => { 
        getShops()
        .then(res=>{
            setShopList(res);
        })
        .catch(error =>{
            console.log(error);
            dispatch(addErrorMessage({type:"error"}));
        });
        if(id !== 'new'){
            getUser(id, 1)
            .then(res=>{
                if (res) setUser({
                    ...user,
                    id: res.id,
                    username: res.username,
                    role: res.role,
                    theme: res.theme,
                    shop: res.shop,
                    shopsStats: res.shopsStats,
                });
            })
            .catch(error =>{
                console.log(error);
                dispatch(addErrorMessage({type:"error"}));
            });
        }
        else setUser({...user, id:'new', role:"user"});

    }, []);


    const handleChange = e =>{
        const { name, value } = e.target;
        let newUser = {...user, [name]: value, error:null};
        setUser(newUser);
    }   

    const saveChanges = () => {
        if (user.username === "" || (user.id === "new" && (user.password === "" || user.password2 === "" || user.shop === ""))){
            setUser({...user, error: 'Veuillez renseigner les champs manquants'});
            return;
        }

        if (user.password !== "") {
            if(!checkPasswordStrength(user.password)){
                setUser({...user, error: 'Le mot de passe saisi n\'est pas valide'});
                return;
            }

            if (user.password !== user.password2){
                setUser({...user, error: 'Les mots de passe saisis ne sont pas identiques'});
                return;
            }
        }

        setIsLoading(true);
        if (user.id === 'new') {
            createUser({
                username: user.username,
                password: user.password,
                shopId: user.shop,
                theme: user.theme
            })
            .then(res=>{
                if (res) {
                    dispatch(addErrorMessage({type:"success", message:"L'utilisateur a été crée avec succès"}));
                    navigate('/admin/users');
                }
                
            }).catch(error =>{
                console.log(error);
                dispatch(addErrorMessage({type:"error"}));
                setIsLoading(false);
            });  
        }
        else {
            updateUser({
                id: user.id,
                password: user.password === "" ? "" : user.password,
            })
            .then(res=>{
                if (res) {
                    dispatch(addErrorMessage({type:"success", message:"Les données de l'utilisateur ont été mises à jour avec succès"}));
                    navigate('/admin/users');
                }
                
            }).catch(error =>{
                console.log(error);
                dispatch(addErrorMessage({type:"error"}));
                setIsLoading(false);
            });  
        }
        
    }

    const onClickGeneratePassword = () => {
        let password = generatePassword(16);
        setUser({...user, password:password, password2:password});
    }

    return ( user.id === null  ?
        <Loader/>
        : 
        <div className={'User ' + (user.role === "supervisor" ? "Supervisor" : "")}>
            <Title title="Utilisateur" backTo='/admin/users'><ClientIcon/></Title>
            <div className='Block'>
                <div className='form'>
                    {user.id !== 'new' ? <LockedInput className="id" label="Id" value={user.id}/> : null}
                    {user.id !== 'new' ? <LockedInput className="role" label="Role" value={user.role}/> : null}
                    {user.id === 'new' ?
                        <TextInput className="username" label={'Nom d\'utilisateur'} name={'username'} value={user.username} onChange={handleChange} required={true}/>
                        : <LockedInput className="username" label={'Nom d\'utilisateur'} value={user.username}/>

                    }
                    {user.id === 'new' ?
                        <SelectInput label='Thème' className='theme' name='theme' options={themes.map(el=> {return{value:el, name:el}})} onChange={handleChange} withEmptyOption={false} value={user.theme}/>
                        : <LockedInput label='Thème' onChange={handleChange} value={user.theme}/>
                    }
                    <PasswordInput className="password" label={'Mot de passe'} name={'password'} value={user.password} onChange={handleChange} required={user.id ==='new'}/>
                    <div className='check'>
                        {checkPasswordStrength(user.password) ? <FilledCheckIcon/> : null}
                    </div>
                    <div>
                        <StandardButton onClick={onClickGeneratePassword} label="Générer"/>
                    </div>
                    <PasswordInput label={'Confirmation'} name={'password2'} value={user.password2} onChange={handleChange} required={user.id ==='new'}/>
                    <div className='check'>
                        {checkPasswordStrength(user.password) && user.password === user.password2 ? <FilledCheckIcon/> : null}
                    </div>
                    {user.id === "new" ? 
                        <SelectInput className='magasin' label={'Magasin'} name={'shop'} options={shopList.map(el=> {return{value:el.id, name:el.name}})} value={user.shop} onChange={handleChange} required={true}/>:
                        <LockedInput className='magasin' label={'Magasin'} value={shopList.find(el=> el.id === user.shop)?.name}/>
                    }
                </div>
                <div className='submitForm'>
                    {isLoading ? 
                        <Loader width={35} height={35}/> : 
                        <ErrorTooltip title={user.error} open={user.error != null} placement="left">
                            <div>
                                <StandardButton label="Enregistrer" onClick={saveChanges}/>
                            </div>
                        </ErrorTooltip>
                    }
                </div>
            </div>
            {user.role === 'supervisor' ?
                <SupervisorShopStats user={user}/>
                :null
            }            
        </div>
    )
}


function SupervisorShopStats ({user}) {
    return (
        <div className='Block SupervisorShopStats'>
            <BlockTitle title="Magasins Rattachés"/>
            <div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Nom</th>
                        </tr>
                    </thead>
                    <tbody>
                        {user.shopsStats.map((s)=>{
                            return <ListRow key={s.id} shop={s}/>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

function ListRow ({shop}) {

    return (
       <tr className='ItemRow'>
            <td>{shop.id}</td>
            <td>
                <Link to={'/admin/shops/'+shop.id}>{shop.name}</Link>
            </td>
       </tr>
     )
 }
 

