import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorTooltip } from "../Tooltips.js";
import { isErrorCBString, isCBString, getCBInfo, formatCardNumber, formatDateExp } from "../../utils/CB.js";
import { ChevronIcon, ClientIcon } from "../Icons.js";
import { LockedInput, SimpleTextInput, TextAreaInput, TextInput } from "../Inputs.js";
import { StandardButton, UnderlindedButton } from "../Buttons.js"
import { addErrorMessage } from "../../features/errorSlice.js";
import { saveClientData, setReservation } from "../../features/ficheSlice.js";
import Loader from "../Loader.js";
import {getPublicKey} from '../../api/keyApi.js'
import {rsaEncrypt} from '../../utils/Commons.js'
import { Modal } from "../Modals.js";
import { getReservations } from "../../api/reservationsApi.js";
import dayjs from "dayjs";
import { BlockTitle } from "../Title.js";
import { VideoLink } from "../VideoLink.js";

import '../../styles/clientLocation.css';

export function ClientLocation({socket}) {

    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [showReservations, setShowReservations] = useState(false);
    const reservation = useSelector((state) => state.fiche.reservation);
    const articles = useSelector((state) => state.fiche.articles);

    const [formData, setFormData] = useState({
        nom: "",
        prenom: "",
        emplacement: "",
        nc: "",
        date: "",
        telephone: "",
        postIt: "",
        codeControle: "",
        error: false,
    });

    const emplacementInputRef = useRef(null);

    const isButtonSaveLocked = reservation != null && Object.entries(formData).filter(el => el[0] !='nom' && el[0] != 'prenom' && el[1] != "").length == 0;

    const resetFormData = () => {
        if (reservation != null) dispatch(setReservation({reservation:null}));

        setFormData({
            nom: "",
            prenom: "",
            emplacement: "",
            nc: "",
            date: "",
            telephone: "",
            postIt: "",
            codeControle: "",
        });

        dispatch(saveClientData({client:null}));
    }


    async function handleSubmit() {
        if (formData.nom === "" || formData.prenom === "" || formData.emplacement === ""){
            setFormData({...formData, error:true});
            return;
        }

        let infoCB = null;
        if (window.crypto.subtle != null) {
            if (formData.nc !== "" || formData.date !== "") {
                if (formData.nc === "" || formData.date  === "") {
                    setFormData({...formData, error:true});
                    return;
                }

                try {
                    let key = await getPublicKey();
                    if (key) {
                        infoCB = await rsaEncrypt(key.key, JSON.stringify({Numero:formData.nc, Date:formData.date, CodeControle:formData.codeControle }));
                    }
                }
                catch (err) {
                    dispatch(addErrorMessage({type:"error", message:"Erreur lors de l'enregistrement des données de carte"}));
                }
            }
        }

        setIsLoading(true);
        socket.current.emit("enregistreClientLocation", {
            idResa: reservation?.IdResa,
            nom: formData.nom,
            prenom: formData.prenom,
            emplacement: formData.emplacement,
            telephone: formData.telephone,
            postIt: formData.postIt,
            infoCB: infoCB
        });
    }

    const handleChange = e =>{
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value, error:false });
    }

    
    function handleDateExpChange(event){
        setFormData({...formData, date : formatDateExp(event.target.value), error:false});  
    }

    function handleNumeroCarteChange(event){
        if (!event.target.value.startsWith('%')) {
            event.target.value = formatCardNumber(event.target.value);
        }
        setFormData({...formData, nc: event.target.value, error:false});
    }

    const onReceiveCBInfo = (infos) => {
        setFormData(
            { 
                ...formData, 
                nom: isCBString(formData.nom) ? infos.nom : formData.nom,
                prenom: formData.prenom == "" ? infos.prenom : formData.prenom,
                nc : infos.numeroCB,
                date : infos.dateExp,
                error : false
            }
        );  

        emplacementInputRef.current.focus();
    }

    const selectReservation = (reservation) => {
        setShowReservations(false);
        resetFormData();
        setFormData({
            ...formData,
            nom: reservation.Nom,
            prenom: reservation.Prenom,
            emplacement: reservation.Emplacement
        });
        if (reservation.Emplacement != "") {
            dispatch(saveClientData({client:{
                nom: reservation.Nom,
                prenom: reservation.Prenom,
                emplacement:reservation.Emplacement
            }}))
        }
        dispatch(setReservation({reservation:{...reservation, detail: []}}));
    }

    const onEnregistreClient = (response) => {
        console.log(response);
        setIsLoading(false);
        if (!response.success) dispatch(addErrorMessage({type:"error", message:response.message}));
        else {
            dispatch(saveClientData({client:response.client})); 
            if (!reservation) setOpen(false);
        }
    }

    useEffect(() => {
        socket.current.on("enregistreClientLocation", onEnregistreClient);
        return () => {
            socket.current.off("enregistreClientLocation", onEnregistreClient);
        }
    }, []);
    
    return (
        <div className='Block ClientLocation'>
            <BlockTitle 
                title={"Client " + (reservation?.Num_interloc ? reservation.Num_interloc : "")} 
                onClick={() => setOpen(!open)}
                icon={<ClientIcon/>}>
                    <button tabIndex={-1} className={open ? 'open':'close'}><ChevronIcon/></button>
            </BlockTitle>
            {open ?
                <div className='form'>
                    <div>
                        {reservation ?
                            <LockedInput className='nom' label='Nom' name='nom' value={reservation.Nom}/>
                            :<TextInputCardReader className='nom' label='Nom' name='nom' value={formData.nom} required={true} handleChange={(e) => setFormData({...formData, nom: e.target.value})} onReceiveCBInfo={onReceiveCBInfo} tabIndex={1} autoFocus={true}/>
                        }
                        {reservation ? 
                            <LockedInput className='prenom' label='Prénom' name='prenom' value={reservation.Prenom}/>
                            : <TextInput className='prenom' label='Prénom' name='prenom' value={formData.prenom} onChange={handleChange} tabIndex={2} required="required"/>
                        }
                        {window.crypto.subtle ? 
                            [
                                <TextInputCardReader key='nc' className='nc' label='Numéro de carte' name='nc' value={formData.nc} required={formData.date !== ""} handleChange={handleNumeroCarteChange} onReceiveCBInfo={onReceiveCBInfo}/>,
                                <TextInput key='date' className='date' label={'Date d\'exp.'} name={'date'} value={formData.date} onChange={handleDateExpChange} required={formData.nc !== ""}/>,
                                <TextInput key='codeControle' className='codeControle' label={''} name={'codeControle'} value={formData.codeControle} onChange={handleChange}/>

                            ] : null
                        }
                        <TextInput className='emplacement' label='Emplacement' name={'emplacement'} value={formData.emplacement} onChange={handleChange} tabIndex={3} ref={emplacementInputRef} required="required"/>
                        
                        <TextInput className='telephone' label={'Téléphone'} name={'telephone'} value={formData.telephone} onChange={handleChange} type='tel'/>
                        {articles.length == 0 ? <div className="btnResa">
                            <StandardButton tabIndex={-1} label="Réservations" onClick={()=>{setShowReservations(true)}}/>
                            <VideoLink link='https://www.youtube.com/embed/XW3idEG2Xgg?si=boyI1rbKFXO5Bk0g' libelle='reservations' small={true}/>
                        </div> : null}
                    </div>
                    <div>
                        <div>
                            <TextAreaInput name="postIt" value={formData.postIt} onChange={handleChange}/>
                        </div>
                        {
                            isLoading ? 
                            <Loader width={35} height={35}/> :
                            <div className="footer">
                                {articles.length == 0 ? <UnderlindedButton label="Effacer" onClick={resetFormData}/> : null}
                                <ErrorTooltip title={"Veuillez renseigner les champs manquants"} open={formData.error} placement="left" arrow={false}>
                                    <span>
                                        <StandardButton tabIndex={4} label="Enregistrer" isLocked={isButtonSaveLocked} onClick={handleSubmit}/>
                                    </span>
                                </ErrorTooltip>
                            </div>
                        }

                        {showReservations ? <ListeReservations setShowReservations={setShowReservations} selectReservation={selectReservation}/> : null}
                    </div>
                </div>
                :null
            }
    
        </div>
    )
}

function TextInputCardReader ({className, label, name, value, required, handleChange, onReceiveCBInfo, autoFocus, tabIndex}) {

    const dispatch = useDispatch();

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            onConfirmValue(e); 
        } 
    }

    function onConfirmValue(event) {
        console.log("confirm");
        let newValue = event.target.value;
        console.log(event.target.value);
        if (isErrorCBString(newValue)){
            dispatch(addErrorMessage({type:"error", message:"Erreur lors de la lecture de la carte."}));
            event.target.value = "";
            handleChange(event);
            return;
        }
        if (!isCBString(newValue)) {
            handleChange(event);
            return;
        }
        let infos = getCBInfo(newValue);
        if (infos == null) {
            dispatch(addErrorMessage({type:"error", message:"Erreur lors de la lecture de la carte"}));
            event.target.value = "";
            handleChange(event);
            return;
        }

        onReceiveCBInfo(infos);
    }

    return  (
        <TextInput 
        className={className} 
        label={label} 
        name={name} 
        value={value} 
        onChange={handleChange} 
        onKeyDown={onKeyDown} 
        autoFocus={autoFocus}
        tabIndex={tabIndex}
        required={required}/>
    );

}

function ListeReservations({setShowReservations, selectReservation}) {

    const [reservations, setReservations] = useState([]);
    const [filtres, setFiltres] = useState({
        numDossier:"",
        nom:"",
        prenom:"",
        emplacement:"",
    });
    const [sortBy, setSortBy] = useState(null);
    const [sortDesc, setSortDesc] = useState(false);


    const displayResa = reservations
    ?.filter((resa) => 
        resa?.Num_interloc?.match(new RegExp(filtres.numDossier, "i")) &&
        resa?.Nom?.match(new RegExp(filtres.nom, "i")) &&
        resa?.Prenom?.match(new RegExp(filtres.prenom, "i")) &&
        resa?.Emplacement?.match(new RegExp(filtres.emplacement, "i"))
    )
    ?.sort((a, b) => sortDesc ? (a[sortBy] > b[sortBy] ? -1:1) : (a[sortBy] > b[sortBy] ? 1:-1));

    const handleChange = e =>{
        const { name, value } = e.target;
        setFiltres({ ...filtres, [name]: value });
    }

    const sortByColumn  = (column) => {
        if (sortBy === column) setSortDesc(!sortDesc); 
        else {
            setSortBy(column);
            setSortDesc(false);
        }
    }

    const filterReservations = (resas) => {
        let today = dayjs().format('YYYY-MM-DD');
        return resas.filter((el) => {
            return dayjs(el.Date_debut, 'DD/MM/YYYY').format('YYYY-MM-DD') <= today 
            && dayjs(el.Date_fin, 'DD/MM/YYYY').format('YYYY-MM-DD') >= today;
        });
    }

    useEffect(() => {
        getReservations({idResa:"", nom:"", prenom:"", interloc:"", emplacement:""})
        .then (res=> setReservations(filterReservations(res)))
    }, []);


    
    return (
        <Modal className="modalReservations" width="90vw" height="90vh" closeCallback={() => setShowReservations(false)}>
            <div style={{height:'100%', overflowY:'scroll'}}>
                <table className="table">
                    <thead>
                        <tr>
                            <th className="id" style={{width:'20%'}}><span onClick={() => sortByColumn('Num_interloc')} className={sortBy == "Num_interloc" ? "selected" : ""}>N° dossier</span></th>
                            <th className="nom"><span onClick={() => sortByColumn('Nom')} className={sortBy == "Nom" ? "selected" : ""}>Nom</span></th>
                            <th className="prenom"><span onClick={() => sortByColumn('Prenom')} className={sortBy == "Prenom" ? "selected" : ""}>Prénom</span></th>
                            <th className="empl"><span onClick={() => sortByColumn('Emplacement')} className={sortBy == "Emplacement" ? "selected" : ""}></span></th>
                            <th className="deb"><span onClick={() => sortByColumn('Date_debut')} className={sortBy == "Date_debut" ? "selected" : ""}>Du</span></th>
                            <th className="fin"><span onClick={() => sortByColumn('Date_fin')} className={sortBy == "Date_fin" ? "selected" : ""}>Au</span></th>
                            <th className="jr"><span onClick={() => sortByColumn('Nb_Jours')} className={sortBy == "Nb_Jours" ? "selected" : ""}>Durée</span></th>
                        </tr>
                        <tr>
                            <th className="id"><SimpleTextInput className='numDossier' label='' name='numDossier' value={filtres.numDossier} onChange={handleChange}/></th>
                            <th>
                                <SimpleTextInput className='nom' label='' name='nom' value={filtres.nom} onChange={handleChange}/>
                            </th>
                            <th>
                                <SimpleTextInput className='prenom' label='' name='prenom' value={filtres.prenom} onChange={handleChange}/>
                            </th>
                            <th>
                                <SimpleTextInput className='emplacement' label='' name='emplacement' value={filtres.emplacement} onChange={handleChange}/>
                            </th>
                            <th className="deb"></th>
                            <th className="fin"></th>
                            <th className="jr"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayResa.map(resa=>{
                            return <LigneReservation key={resa.IdResa} reservation={resa} selectReservation={selectReservation}/>
                        })}
                    </tbody>
                </table>
            </div>
        </Modal>
    )
}

function LigneReservation({reservation, selectReservation}) {

    return (
       <tr onClick={() => selectReservation(reservation)}>
            <td className="id">{reservation.Num_interloc}</td>
            <td className="nom">{reservation.Nom}</td>
            <td className="prenom">{reservation.Prenom}</td>
            <td className="empl">{reservation.Emplacement}</td>
            <td className="deb">{reservation.Date_debut}</td>
            <td className="fin">{reservation.Date_fin}</td>
            <td className="jr">{reservation.Nb_Jours}</td>
       </tr>
    )
}