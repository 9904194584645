import { createSlice } from '@reduxjs/toolkit';


export const ficheSlice = createSlice({
  name: 'fiche',
  initialState: {
    type: null,
    id: null,
    numeroFiche:null,
    reservation:null,
    client: null,
    produits: [],
    paiements:[],
    articles:[],
    pendingProduits: 0,
    pendingArticles: 0,
    pendingPaiements: 0,
  },
  reducers: {
    setTypeFiche(state, action){
        return {
            ...state,
            type:action.payload.type
        }
    },

    setReservation(state, action){
        return {
            ...state,
            reservation:action.payload.reservation
        }
    },

    saveClientData: (state,action) => {
        return {
            ...state,
            client:action.payload.client
        }
    },

    addProduitToFiche: (state, action) => {
        let produit = {
            ...action.payload.produit,
            pendingUpdate : {
                quantite : false,
                prix : false,
                delete : false
            }
        }

        return {
            ...state,
            produits : [...state.produits, produit]
        }
    },

    setPendingFieldProduit: (state, action) => {
        let index = state.produits.map(function(e) { return e.indice; }).indexOf(action.payload.id);
        state.produits[index].pendingUpdate[action.payload.fieldName] = action.payload.pending;
    },

    removeProduitFromFiche: (state, action) => {
        return {
            ...state,
            produits: state.produits.filter((produit) => produit.indice !== action.payload.id)
        };
    },

    updateQuantiteProduit: (state, action) => {
        let index = state.produits.map(function(e) { return e.indice; }).indexOf(action.payload.id);
        let prixUnitaire =  state.produits[index].prixUnitaire;
        
        state.produits[index].quantite = action.payload.quantite;
        state.produits[index].prixTotal = action.payload.prix;
        state.produits[index].remise = Math.round(100-action.payload.prix*100/(prixUnitaire*action.payload.quantite));
    },

    updatePrixProduit: (state, action) => {
        let index = state.produits.map(function(e) { return e.indice; }).indexOf(action.payload.id);
        console.log(action.payload);
        state.produits[index].prixTotal = action.payload.prix;
        state.produits[index].remise = action.payload.remise;
    },

    addArticleToFiche: (state, action) => {
        let article = {
            ...action.payload.article,
            pendingUpdate : {
                duree : false,
                supplement : false,
                delete : false
            }
        }
        state.articles.push(article);
    },

    setPendingFieldArticle: (state, action) => {
        let index = state.articles.map(function(e) { return e.indice; }).indexOf(action.payload.id);
        state.articles[index].pendingUpdate[action.payload.fieldName] = action.payload.pending;
    },

    updateSupplement: (state, action) => {
        console.log(action.payload);
        let index = state.articles.map(function(e) { return e.indice; }).indexOf(action.payload.id);
        state.articles[index].supplement = action.payload.supplement;
        state.articles[index].prix = parseFloat((action.payload.prix + action.payload.supplement).toFixed(2));
    },

    
    updateDuree: (state, action) => {
        let index = state.articles.map(function(e) { return e.indice; }).indexOf(action.payload.id);
        console.log(action.payload);
        state.articles[index].duree = action.payload.duree;
        state.articles[index].dateFin = action.payload.dateFin;
        state.articles[index].montant = action.payload.montant;
        state.articles[index].prix = action.payload.prix;
    },

    removeArticleFromFiche: (state, action) => {
        return {
            ...state,
            articles: state.articles.filter((article) => article.indice !== action.payload.id)
        };
    },


    addPaiement: (state, action) => {
        let paiement = {
            id:action.payload.id, 
            code:action.payload.code, 
            montant:action.payload.montant, 
            libelle:action.payload.libelle,
            pending:false,
        }
        state.paiements.push(paiement);
    },

    setNumeroFiche: (state, action) => {
        return {
            ...state,
            numeroFiche:action.payload.numeroFiche
        }
    },

    addPendingPaiement: (state, action) =>{
        return {
            ...state,
            pendingPaiements: state.pendingPaiements + 1
        };
    },

    removePendingPaiement: (state, action) =>{
        return {
            ...state,
            pendingPaiements: state.pendingPaiements - 1
        };
    },



    setPendingPaiement: (state, action) => {
        console.log(action.payload.id);
        let index = state.paiements.map(function(e) { return e.id; }).indexOf(action.payload.id);
        state.paiements[index].pending = action.payload.pending;
    },

    deletePaiement: (state, action) => {
        return {
            ...state,
            paiements: state.paiements.filter((paiement) => paiement.id !== action.payload.id)
        };
    },

    addPendingProduit: (state, action) =>{
        return {
            ...state,
            pendingProduits: state.pendingProduits + 1
        };
    },

    removePendingProduit: (state, action) =>{
        return {
            ...state,
            pendingProduits: state.pendingProduits - 1
        };
    },

    addPendingArticle: (state, action) =>{
        return {
            ...state,
            pendingArticles: state.pendingArticles + 1
        };
    },

    removePendingArticle: (state, action) =>{
        return {
            ...state,
            pendingArticles: state.pendingArticles - 1
        };
    },


    closeFiche: (state, action) => {
        return {
            ...state,
            type: null,
            id: null,
            numeroFiche: null,
            client: null,
            reservation:null,
            produits:[],
            articles:[],
            paiements:[],
            pendingProduits:0,
            pendingArticles:0,
            pendingPaiements:0
        }
    },
  }
})

export const { 
    setTypeFiche,
    setReservation,
    saveClientData,
    addProduitToFiche, 
    removeProduitFromFiche, 
    updateQuantiteProduit, 
    setPendingFieldProduit,
    updatePrixProduit, 
    closeFiche, 
    addPendingProduit, 
    removePendingProduit, 
    addPendingArticle,
    setPendingFieldArticle,
    removePendingArticle,
    addArticleToFiche,
    updateSupplement,
    updateDuree,
    removeArticleFromFiche,
    addPaiement,
    setNumeroFiche,
    addPendingPaiement,
    removePendingPaiement,
    setPendingPaiement,
    deletePaiement
} = ficheSlice.actions;

export default ficheSlice.reducer;
